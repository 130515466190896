import { useContext } from "react";
import { UserContext } from "../../../Context/UserContext";
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {

    const { clearSession } = useContext(UserContext);
    let navigate = useNavigate();

    const handleLogout = (event) => {
        clearSession();

        return navigate("/login");
    }


    return (
        <button type="button" onClick={handleLogout}
            style={{ marginLeft: "1rem", backgroundColor: "#072b46", outline: "none", border: "none" }}
            className="btn btn-primary">
            <i class="fas fa-sign-out-alt"></i>
        </button>
    );
};
  
export default LogoutButton;
  