import { useState, useContext, useEffect } from 'react';
import NavigationBar from "../../Layout/NavigationBar";
import BottomNavigationBar from "../../Layout/BottomNavigationBar";
import LoginContainer from "./LoginContent";
import { postRequest } from "../../../APIs/GenericFetch";
import { BACKEND_BASE_URL, BACKEND_LOGIN_URI } from "../../../APIs/BackendUrls";
import { UserContext } from "../../../Context/UserContext";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import UseTranslations from '../../../Translation/UseTranslations';

const Login = () => {

    const Translation = UseTranslations();
    let navigate = useNavigate();

    const { setSession, isAuthenticated } = useContext(UserContext);

    // below useEffect acts as componentDidMount witch will start fetching before rendering any component, if user already logged in redirect to main page
    useEffect(() => {

        if (isAuthenticated())
            return navigate("/");

    }, [isAuthenticated()]);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const onEmailChanged = (event) => setEmail(event.target.value);
    const onPasswordChanged = (event) => setPassword(event.target.value);

    const onFormSubmit = async (event) => {
        event.preventDefault();

        const loginAttemptToastRefrenceId = toast.loading(Translation.messages.attemptingToLogin);

        postRequest(BACKEND_BASE_URL + BACKEND_LOGIN_URI, { username: email, password: password }).then(responseObject => {
            if (responseObject.success) {
                if (responseObject.status === 200) {
                    const responseData = responseObject?.data;

                    setSession(responseData.token, responseData.expiration);
                } else {
                    if (responseObject.status === 401)
                        toast.error(Translation.messages.wrongUsernameOrPassowrd);
                }

                toast.dismiss(loginAttemptToastRefrenceId);
            }
        });
    }

    return (
        <div id="login-page">
            <Toaster
                position="top-center"
                reverseOrder={true}
                toastOptions={{
                    className: '',
                    duration: 5000,
                }}
            />
            <div class="home-page-background"></div>
            <div class="home-page-secondary-background"></div>
            <NavigationBar secondNavbarClassName="second-navbar-home-page" />
            <LoginContainer onEmailChanged={onEmailChanged} onPasswordChanged={onPasswordChanged} onFormSubmit={onFormSubmit} />
            <BottomNavigationBar />
        </div>
    );
};

export default Login;
