export default {
    "data": [
        {
            "type": "التصنيف",
            "typeId": 1,
            "questionsAndAnswers": [
                {
                    "index": 1,
                    "question": "ما هي آلية فتح حساب الكتروني في نظام التصنيف (pcs)؟",
                    "answer": "1. اذهب إلى موقع هيئة الغذاء والدواء \n2. اضغط على الخدمات الالكترونية، الهيئة, نظام تصنيف المنتجات بعدها اضغط على الرابط.\n3. اختر رمز تسجيل دخول للشركة.\n4. اضغط على رمز التسجيل واملأ النموذج بجميع المعلومات المطلوبة.\n5. بعد إكمال كل المعلومات اضغط على التسجيل.\nاو توجيه العميل الى الفيديو التالي https://youtu.be/EI09W325Ybo",
                    "isActive": false
                },
                {
                    "index": 2,
                    "question": "أرغب في استيراد منتجات ولكن لست متأكد تماما من كونها منتجات تجميل او دواء؟",
                    "answer": "للحصول على تصنيف منتج يتطلب منكم الدخول والتسجيل على نظام تصنيف المنتجات (PCS) على الرابط التالي: \nhttps://pcs.sfda.gov.sa/",
                    "isActive": false
                },
                {
                    "index": 3,
                    "question": "هل يوجد مقابل مادي لخدمة تصنيف المنتجات؟",
                    "answer": "رسوم طلب خدمة التصنيف 1000 ريال سعودي للمنتج الواحد غير قابل للاسترداد.",
                    "isActive": false
                },
                {
                    "index": 4,
                    "question": "كم هي مدة العمل لدراسة طلب تصنيف؟",
                    "answer": "يوم عمل واحد مع اكتمال جميع البيانات واستيفاء الرسوم. قد تستغرق عملية التصنيف مدة أطول خصوصا في حالات المنتجات ذات الخط الحدودي أو المتداخلة أو عندم الحاجة لمعلومات أو مستندات إضافية.",
                    "isActive": false
                },
                {
                    "index": 5,
                    "question": "هل يمكن الاعتراض على تصنيف منتج؟",
                    "answer": "نعم، يمكن للشركة أو المتقدم رفع طلب اعتراض على قرار التصنيف خلال 30 يوم على نظام ال pcs مع كل التبريرات والمستندات المؤيدة وستتم مراجعتها.  كما يمكنك متابعة المقطع التوضيحي:\nhttps://youtu.be/X1QPhAaWp3k",
                    "isActive": false
                },
                {
                    "index": 6,
                    "question": "هل يجب تعبئة جميع المتطلبات عند رفع طلب التصنيف؟",
                    "answer": "نعم المتطلبات اللازمة عند رفع منتج كالتالي:\n1. الغرض من الاستخدام من الشركة المصنعة\n2. تعليمات المستخدم كما أفاد المصنع\n3. آلية العمل & المعروفة بـ (Mode of action) \n4. خطاب طلب التصنيف بوجود اسم المنتج واسم الشخص المسؤول عن الطلب مع إرفاق بيانات تواصله.\n5. إرفاق كتالوج موضح للمنتج بدقة واضحة \n6. إرفاق شهادة جودة من المصنع\n7. شهادة تسجيل المنتج من بلد المصنع، إن وجدت.\n8. صورة لشكل المنتج ورقم التعريف الخاص به\n9. إرفاق بيان المطابقة للمنتج\n10. ورقة تصنيف من المصنع يوضح فيها تصنيف المنتج.",
                    "isActive": false
                },
                {
                    "index": 7,
                    "question": "هل المنتج الخاص بي جهاز طبي؟",
                    "answer": "لمعرفة تصنيف المنتج يمكنكم تقديم طلب الكتروني من خلال نظام التصنيف الالكتروني على الرابط التالي: https://pcs.sfda.gov.sa/ أو الاطلاع على الدليل الارشادي للتصنيف من خلال الرابط التالي:\nhttps://sfda.gov.sa/ar/regulations/65896",
                    "isActive": false
                },
                {
                    "index": 8,
                    "question": "ماهي الخدمات التي تقدمها إدارة التصنيف ",
                    "answer": "•\t- خدمة تصنيف منتج.\n•\t- خدمة الاستفسار عن تصنيف منتج مقدم للهيئة.\n•\t- خدمة الاستفسار عن مدى نظامية وحاله الطلبات المقدمة للتصنيف.\n•\t- خدمة الاستفسار والاستعلام عن طلبات الاعتراض على قرار تصنيف منتج مقدم للهيئة.\n•\t-خدمة تصنيف منتج مبتكر. \n- إصدار الأدلة الإرشادية لتوضيح معايير ومتطلبات تصنيف المنتجات.",
                    "isActive": false
                },
                {
                    "index": 9,
                    "question": "ماهي خدمة تصنيف منتج ",
                    "answer": " هي خدمة تتيح للشركات والمستثمرين والقطاع العام والجهات ذات العلاقة بتصنيف منتجاتها ومعرفة مدى خضوع المنتج للتسجيل من عدمه، بناء على معايير الهيئة وبحسب الدليل الإرشادي لتصنيف المستحضرات والمنتجات. ",
                    "isActive": false
                },
                {
                    "index": 10,
                    "question": "ماهي خدمة تصنيف المنتج المتداخل",
                    "answer": "خدمة تقدمها إدارة تصنيف المنتجات للعملاء بتصنيف المنتج المتداخل وتحديد المسار الرقابي الأنسب وتعيين القطاع المسؤول عن تسجيل ورقابة المنتج المتداخل والقطاع المستشار المسؤول عن تقييم الجزء الثانوي للمنتج المتداخل",
                    "isActive": false
                },
                {
                    "index": 11,
                    "question": "خدمة الاستفسار والاستعلام عن طلبات الاعتراض على قرار تصنيف منتج مقدم للهيئة.",
                    "answer": "هي خدمة حيث يحق للشركة تقديم طلب استئناف او اعتراض على قرار تصنيف منتج خلال 30 يوم في النظام الالكتروني (PCS) مع تقديم المبررات ويتم النظر في ذلك.",
                    "isActive": false
                },
                {
                    "index": 12,
                    "question": "خدمة تصنيف منتج مبتكر ",
                    "answer": "خدمة مجانية مقدمة للمخترعين والباحثين تهدف لدعم الابتكار عبر توضيح المسار التنظيمي للمنتج المبتكر وفق الأنظمة التشريعية القائمة بالهيئة العامة للغذاء والدواء",
                    "isActive": false
                },
                {
                    "index": 13,
                    "question": "لماذا خدمة تصنيف المنتجات",
                    "answer": "•\t- الحالة النظامية والرقابية للمنتجات (للشركات والمستثمرين).\n•\t- تداخل الصلاحيات النظامية للمنتجات لدى الجهات الحكومية.\n•\t- اختلاف التصنيف العالمي لدى الجهات الرقابية العالمية.\n•\t- حاجة المنافذ الحدودية والجمارك.\n•\t- حاجة إدارات وفروع الهيئة.\n•\t- حاجة مفتشين الهيئة لضبط واجراء المخالفات على المنتجات المخالفة\n•\t- تسهيل إجراءات التراخيص وعمليات التقييم.\n•\t- توفير الوقت، المال والجهد.\n•\t- توحيد مدخلات الهيئة العامة للغذاء والدواء (عن طريق النظام الموحد للتصنيف المنتجات (PCS)).\n•\t- مواكبة التشريعات في الجهات الرقابية وتشجيع الصناعات المحلية والإقليمية\n•\t- توضيح الأنظمة وتفريق بين السلطات المتداخلة ولخلق بيئة مشجعة للاستثمار المحلي والعالمي للباحثين والمخترعين.",
                    "isActive": false
                },
                {
                    "index": 14,
                    "question": "دليل تصنيف المنتجات في الهيئة العامة للغذاء والدواء",
                    "answer": "https://sfda.gov.sa/ar/regulations/65896",
                    "isActive": false
                },
                {
                    "index": 15,
                    "question": "الدليل الإرشادي لتصنيف المنتجات ذات الخط الحدودي",
                    "answer": "https://www.sfda.gov.sa/ar/regulations/85859",
                    "isActive": false
                },
                {
                    "index": 16,
                    "question": "الدليل الإرشادي لتصنيف المنتجات المتداخلة والمدمجة",
                    "answer": "https://www.sfda.gov.sa/ar/regulations/78600",
                    "isActive": false
                },
                {
                    "index": 17,
                    "question": "طريقة طلب الاستئناف او الاعتراض على قرار تصنيف",
                    "answer": "يحق للشركة تقديم طلب استئناف او اعتراض على قرار تصنيف منتج خلال 30 يوم في نظام التصنيف الالكتروني (PCS) مع تقديم المبررات ويتم النظر في ذلك.\nفيديو توضيحي لطريقه تقديم طلب استئناف:  \nhttps://www.youtube.com/watch?v=X1QPhAaWp3k",
                    "isActive": false
                },
                {
                    "index": 18,
                    "question": "هل يتم استقبال مستندات او تصنيف المنتجات عن طريق البريد الالكتروني الخاص بالتصنيف",
                    "answer": "لا يتم استقبال أي مستندات تتعلق بالتصنيف عن طريق البريد الالكتروني ويلزم التقديم في نظام التصنيف الالكتروني حتى يحصل مقدم الطلب على تصنيف معتمد لدى الهيئة. إلا في حال تم التواصل مع مزود الطلب وطلب ارسالها عن طريق البريد.",
                    "isActive": false
                },
                {
                    "index": 19,
                    "question": "هل قرار تصنيف منتج لها فترة انتهاء؟ ولماذا",
                    "answer": "نعم قرار التصنيف يعتبر صالح لمدة سنة من تاريخ اصدار الشهادة للمنتج وذلك لتحديث القوانين والمعايير والأدلة الارشادية داخل الهيئة العامة للغذاء والدواء ",
                    "isActive": false
                },
                {
                    "index": 20,
                    "question": "هل توجد شهادة تصنيف نستطيع الحصول عليها",
                    "answer": "لا يوجد شهادة او خطاب ورقي. يصل لمقدم الطلب قرار عن طريق البريد الكتروني يفيد بتصنيف المنتج ويمكن الاطلاع على القرار الكترونياً عبر النظام (PCS). في الوقت الحالي يتم اصدار شهادات القرار بتصنيف منتج عند طلبه من الجهات الحكومية فقط.",
                    "isActive": false
                },
                {
                    "index": 21,
                    "question": "كيفية التواصل مع إدارة تصنيف المنتجات",
                    "answer": "يمكن التواصل مع إدارة تصنيف المنتجات من خلال البريد الالكتروني التالي: PCS@sfda.gov.sa او من خلال قنوات التواصل المتاحة للهيئة العامة للغذاء والدواء عبر مركز الاتصال الموحد (19999). ",
                    "isActive": false
                }
            ],
        },
        {
            "type": "المبيدات",
            "typeId": 2,
            "questionsAndAnswers": [
                {
                    "index": 1,
                    "question": "ما هي آلية فتح حساب الكتروني في نظام (غد)؟",
                    "answer": "11) ادخال الرقم الموحد للمنشاة لسجل تجاري ساري المفعول مضاف فيه احدى الأنشطة التالية:\n(466922) البيع بالجملة للمبيدات الحشرية\n(466923) البيع بالجملة لمبيدات آفات الصحة\n(202151) صناعة مبيدات آفات الصحة العامة\n(202120) صناعة مبيدات الحشرات والقوارض المنزلية\n2) خطاب تفويض مصدق من الغرفة التجارية للمفوض اذا كان مقدم الطلب ليس هو المالك.\n3) تكون جميع المستندات المرفقة باللغة العربية أو الانجليزية وإذا كانت بلغة أخرى فيجب” أن يرفق معها ترجمة معتمدة باللغة العربية أو الإنجليزية”.\n",
                    "isActive": false
                },
                {
                    "index": 2,
                    "question": "ماهي أنواع المبيدات التي يمكن تسجيلها بالهيئة؟ وماهي آلية تسجيل مبيد آفات الصحة العامة؟",
                    "answer": "مبيدات افات الصحة العامة ,من خلال زيارة الرابط التالي:\n https://www.sfda.gov.sa/ar/regulations/78468",
                    "isActive": false
                },
                {
                    "index": 3,
                    "question": "ما هي المستندات المطلوب توافرها عند التسجيل المبيدات في النظام ؟",
                    "answer": "من خلال زيارة الرابط التالي:\n https://ghad.sfda.gov.sa/assets/files/create-commercial-account-steps.pdf",
                    "isActive": false
                },
                {
                    "index": 4,
                    "question": "ماهي متطلبات وإجراءات ترخيص الدعاية والاعلان لمبيدات (آفات الصحة العامة) ؟",
                    "answer": "الاطلاع على صفحة الدعاية والإعلان لمبيدات الصحة العامة في رحلة العميل https://customerjourney.sfda.gov.sa/journey/1203",
                    "isActive": false
                },
                {
                    "index": 5,
                    "question": "هل يوجد مواد محظور استخدامها بالمبيدات؟",
                    "answer": "نعم يوجد مواد محظورة وبالامكان معرفتها من خلال زيارة  موقع وزارة البيئة والمياة والزراعة على الرابط التالي:\nhttps://www.mewa.gov.sa/ar/Ministry/Agencies/AgencyofAgriculture/Topics/Pages/oldblockKSA.aspx",
                    "isActive": false
                },
                {
                    "index": 6,
                    "question": "ما هي آلية التقديم على طلب أذن استيراد للمبيد؟",
                    "answer": "يتم تقديم نموذج طلب إذن استيراد مبيد للهيئة بعد تعبئته وتوفير المتطلبات المرفقه على الرابط التالي:\nhttps://www.sfda.gov.sa/ar/forms?tags=6",
                    "isActive": false
                },
                {
                    "index": 7,
                    "question": "ماهي آلية التقديم على طلب أذن تصدير للمبيد؟",
                    "answer": "يتم تقديم نموذج طلب إذن تصدير مبيد للهيئة بعد تعبئته وتوفير المتطلبات المرفقه على الرابط التالي:\nhttps://www.sfda.gov.sa/ar/forms?tags=6",
                    "isActive": false
                },
                {
                    "index": 8,
                    "question": "ماهي أنواع الاختبارات والتحاليل التي يجب توفرها لتسجيل مبيدات آفات الصحة العامة بحسب اللوائح المعتمدة لدى الهيئة ؟",
                    "answer": "1- اختبار تحليل المبيد على أن يكون شامل لمكونات المبيد والكثافة النوعية ودرجة الحموضة.\n2- اختبار تخزين المبيد على درجة حرارة صفر مئوية لمدة أسبوع.\n3- اختبار تخزين المبيد على درجة حرارة 54 مئوية لمدة أسبوعين.\n4- اختبار تخزين المبيد على درجة حرارة 24 مئوية لمدة أسبوعين.\n5- اختبار فعالية المبيد على الآفات المستهدفة.\n6- اختبارات السمية الحادة للمبيد وتكون على النحو التالي:\n1.6 Acute Oral Toxicity on Rat\n2.6 Acute Dermal Toxicity on Rat\n3.6 Acute Inhalation Toxicity on Rat\n4.6 Skin Sensitization on Guinea pig\n5.6 Acute Eye Irritation on Rabbit\n6.6 Acute Dermal Irritation on Rabbit\n",
                    "isActive": false
                },
                {
                    "index": 9,
                    "question": "كيف يتم الحصول على الأنظمة واللوائح والتعاميم الخاصة بالمبيدات؟",
                    "answer": "قانون (نظام) المبيدات ولائحته التنفيذية لدول مجلس التعاون لدول الخليج العربية\nhttps://www.sfda.gov.sa/sites/default/files/2021-05/lawPestcideA.pdf\nزيارة متجر المواصفات  على الرابط التالي:\nhttps://www.gso.org.sa/store/standards?query=%D8%A7%D9%84%D9%85%D8%A8%\nمتطلبات تسجيل مبيدات الصحة العامة\nhttps://www.sfda.gov.sa/sites/default/files/2021-01/req_pestcides_register.pdf",
                    "isActive": false
                },
                {
                    "index": 10,
                    "question": "التكرم بشرح آلية تسجيل حساب مسجل مسبقا؟",
                    "answer": "اذا كان المقصود إضافة نشاط فإن العميل يقوم بالدخول الى الحساب وعمل تحديث واضافة النشاط وتقديم الطلب ليتم الموافقه عليه من قبل موظفين الهيئه",
                    "isActive": false
                },
                {
                    "index": 11,
                    "question": " 3أشهر فترة قصيرة خاصة للشركات الي عندها ٢٥ الى ٣٠ تسجيل او أكثر فيا ريت اعادة النظر؟",
                    "answer": "مهلة 3 أشهر بإذن الله سوف تكون مدة كافيه لإدراج منتجاتك، وذلك نظرا لسهولة الإجراءات.",
                    "isActive": false
                },
                {
                    "index": 12,
                    "question": " ما المقصود بالترخيص التعاقدي؟",
                    "answer": "يقصد به: العلاقة التعاقدية ما بين الشركة المالكة للمبيد والشركة المصنعة للمبيد حال اختلافهما. أو العلاقة التعاقدية ما بين الشركة المصنعة والشركة المصدرة للمبيد.",
                    "isActive": false
                },
                {
                    "index": 13,
                    "question": "ممكن تعيد طريقه تنزيل الشهادة؟",
                    "answer": "عند الدخول إلى حساب المنشأة في منصة غد > الذهاب إلى قائمة خدمات المنتجات > اختيار منتجات مبيدات الآفات الخاصة بي < الضغط على عبارة \"إجراءات\" للمنتج المحدد < اختر تنزيل.",
                    "isActive": false
                },
                {
                    "index": 14,
                    "question": "الشركات المصنعة محليا مثل الشركات المستوردة؟",
                    "answer": "تختلف بعض المتطلبات في المنتجات المصنعة محليا عن المنتجات المستوردة، مثل: عدم وجود متطلب شهادة تسجيل في بلد المنشأ.",
                    "isActive": false
                },
                {
                    "index": 15,
                    "question": "بالنسبة للمعاملات التي لدينا في PNR وش وضعها الان؟",
                    "answer": "سيتم إيقاف العمل ببرنامج السجل الوطني للمبيدات، ويلزم التقدم بطلبات تسجيل أو إدراج مبيدات من خلال منصة غد.",
                    "isActive": false
                },
                {
                    "index": 16,
                    "question": "هل يوجد عدد مرات محدده لرجوع طلب التسجيل؟",
                    "answer": "حتى الأن لا يوجد عدد محدد لرجوع طلب التسجيل.",
                    "isActive": false
                },
                {
                    "index": 17,
                    "question": "الية نقل المنتجات المسجلة على النظام القديم؟",
                    "answer": "لن يتم نقل المنتجات المسجلة على النظام القديم إلى منصة \"غد\"، ويلزم التقدم بطلبات إدراج أو تسجيل مبيدات من خلال منصة غد، وذلك لاختلاف شهادة تسجيل وتداول المبيد عن الشهادة السابقة.",
                    "isActive": false
                },
                {
                    "index": 18,
                    "question": "إجراءات إدراج مبيد مسجل مسبقاً؟",
                    "answer": "هي نفس إجراءات التقدم بطلب تسجيل مبيد، مع التوضيح أن إدراج المبيد سيكون بدون رسوم لأول 3 أشهر من إطلاق المنصة، وبعد تلك الفترة سيتم اعتبار الطلب كتسجيل جديد.",
                    "isActive": false
                },
                {
                    "index": 19,
                    "question": "هل المنتجات المسجلة سابقا نجدها بالنظام الجديد؟",
                    "answer": "لا، ويلزم التقدم بطلبات إدراج أو تسجيل مبيدات من خلال منصة غد، وذلك لاختلاف شهادة تسجيل وتداول المبيد عن الشهادة السابقة، مع التنويه أن إدراج المبيد سيكون بدون رسوم لأول 3 أشهر من إطلاق المنصة، وبعد تلك الفترة سيتم اعتبار الطلب كتسجيل جديد.",
                    "isActive": false
                },
                {
                    "index": 20,
                    "question": "لو سمحت انا كنت مسجل ع PNR ووصلت لمرحله اصدار الفاتورة وأرسلي ايميل اتواصل معه هل كده خلاص ولا لازم أسجل من جديد؟",
                    "answer": "في حال عدم دفع الفاتورة، يجب تقديم طلب التسجيل من خلال منصة غد واستكمال الإجراءات.\nفي حال دفع الفاتورة، يجب تقديم طلب التسجيل في منصة غد وإرفاق نسخة من إيصال الدفع.",
                    "isActive": false
                },
                {
                    "index": 21,
                    "question": " هل يمكن رفع أكثر من ملف؟",
                    "answer": "يمكن رفع أكثر من ملف لبعض المتطلبات، مثل: شهادة التحليل – اختبارات الفعالية – كما يمكن إرفاق أكثر من ملف في الخطوة الأخيرة قبل إرسال الطلب.",
                    "isActive": false
                },
                {
                    "index": 22,
                    "question": " هل يجب رفع خطاب التعاقد إذا كانت الشركة المصنعة نفسها وإذا كان المصنع محلي هل يلزم رفع خطاب التعاقد والتفويض؟",
                    "answer": "إذا كانت الشركة المصنعة هي المالكة للمبيد لا يلزم رفع خطاب التعاقد سواء للمبيدات المصنعة محليا أو خارجياً.",
                    "isActive": false
                },
                {
                    "index": 23,
                    "question": "هل يتم الاكتفاء بالباركود في الملصق فقط من دون ذكر اي بيانات للهيئة العامة للغذاء والدواء؟",
                    "answer": "يجب وضع الباركود ورقم التسجيل الصادر من الهيئة العامة للغذاء والدواء.",
                    "isActive": false
                },
                {
                    "index": 24,
                    "question": "مبيد تحت التسجيل من قبل نظام غد وعليه ملاحظات من المفروض تقديمها كيف نقدر نقدم؟",
                    "answer": "في حال صدور ملاحظات من قبل الهيئة، سيتم إشعار العميل بذلك من خلال البريد الإلكتروني المسجل في المنصة بالإضافة إلى تغير حالة الطلب في المنصة من \"تحت الإجراء لدى الهيئة\" إلى \"عاد إلى مقدم الطلب\".\nويمكن الرد على الملاحظات بالضغط على أيقونة \"إجراءات\" ثم اختيار أيقونة \"تحديث الطلب\".",
                    "isActive": false
                },
                {
                    "index": 25,
                    "question": "في حال إضافة مبيدات مسجلة سارية المفعول، هل يلزم تعبئة جميع الحقول السابقة حتى نصل إلى خطوة رفع مستند شهادة التسجيل السابقة؟",
                    "answer": "نعم، يجب رفع جميع المستندات المطلوبة.",
                    "isActive": false
                },
                {
                    "index": 26,
                    "question": "الشركات المسجلة مسبقا يتم تسجيلها من جديد؟",
                    "answer": "نعم، ويلزم التقدم بطلبات إدراج أو تسجيل مبيدات من خلال منصة غد، وذلك لاختلاف شهادة تسجيل وتداول المبيد عن الشهادة السابقة، مع التنويه أن إدراج المبيد سيكون بدون رسوم لأول 3 أشهر من إطلاق المنصة، وبعد تلك الفترة سيتم اعتبار الطلب كتسجيل جديد.",
                    "isActive": false
                },
                {
                    "index": 27,
                    "question": "ما وضع المبيدات التي في المرحلة النهائية فقط في انتظار اصدار الشهادة هل سيتم إدخالها من جديد؟",
                    "answer": "نعم، ويلزم التقدم بطلبات إدراج أو تسجيل مبيدات من خلال منصة غد، وذلك لاختلاف شهادة تسجيل وتداول المبيد عن الشهادة السابقة، مع التنويه أن إدراج المبيد سيكون بدون رسوم لأول 3 أشهر من إطلاق المنصة، وبعد تلك الفترة سيتم اعتبار الطلب كتسجيل جديد.",
                    "isActive": false
                },
                {
                    "index": 28,
                    "question": "تجديد المبيد يكون من ضمن الخيارات الثلاث؟",
                    "answer": "صحيح، ستظهر في أيقونة \"الإجراءات\" خيار \"تحديث وتجديد\" وذلك خلال 180 يوم قبل انتهاء تاريخ المبيد المسجل.",
                    "isActive": false
                },
                {
                    "index": 29,
                    "question": "كيف نصنف مبيد حشري منخفض الخطورة؟",
                    "answer": "بناءً على قوائم المواد الفعالة + قوائم المواد قليلة الخطورة في منصة \"غد\" عند التقدم بطلب التسجيل.",
                    "isActive": false
                },
                {
                    "index": 30,
                    "question": "كيف نرد على الملاحظات للملفات السلمة للهيئة؟ ",
                    "answer": "يلزم التقدم بطلبات إدراج أو تسجيل مبيدات من خلال منصة غد، وإرفاق الرد على الملاحظات من خلال منصة غد. مع التنويه أن إدراج المبيد سيكون بدون رسوم لأول 3 أشهر من إطلاق المنصة، وبعد تلك الفترة سيتم اعتبار الطلب كتسجيل جديد.",
                    "isActive": false
                },
                {
                    "index": 31,
                    "question": "قائمه المنتجات بالمنصة لدينا غير موجود بها قسم المبيدات؟",
                    "answer": "يرجى التأكد من إضافة نشاط مبيدات في حسابكم على منصة غد لتتمكن من تسجيل منتجات المبيدات.",
                    "isActive": false
                },
                {
                    "index": 32,
                    "question": "هل يتم اصدار فاتورة تجديد مبيد او تسجيل مبيد من نظام الفواتير؟",
                    "answer": "عند التقدم بطلب تسجيل أو تجديد يتم إصدار الفواتير الكترونياً.",
                    "isActive": false
                },
                {
                    "index": 33,
                    "question": "كيف نرد على الملاحظات للملفات المسلمة للهيئة؟",
                    "answer": "يلزم التقدم بطلبات إدراج أو تسجيل مبيدات من خلال منصة غد، وإرفاق الرد على الملاحظات من خلال منصة غد. مع التنويه أن إدراج المبيد سيكون بدون رسوم لأول 3 أشهر من إطلاق المنصة، وبعد تلك الفترة سيتم اعتبار الطلب كتسجيل جديد.",
                    "isActive": false
                },
                {
                    "index": 34,
                    "question": "هل يتم اعتماد الملصقات الموافق عليها مسبقا بعد إضافة كيو أر كود أم يضاف على الملصق فقط بدون اعتماده مره اخرى؟",
                    "answer": "بعد اعتماد الملصق من قبل الهيئة يجب إضافة كيو ار كود QR Code.",
                    "isActive": false
                },
                {
                    "index": 35,
                    "question": "هل يلزم وجود علامة كيو ار على العلب المبيد؟",
                    "answer": "يجب إضافة كيو ار كود QR Code على ملصق عبوة المبيد بعد اعتماد الملصق من قبل الهيئة.",
                    "isActive": false
                },
                {
                    "index": 36,
                    "question": "هل ممكن اضافه أكثر من بريد إلكتروني عند التسجيل يحتوي على نفس بيانات الشركة؟",
                    "answer": "نعم يوجد هناك حساب رئيسي ويمكن إضافة حسابات فرعيه",
                    "isActive": false
                },
                {
                    "index": 37,
                    "question": "لماذا يجب رفع جميع البيانات لإعادة تسجيل المبيدات المسجلة مسبقاً، يجب فقط ارفاق شهادة التسجيل للمبيد، بان الهيئة مسبقاً درست المبيد ووافقت على تسجيله. فما الداعي لدراسته مرة اخرى نرجوا التوضيح؟",
                    "answer": "يلزم التقدم بطلبات إدراج أو تسجيل مبيدات من خلال منصة غد، وذلك لاختلاف شهادة تسجيل وتداول المبيد عن الشهادة السابقة واختلاف ترميز أرقام تسجيل المبيدات، مع التنويه أن إدراج المبيد سيكون بدون رسوم لأول 3 أشهر من إطلاق المنصة، وبعد تلك الفترة سيتم اعتبار الطلب كتسجيل جديد.",
                    "isActive": false
                },
                {
                    "index": 38,
                    "question": "الرسوم 4600 شاملة كل شي؟",
                    "answer": "يجب التنويه أن رسوم تسجيل المبيدات لم تتغير عن السابق،\nتسجيل منتج مبيد جديد 2700 ريال \nإعادة التسجيل 2200 ريال \nتحليل عينة 1400 ريال\nويتم إضافة 500 ريال لكل مادة فعالة إضافية.",
                    "isActive": false
                },
                {
                    "index": 39,
                    "question": "هل الناموسيات المعالجة بالمبيد مطلوب تسجيلها؟",
                    "answer": "نعم، أي منتج مبيد آفات صحة عامة يحتوي على مادة فعالة أو مادة داخلة من ضمن قائمة المبيدات قليلة الخطورة يجب تسجيلها لدى الهيئة العامة للغذاء والدواء.",
                    "isActive": false
                },
                {
                    "index": 40,
                    "question": "كم الرسوم لتسجيل مبيد جديد؟",
                    "answer": "تسجيل منتج مبيد جديد 2700 ريال + تحليل عينة 1400 ريال.\nويتم إضافة 500 ريال لكل مادة فعالة إضافية.",
                    "isActive": false
                },
                {
                    "index": 41,
                    "question": "ضمن خطوات إدراج المبيد المسجل مسبقا\" هل يقصد بذلك تحميل الدراسات من الملف المقدم مسبقا؟",
                    "answer": "نعم، يلزم التقدم بطلبات إدراج أو تسجيل مبيدات من خلال منصة غد، وذلك لاختلاف شهادة تسجيل وتداول المبيد عن الشهادة السابقة واختلاف ترميز أرقام تسجيل المبيدات، ويجب تقديم جميع المتطلبات في منصة غد، مع التنويه أن إدراج المبيد سيكون بدون رسوم لأول 3 أشهر من إطلاق المنصة، وبعد تلك الفترة سيتم اعتبار الطلب كتسجيل جديد.",
                    "isActive": false
                },
                {
                    "index": 42,
                    "question": "ربما تكون هنالك صعوبة في الحصول على إيصالات التسجيل لمبيدات مضى على تسجيلها عدة سنوات؟ ",
                    "answer": "يكتفى بإرفاق شهادة تسجيل وتداول مبيد الصادرة مسبقا من الهيئة.",
                    "isActive": false
                },
                {
                    "index": 43,
                    "question": "هل يتم تسليم ملف ورقي بالإضافة للتسجيل على المنصة؟",
                    "answer": "لا، تم إيقاف العمل بالطلبات الورقية، وسوف تكون منصة \"غد\" القناة الإلكترونية الوحيدة للتقدم بطلبات تسجيل مبيدات آفات الصحة العامة.",
                    "isActive": false
                },
                {
                    "index": 44,
                    "question": "هل نستطيع التقديم على أذون استيراد بالشهادة القديمة؟",
                    "answer": "سيتم قبول الشهادة بالنسخة القديم لإصدار أذونات الاستيراد مؤقتاً وذلك خلال أول 3 أشهر من إطلاق منصة \"غد\".\nوبعد انتهاء الفترة يجب إرفاق الشهادة بالنسخة الحديثة عند التقدم بطلبات أذونات الاستيراد.\n",
                    "isActive": false
                },
                {
                    "index": 45,
                    "question": "لماذا يجب رفع جميع البيانات لإعادة تسجيل المبيدات المسجلة مسبقاً، يجب فقط ارفاق شهادة التسجيل للمبيد، بان الهيئة مسبقاً درست المبيد ووافقت على تسجيله. فما الداعي لدراسته مرة اخرى نرجوا التوضيح؟",
                    "answer": "يلزم التقدم بطلبات إدراج أو تسجيل مبيدات من خلال منصة غد، وذلك لاختلاف شهادة تسجيل وتداول المبيد عن الشهادة السابقة واختلاف ترميز أرقام تسجيل المبيدات، مع التنويه أن إدراج المبيد سيكون بدون رسوم لأول 3 أشهر من إطلاق المنصة، وبعد تلك الفترة سيتم اعتبار الطلب كتسجيل جديد.",
                    "isActive": false
                },
                {
                    "index": 46,
                    "question": "من لديه شحنة جديدة يرغب استيرادها، هل يلزم كيو ار كود على الملصقات الخاصة بها؟",
                    "answer": "خلال أول 3 أشهر من إطلاق المنصة لا يلزم إرفاق كيو ار كود QR Code على ملصقات المبيدات للتمكن من استيرادها، ولكن بعد انتهاء المهلة المحددة 3 أشهر يلزم إرفاق كيو ار كود في الملصق.",
                    "isActive": false
                },
                {
                    "index": 47,
                    "question": "اطقم اختبار الحساسية كيف يمكن استيرادها وخاصة أن تسجيلها سوف يكون معقد جدا بماذا توجه لنا في حال احتياجها؟",
                    "answer": "ليس من اختصاص الهيئة العامة للغذاء والدواء.",
                    "isActive": false
                },
                {
                    "index": 48,
                    "question": "الدراسات المطلوبة للمنتج مثل الثابتية والفعالية هل هي دراسات من اجراء نفس المصنع أو من مختبرات محايدة معتمدة؟",
                    "answer": "يجب إرفاق الدراسات المطلوبة من مختبرات معمدة تحمل شهادة GLP أو شهادة الايزو 17025.",
                    "isActive": false
                },
            ],
        },
        {
            "type": "التجميل",
            "typeId": 3,
            "questionsAndAnswers": [
                {
                    "index": 1,
                    "question": "ما هي آلية فتح حساب الكتروني في النظام الموحد (غد) لترخيص منشآت التجميل؟",
                    "answer": "لإنشاء الحساب، يتم استخدام سجل تجاري متضمن للنشاط الذي سيتم ترخيصه باستخدام الرقم الموحد للمنشأة الذي يبدأ بالرقم (7) ، ويمكنكم الاطلاع على قائمة الأنشطة الاقتصادية الخاصة بالهيئة ISIC4 من خلال موقعها الإلكتروني.",
                    "isActive": false
                },
                {
                    "index": 2,
                    "question": "ماهي طريقة استعادة كلمة المرور واسم المستخدم وبيانات الدخول بشكل عام في النظام الموحد (غد) لترخيص منشآت التجميل؟",
                    "answer": "باختيار (نسيت كلمة المرور) في قائمة تسجيل الدخول، ومن ثم استعادة البيانات المطلوبة على البريد الإلكتروني المسجل في النظام.",
                    "isActive": false
                },
                {
                    "index": 3,
                    "question": "ماهي خطوات تغيير مدير مستودع التجميل؟",
                    "answer": "يتم تغيير المدير الفني لمستودع التجميل من خلال الدخول على حساب العميل ثم اختيار خانة \"خدمات الترخيص\" ومن \"تراخيص مستحضرات التجميل\" يتم تحديد الرخصة واختيار \"تحديث\" من قائمة إجراءات الترخيص الفعال ثم الدخول إلى بيانات مدير المستودع وتغييرها إلى المدير البديل وإرفاق المستندات الخاصة به.",
                    "isActive": false
                },
                {
                    "index": 4,
                    "question": "كيف يمكن التقديم على رخصة مصنع تجميل؟ وكم هي مدة دراسة الطلب؟",
                    "answer": "يتم تقديم طلب ترخيص مصنع منتجات تجميل من خلال إنشاء حساب باستخدام سجل تجاري يتضمن النشاط على النظام الإلكتروني الموحد (غد) وبعد الموافقة على الحساب يتم تقديم طلب الرخصة وإرفاق المستندات المطلوبة.",
                    "isActive": false
                },
                {
                    "index": 5,
                    "question": "كيف يمكن طباعة ترخيص مصنع تجميل؟",
                    "answer": "بإمكان العميل طباعة ترخيص مصانع التجميل من خلال حسابه في النظام الإلكتروني الموحد (غد) بالضغط على كلمة تنزيل أو Download من قائمة التراخيص.",
                    "isActive": false
                },
                {
                    "index": 6,
                    "question": "كيف يمكن التقديم على ترخيص مستودع تجميل؟",
                    "answer": "يتم تقديم طلب ترخيص مستودع تجميل من خلال إنشاء حساب باستخدام سجل تجاري يتضمن النشاط على النظام الإلكتروني الموحد (غد) وبعد الموافقة على الحساب يتم تقديم طلب الرخصة وإرفاق المستندات المطلوبة.",
                    "isActive": false
                },
                {
                    "index": 7,
                    "question": "هل  سيكون هناك رسوم على إدراج المصانع أو المنتجات في نظام غد؟ ",
                    "answer": "حاليًا، لا يوجد أي رسوم على إدراج المصانع أو المنتجات في نظام غد.  ",
                    "isActive": false
                },
                {
                    "index": 8,
                    "question": "ما الفرق بين (ملصق المنتج) و (صورة المنتج للنشر) في نظام (غد)؟",
                    "answer": "ملصق المنتج: هو تصميم العبوة من المصنع والذي يشترط إرفاقه كصور واضحة للمنتج من جميع الجهات تبرز هويته بشكل واضح ومقروء.صورة المنتج (للنشر): هي صورة المنتج النهائي الفعلي والتي سوف تكون متاحة للاطلاع من خلال تطبيق طمني.",
                    "isActive": false
                },
                {
                    "index": 9,
                    "question": "هل يمكن تحديث بيانات إدراج المنتجات المدرجة في نظام غد؟ ",
                    "answer": "يمكن تحديث بيانات الإدراج في نظام غد، شريطة أن  يكون التعديل لا يغير من هوية المنتج ولا من الشكل والتركيب الأساسي للمنتج وفي حال تغيير هوية المنتج يلزم المنتج من جديد. ",
                    "isActive": false
                },
                {
                    "index": 10,
                    "question": "كيف يمكنني الحصول على رقم التعرفة الجمركية(HS code)؟",
                    "answer": "من خلال زيارة موقع هيئة الزكاة والضريبة والجمارك في صفحة التعريفة الجمركية المتكاملة عبر الرابط: https://eservices.zatca.gov.sa/sites/sc/ar/tariff/Pages/TariffPages/TariffSearch.aspx     ",
                    "isActive": false
                },
                {
                    "index": 11,
                    "question": "هل هناك شهادة إدراج منتج تسلم للمنشأة بعد إدراج المنتج في نظام غد؟ ",
                    "answer": "توجد أن تقوم المنشأة المدرجة بإخطار الهيئة بتسويق المنتج التجميلي وذلك قبل تداوله في الأسواق.  هذه الشهادة ليست لغرض إثبات أن المنتج (منتج تجميلي) أو أن المنتج قد تم تقييمه أو دراسته أو تحليله أو الموافقة على فسحه من قبل الهيئة، بالإضافة إلى أن الشهادة لا تستخدم كمادة تسويقية لغرض الدعاية والإعلان. ",
                    "isActive": false
                },
                {
                    "index": 12,
                    "question": "كيف يمكن معرفة أن المنتج يحتوي على nano أوmaterial CRM  ؟ ",
                    "answer": "يمكن التعرف عليها من من خلال ملف معلومات المنتج المزود من قبل المصنع. ",
                    "isActive": false
                },
                {
                    "index": 13,
                    "question": "كيف يمكنني إدراج منتجات التجميل في نظام (غد)؟",
                    "answer": " 1. إنشاء حساب جديد في نظام (غد). 2. اختيار منتج مدرج أو إدراج منتج جديد. 3. اختيار مصنع أو إدخال بيانات مصنع جديد. 4. استيفاء شروط فسح منتجات التجميل للمنتجات المستوردة.",
                    "isActive": false
                },
                {
                    "index": 14,
                    "question": "هل يجب إدخال  قائمة المكونات بتراكيزها وأدوارها عند طلب إدراج منتج؟",
                    "answer": "نعم يلزم إدخالها، ويتم ذلك من خلال إحدى الطريقتين أدناه:1. إدخال المكونات يدوياً من خلال البحث بقاعدة بيانات النظام للمكونات. 2. إرفاق ملف المكونات (Excel) قابل للقراءة تلقائياً من قبل النظام باستخدام النموذج المخصص.",
                    "isActive": false
                },
                {
                    "index": 15,
                    "question": "ما هو الوقت المتوقع لإتمام مراجعة طلب ادراج منتج تجميلي؟",
                    "answer": "يتم قيد المنتج التجميلي في سجلات الهيئة مباشرة بعد تقديم طلب الإدراج.",
                    "isActive": false
                },
                {
                    "index": 16,
                    "question": "ما تعريف المنتج المركب؟ ",
                    "answer": "عبارة عن مجموعة من المنتجات التجميلية البسيطة في عبوة واحدة غير قابلة للفصل. مثل: لوح ظلال العيون. ",
                    "isActive": false
                },
                {
                    "index": 17,
                    "question": "ماهي أصناف منتجات التجميل حسب نظام (غد)؟",
                    "answer": "يوجد (3) أصناف لمنتجات التجميل حسب نظام (غد):1. منتج بسيط. 2. منتج مركب. 3. طقم منتجات (دمج منتج بسيط/مركب مع منتج بسيط/مركب آخر).",
                    "isActive": false
                },
                {
                    "index": 18,
                    "question": "كيف يمكنني الإجابة عن سؤال هل للمنتج أصناف/أنواع مختلفة؟ ",
                    "answer": "عند الإجابة بنعم فذلك يعني أنك ترغب بإضافة منتجين أو أكثر بحيث تكون  متماثلة في التركيب ومكان التصنيع وغرض الاستخدام وتختلف في الرائحة، أو النكهة، أو التركيز، أو درجة اللون، أو أي اختلاف آخر مثل: أحمر شفاه بألوان مختلفة. وعند الإجابة بلا فذلك يعني أنك ترغب بإضافة منتج واحد فقط، مثل كريم.",
                    "isActive": false
                },
                {
                    "index": 19,
                    "question": "كيف يمكنني إدراج تشكيلة منتجات (مجموعة هدايا/ مجموعة عروض ترويجية) تباع في علبة واحدة؟ ",
                    "answer": "تعد هذه المنتجات طقم ويمكن إدراجها من خلال خدمات المنتجات<إضافة منتج جديد<إدراج طقم منتجات تجميل (إخطار بالتسويق)، قم بإدخال معلومات الطقم ثم تحديد المنتجات المراد إضافتها داخل الطقم وذلك بعد إدراجها كمنتجات منفردة.",
                    "isActive": false
                },
                {
                    "index": 20,
                    "question": "ما الذي يلزم عمله حال عدم ظهور المكون المراد إدخاله عند البحث في المكونات؟ ",
                    "answer": "تأكد من كتابة اسم المكون بالطريقة الصحيحة. وفي حال لم يتم العثور على المكون يرجى البحث برقم الــCAS مع مراعاة ترك حقل اسم المكون فارغا. إذا لم تتمكن من العثور على المكون بعد اتباع الإرشادات، يرجى التواصل مركز الاتصال الموحد 19999 وتزيدهم باسم المكون ورقم الــCAS حال توفره. ",
                    "isActive": false
                },                
            ],
        },
        {
            "type": "الدواء",
            "typeId": 4,
            "questionsAndAnswers": [
                {
                    "index": 1,
                    "question": "ما هي آلية فتح حساب الكتروني في النظام الموحد (غد) لترخيص منشآت الدواء؟",
                    "answer": "لإنشاء الحساب، يتم استخدام سجل تجاري متضمن للنشاط الذي سيتم ترخيصه باستخدام الرقم الموحد للمنشأة الذي يبدأ بالرقم (7) ، ويمكنكم الاطلاع على قائمة الأنشطة الاقتصادية الخاصة بالهيئة ISIC4 من خلال موقعها الإلكتروني.",
                    "isActive": false
                },
                {
                    "index": 2,
                    "question": "ماهي طريقة استعادة كلمة المرور واسم المستخدم وبيانات الدخول بشكل عام في النظام الموحد (غد) لترخيص منشآت الدواء؟",
                    "answer": "باختيار (نسيت كلمة المرور) في قائمة تسجيل الدخول، ومن ثم استعادة البيانات المطلوبة على البريد الإلكتروني المسجل في النظام.",
                    "isActive": false
                },
                {
                    "index": 3,
                    "question": "ماهي الالية المتبعة لاستعادة كلمة المرور لنظام SDR ؟",
                    "answer": "يمكن عن طريق طلب استعادة كلمة المرور من خلال نظام DENR.",
                    "isActive": false
                },
                {
                    "index": 4,
                    "question": "هل يجب ان يكون هناك مفوض للإدراج او التسجيل يختلف عن المفوض المرفق مع السجل؟",
                    "answer": "لا يوجد هناك مفوض وعلى الشركة الوكيلة التسجيل على نظام DENR.",
                    "isActive": false
                },
                {
                    "index": 5,
                    "question": "متى يتم إضافة السجل الفرعي في التسجيل في نظام ؟",
                    "answer": "بعد الموافقة على الحساب الرئيسي .",
                    "isActive": false
                },
                {
                    "index": 6,
                    "question": "ما هي آلية فتح حساب الكتروني في نظام ELS لترخيص المنشآت؟",
                    "answer": "لإنشاء الحساب ، يتم استخدام سجل تجاري \"سواء رئيسي أو فرعي\" متضمن للنشاط الذي سيتم ترخيصه مع كتابة الرقم الموحد للمنشأة الذي يبدأ بالرقم (7) ، ويمكنكم الاطلاع على قائمة الأنشطة الاقتصادية الخاصة بالهيئة ISIC4  من خلال موقعها الإلكتروني.",
                    "isActive": false
                },
                {
                    "index": 7,
                    "question": "ماهي طريقة استعادة  كلمة المرور واسم المستخدم وبيانات الدخول بشكل عام في نظام ELS لترخيص المنشآت؟",
                    "answer": "باختيار (لا أستطيع الدخول إلى حسابي) في قائمة تسجيل الدخول ، ومن ثم استعادة البيانات المطلوبة على البريد الإلكتروني المسجل في النظام.",
                    "isActive": false
                },                
                {
                    "index": 8,
                    "question": "ماهي خطوات تغيير مسؤول مستودع ؟",
                    "answer": "يتم تغيير المدير الفني لمستودع الدواء من خلال الدخول على حساب العميل ثم اختيار خانة \"خدمات الترخيص\" ومن \"تراخيص الأدوية الخاصة بي\" يتم تحديد الرخصة واختيار \"تحديث\" من قائمة إجراءات الترخيص الفعال ثم الدخول إلى بيانات مدير المستودع وتغييرها إلى المدير البديل وإرفاق المستندات الخاصة به.",
                    "isActive": false
                },                
                {
                    "index": 9,
                    "question": "كيف يمكن التقديم على رخصة مصنع ادوية؟ وكم هي مدة دراسة الطلب؟",
                    "answer": "يتم تقديم طلب ترخيص مصنع مستحضرات صيدلانية وعشبية من خلال إنشاء حساب باستخدام سجل تجاري يتضمن النشاط على النظام الإلكتروني الموحد (غد) وبعد الموافقة على الحساب يتم تقديم طلب الرخصة وإرفاق المستندات المطلوبة.",
                    "isActive": false
                },
                {
                    "index": 10,
                    "question": "كيف يمكن طباعة ترخيص مصنع ادوية؟",
                    "answer": "بإمكان العميل طباعة ترخيص مصانع المستحضرات الصيدلانية والعشبية من خلال حسابه في النظام الإلكتروني الموحد (غد) بالضغط على كلمة تنزيل أو Download من قائمة التراخيص.",
                    "isActive": false
                },
                {
                    "index": 13,
                    "question": "كيف يمكن التقديم على ترخيص مستودع ادوية ؟",
                    "answer": "يتم تقديم طلب ترخيص مستودع مستحضرات صيدلانية وعشبية من خلال إنشاء حساب باستخدام سجل تجاري يتضمن النشاط على النظام الإلكتروني الموحد (غد) وبعد الموافقة على الحساب يتم تقديم طلب الرخصة وإرفاق المستندات المطلوبة.",
                    "isActive": false
                },
                {
                    "index": 14,
                    "question": "ما  هي الية الحصول على ترخيص تصنيع منتجات منزليا ؟",
                    "answer": "كون هذه الاستفسارات تخص الدواء ، فإنه لا يمكن تصنيع المستحضرات الصيدلانية منزليا",
                    "isActive": false
                },
                {
                    "index": 15,
                    "question": "هل يلزم تسجيل المنتج عند كل عملية استيراد؟",
                    "answer": "يلزم تسجيل المستحضرات الغير مسجلة في حال تسجيلها يلزم الشركة تقديم التغييرات الخاصة بالطلب.",
                    "isActive": false
                },
                {
                    "index": 16,
                    "question": "كيف يمكنني التعديل على طلب مدرج؟",
                    "answer": "يلزم التأكد من تصنيف المنتج من خلال نظام التصنيف أو الاطلاع على دليل التصنيف المنشور على موقع الهيئة.",
                    "isActive": false
                },
                {
                    "index": 17,
                    "question": "ماهي رسوم تسجيل المستحضرات ومتى يحق للمستورد البدء بالاستيراد بعد التسجيل؟",
                    "answer": "يمكن معرفة الرسوم بالاطلاع على تعميم المقابل المادي للمستحضرات رقم 22981 وتاريخ 15/8/1442 ، ويمكن الاستيراد بمجرد الحصول على شهادة التسجيل.",
                    "isActive": false
                },
                {
                    "index": 18,
                    "question": "كيفية معرفة ان المستحضر الصحي يدرج او يسجل ؟",
                    "answer": "يمكن للشركة طلب تصنيف للمنتج ومعرفة خضوعه للتسجيل أو الادارج.",
                    "isActive": false
                },
                {
                    "index": 19,
                    "question": "ماهي الالية المتبعة في حالة عدم إمكانية تقديم طلب تجديد لمستحضر في نظام SDR؟",
                    "answer": "يمكن التواصل على البريد الالكتروني (SDR.Drug@sfda.gov.sa) لشرح الإشكالية بالتفصيل.",
                    "isActive": false
                },
                {
                    "index": 20,
                    "question": "من أي نظام يمكن تسجيل الأدوية؟",
                    "answer": "نظام تسجيل الأدوية السعودي (سدر).",
                    "isActive": false
                },
                {
                    "index": 21,
                    "question": "كم مدة دراسة طلب تسجيل الدواء؟",
                    "answer": "يمكن الاطلاع على مدد التسجيل للمستحضرات في الهيكل الاجرائي للترخيص المنشور على موقع الهيئة على الرابط (https://www.sfda.gov.sa/sites/default/files/2021-10/RegulatoryFramework_1.pdf).",
                    "isActive": false
                },
                {
                    "index": 22,
                    "question": "ما  هي الالية المتبعة لمعرفة ما اذا كان هذا الدواء مصرح له ام لا ؟",
                    "answer": "عن طريقة تطبيق طمني أو قائمة الأدوية المسجلة على موقع الهيئة الالكتروني.",
                    "isActive": false
                },
                {
                    "index": 23,
                    "question": "ماهي المستندات والاشتراطات المطلوبة لاستيراد الأدوية البشرية ؟",
                    "answer": "خطاب من المستشفى + صورة ترخيص مستودع + شهادة تثبت ان المستحضر مسوق في بلد المنشاء +شهادة تثبت ان المستحضر مسجل في جهة اعتبارية  علما بان كل المتطلبات موضحة في مدونه إجراءات الاستيراد والتصدير https://www.sfda.gov.sa/sites/default/files/2021-07/Drug_Import_Export.pdf",
                    "isActive": false
                },
                {
                    "index": 24,
                    "question": "ما هي الالية المتبعة في حال استيراد منتج من بلد و مصّدر المنتج من بلد اخر هل يسجل المنتج من نفس بلد الصنع ام المستورد منه ؟",
                    "answer": "التسجيل في البلد الذي سيتم شحن المستحضر منه  أي البلد المسوق .",
                    "isActive": false
                },
                {
                    "index": 25,
                    "question": "هل يمكن القيام بإجراء الاستيراد في حال ان المستودع تحت الاجراء ولم يحصل على الترخيص النهائي بعد؟",
                    "answer": "ترخيص المستودع متطلب للفسح.",
                    "isActive": false
                },
                {
                    "index": 26,
                    "question": "ماهي الية عمل المناقصات للمستشفيات الحكومية والمراكز الصحية الخاصة وكيفية الاستيراد ؟",
                    "answer": "حسب ماهوم وضح في مدونة إجراءات الاستيراد  والتصدير  بتزويدنا بخطاب من الجهة المستفيدة و التقدم عن طريق نظام Ibrcs  والالتزام ببقية الشروط الموضحة بالمدونة https://www.sfda.gov.sa/sites/default/files/2021-07/Drug_Import_Export.pdf",
                    "isActive": false
                },
                {
                    "index": 27,
                    "question": "متى يجب الحصول على اذن استيراد للأدوية؟",
                    "answer": "في حال طلب دواء لصالح جهة مستفيدة صحية معينة و لم يكن له بديل مسجل ومتوفر في السوق",
                    "isActive": false
                },
                {
                    "index": 28,
                    "question": "هل يلزم الحصول على اذن استيراد للأدوية المسجلة؟",
                    "answer": "لا، اذونات الاستيراد خاصة بالأدوية الغير مسجلة فقط",
                    "isActive": false
                },
                {
                    "index": 29,
                    "question": "هل يلزم دفع رسوم حالياً للحصول على إذن استيراد للأدوية الغير مسجلة؟",
                    "answer": "لا",
                    "isActive": false
                },
                {
                    "index": 30,
                    "question": "ما هي متطلبات الحصول على اذن استيراد للأدوية الغير مسجلة؟",
                    "answer": "الشروط والمتطلبات موضحة في مدونة إجراءات الاستيراد والتصدير عبر الرابط التالي: https://www.sfda.gov.sa/sites/default/files/2022-11/Drug_Import_Export_0.pdf",
                    "isActive": false
                },
                {
                    "index": 31,
                    "question": "ما هي منصة تقديم طلبات اذونات الاستيراد للأدوية الغير مسجلة عبر الوكلاء والموردين لصالح الجهات الصحية المستفيدة؟",
                    "answer": "يتم تقديم الطلب عبر نظام ابركس بالرابط التالي: https://ibrcs.sfda.gov.sa/webpages/common/HomeAr.aspx",
                    "isActive": false
                },
                {
                    "index": 32,
                    "question": "ما هو الاجراء المُتبع في حال تقديم طلب استيراد دواء غير مسجل من خلال الجهة الصحية مباشرة؟",
                    "answer": "حالياً يتم تقديم الطلب عبر الاتصالات الإدارية او عبر البريد الالكتروني import.drug@sfda.gov.sa",
                    "isActive": false
                },
                {
                    "index": 33,
                    "question": "هل استطيع شحن الشحنة قبل الحصول على أذن استيراد للأدوية الغير مسجلة؟",
                    "answer": "لا، يلزم الحصول على أذن الاستيراد قبل الشحن",
                    "isActive": false
                },
                {
                    "index": 34,
                    "question": "ما هي المدة المستغرقة للحصول على أذن الاستيراد للأدوية الغير مسجلة؟",
                    "answer": " 30 يوم عمل في حال اكتمال المتطلبات اللازمة",
                    "isActive": false
                },
                {
                    "index": 35,
                    "question": "هل يمكن تقديم طلب تجديد اذن استيراد منتهي الصلاحية للأدوية الغير مسجلة؟",
                    "answer": "لا، يلزم تقديم طلب جديد مع كامل المتطلبات اللازمة",
                    "isActive": false
                },
                {
                    "index": 36,
                    "question": "ما هي متطلبات استيراد أدوية للاستخدام الشخصي؟",
                    "answer": "الشروط والمتطلبات موضحة في مدونة إجراءات الاستيراد والتصدير عبر الرابط التالي: https://www.sfda.gov.sa/sites/default/files/2022-11/Drug_Import_Export_0.pdf",
                    "isActive": false
                },
                {
                    "index": 37,
                    "question": "ما هي منصة تقديم طلبات اذونات استيراد الادوية للاستخدام الشخصي؟",
                    "answer": "حالياً يتم تقديم الطلب عير البريد الألكتروني import.drug@sfda.gov.sa ",
                    "isActive": false
                },
                {
                    "index": 38,
                    "question": "ما هي المدة المستغرقة للحصول على أذن استيراد ادوية للاستخدام الشخصي؟",
                    "answer": " ثلاثة أيام عمل في حال اكتمال المتطلبات اللازمة",
                    "isActive": false
                },
                {
                    "index": 39,
                    "question": "هل يلزم دفع رسوم حالياً للحصول على إذن استيراد ادوية للاستخدام الشخصي؟",
                    "answer": "لا",
                    "isActive": false
                },
                {
                    "index": 40,
                    "question": "ما هي متطلبات الحصول على اذن تصدير الأدوية؟",
                    "answer": "الشروط والمتطلبات موضحة في مدونة إجراءات الاستيراد والتصدير عبر الرابط التالي: https://www.sfda.gov.sa/sites/default/files/2022-11/Drug_Import_Export_0.pdf",
                    "isActive": false
                },
                {
                    "index": 41,
                    "question": "هل يلزم دفع رسوم حالياً للحصول على إذن تصدير الأدوية؟",
                    "answer": "لا",
                    "isActive": false
                },
                {
                    "index": 42,
                    "question": "ما هي منصة تقديم طلبات اذونات تصدير الأدوية؟",
                    "answer": "حالياً يتم تقديم الطلب عير البريد الألكتروني export.drug@sfda.gov.sa ",
                    "isActive": false
                },
                {
                    "index": 43,
                    "question": "ما هي المدة المستغرقة للحصول على أذن تصدير أدوية؟",
                    "answer": " ثلاثة أيام عمل في حال اكتمال المتطلبات اللازمة",
                    "isActive": false
                },
                {
                    "index": 44,
                    "question": "ما هي متطلبات تصدير أدوية للاستخدام الشخصي؟",
                    "answer": "الشروط والمتطلبات موضحة في مدونة إجراءات الاستيراد والتصدير عبر الرابط التالي: https://www.sfda.gov.sa/sites/default/files/2022-11/Drug_Import_Export_0.pdf",
                    "isActive": false
                },
                {
                    "index": 45,
                    "question": "ما هي المدة المستغرقة للحصول على أذن تصدير أدوية للاستخدام الشخصي؟",
                    "answer": " ثلاثة أيام عمل في حال اكتمال المتطلبات اللازمة",
                    "isActive": false
                },
                {
                    "index": 46,
                    "question": "هل يلزم دفع رسوم حالياً للحصول على إذن تصدير أدوية للاستخدام الشخصي؟",
                    "answer": "لا",
                    "isActive": false
                }
            ],
        },
        {
            "type": "الأجهزة الطبية",
            "typeId": 5,
            "questionsAndAnswers": [
                {
                   "index":1,
                   "question":"من الذي يقوم بتسجيل الأجهزة والمستلزمات الطبية بالنظام الموحد (غد)؟ وماهي آلية التسجيل؟",
                   "answer":"المصنع المحلي أو الممثل المعتمد أو المصنع الخارجي، من خلال النظام الموحد (غد) وذلك بعد استيفاء المتطلبات والاشتراطات الفنية كما في الدليل الارشادي (MDS – REQ 1)",
                   "isActive":false
                },
                {
                   "index":2,
                   "question":"أين أجد المتطلبات والأنظمة واللوائح والنماذج وورش العمل و التعاميم الخاصة بالأجهزة والمستلزمات الطبية على موقع الهيئة؟",
                   "answer":"يمكنك الاطلاع على جميع المتطلبات والانظمة واللوائح وذلك من خلال موقع الهيئة الرسمي ← المجالات ← الأنظمة واللوائح ← أجهزة طبية.\nيمكنك الاطلاع على جميع تعاميم الهيئة وذلك من خلال موقع الهيئة الرسمي ← المجالات ← التعاميم ← أجهزة طبية.\nيمكنك الاطلاع على النماذج  وذلك من خلال موقع الهيئة الرسمي ← المجالات ← النماذج ← أجهزة طبية.\nيمكنك الاطلاع على جميع ورش العمل المنشورة على موقع الهيئة وذلك من خلال موقع الهيئة الرسمي ← المركز الإعلامي ← ورش العمل.",
                   "isActive":false
                },
                {
                   "index":3,
                   "question":"ماهو المقابل المالي  للحصول على شهادة الإذن بالتسويق للأجهزة والستلزمات الطبية ومدة صلاحية الشهادة؟",
                   "answer":"المقابل المالي للتقييم العلمي للأجهزة والمستلزمات الطبية:-\n 1- 15000 ريال سعودي\n19000-2 ريال سعودي\n21000-3 ريال سعودي\n23000-4 ريال سعودي\n وذلك حسب تصنيف خطورة الجهاز أو المستلزم الطبي ومدة صلاحية الشهادة 3 سنوات ويمكن تجديدها قبل إنتهائها ب 90 يوم وبمقابل 5000 ريال سعودي  لمدة 3 سنوات .",
                   "isActive":false
                },
                {
                   "index":4,
                   "question":"كيف يمكنني التواصل مع قطاع الأجهزة والمستلزمات الطبية؟",
                   "answer":"يمكنك التواصل عبر مركز الاتصال الموحد 19999.\n للاستفسار عن طلبات الاذن بالتسويق يمكنكم التواصل مع (بريد إلكتروني: mdma.it@sfda.gov.sa)\nللاستفسار عن الدراسات السريرية يمكنكم التواصل مع (بريد إلكتروني: mdci@sfda.gov.sa).\nللاستفسار عن اللوائح  يمكنكم التواصل مع (بريد إلكتروني: guidelines.md@sfda.gov.sa).\nللاستفسار عن المواصفات يمكنكم التواصل مع (بريد إلكتروني: md.standards@sfda.gov.sa)\nللاستفسار عن الأجهزة الطبية المبتكرة يمكنكم التواصل مع (بريد إلكتروني: novel.md@sfda.gov.sa).\nوللمزيد من المعلومات حول الاشتراطات والمتطلبات، يمكنكم التواصل مع قسم دعم تسجيل المنتجات (بريد إلكتروني: md.rs@sfda.gov.sa).",
                   "isActive":false
                },
                {
                   "index":5,
                   "question":"ماهي العقوبات والمخالفات المقررة لها وفق نظام الأجهزة والمستلزمات الطبية؟",
                   "answer":"يمكنكم الاطلاع جدول تصنيف المخالفات والعقوبات المقررة لها وفقا لنظام الاجهزة والمستلزمات الطبية ولائحته التنفيذية   ( https://www.sfda.gov.sa/ar/regulations/87131 )",
                   "isActive":false
                },
                {
                   "index":6,
                   "question":"مالمقصود بالممثل المعتمد؟ وهل يجوز تعيين أكثر من ممثل معتمد لنفس المنتج؟",
                   "answer":"الممثل المعتمد: هوشخص ذو صفة اعتبارية مقره في المملكة مفوض كتابيًّا من مصنع مقيم في الخارج لتمثيله داخل المملكة، فيما يتصل بتطبيق أحكام النظام واللائحة. \nلا يسمح للمصنع تعيين أكثر من ممثل معتمد لنفس الصنف أو المجموعة العامة للأجهزة والمستلزمات الطبية، ويمكن له تعين ممثل معتمد مختلف لكل صنف أو مجموعة عامة للأجهزة والمستلزمات الطبية. ",
                   "isActive":false
                },
                {
                   "index":7,
                   "question":"هل يجوز الدعاية والاعلان للجهاز الطبي؟",
                   "answer":"لا تجوز الدعاية للأجهزة والمستلزمات الطبية ولا الإعلان عنها ولا الترويج لها، إلَّا بعد موافقة الهيئة ووفقًا لمتطلبات الموافقة على الدعاية والإعلان وإقامة حملات توعوية أو خيرية للأجهزة والمستلزمات الطبية (MDS-REQ8).",
                   "isActive":false
                },
                {
                   "index":8,
                   "question":"هل يجوز بيع الجهاز  أو المستلزم الطبي داحل المملكة العربية السعودية؟",
                   "answer":"لا يجوز تداول أي جهاز أو مستلزم طبي إلَّا بعد التسجيل والحصول على شهادة الإذن بالتسويق وذلك للتأكد من سلامته ومأمونيته، كما يمكن الحصول على الشهادة بعد إستيفاء جميع المتطلبات والملفات الفنية للمنتج وذلك وفقًا للدليل الارشادي المنشور على موقع الهيئة (MDS – REQ 1).",
                   "isActive":false
                },
                {
                   "index":9,
                   "question":"ماهو نظام إدارة الجودة للأجهزة الطبية وهل يلزم تطبيقه؟",
                   "answer":"هو نظام تعتمده الهيئة للتحقق من جودة وفاعلية ومأمونية الجهاز أو المستلزم الطبي وفقًا للنسخة الحديثة من المواصفة الفنية (الآيزو 13485) أو ما يماثلها، وذلك وفقًا لما تبينه اللائحة.\nويجب على جميع مصانع الأجهزة والمستلزمات الطبية والمستوردين والموزعين من فئتي (أ) و (ب) الحصول على شهادة نظام إدارة الجودة من أحد مكاتب التحقق من مطابقة الأجهزة والمستلزمات الطبية ونظام إدارة الجودة المعتمدة لدى الهيئة وفقاً للمواصفة القياسية السعودية (SFDA.MD\\/GSO ISO 13485) أو ما يماثلها.\nويجب على الممثلين المعتمدين والمستوردين والموزعين من فئتي (ج) و (د) تقديم ما يثبت تطبيق نظام إدارة الجودة أو تقرير تفتيش من الهيئة، الذي يؤكد امتثالها لمتطلبات نظام إدارة الجودة وفقاً للمواصفة القياسية السعودية (SFDA.MD\\/GSO ISO 13485) أو ما يماثلها.\nولتفاصيل أكثر لذلك يمكن الرجوع الى متطلبات التفتيش ونظام إدارة الجودة للأجهزة والمستلزمات الطبية (MDS-REQ10).",
                   "isActive":false
                },
                {
                   "index":10,
                   "question":"كيف يمكنني استيراد جهاز طبي منزلي للاستخدام الشخصي؟",
                   "answer":"يمكن ذلك وفقا للاشتراطات المذكورة في متطلبات فسح الأجهزة والمستلزمات الطبية عند المنافذ الجمركية (MDS-REQ5)",
                   "isActive":false
                },
                {
                   "index":11,
                   "question":"هل توجد متطلبات لاستخدام الأجهزة والمستلزمات الطبية داخل مرافق الرعاية الصحية؟",
                   "answer":"قامت الهيئة بتحديد وتوضيح متطلبات الاستخدام الآمن للأجهزة والمستلزمات الطبية والمواد الطبية المشعة داخل مرافق الرعاية الصحية بهدف ضمان تحقيق سلامة وكفاءة وجودة الأجهزة والمستلزمات الطبية من خلال نشر متطلبات الاستخدام الآمن للأجهزة والمستلزمات الطبية داخل مرافق الرعاية الصحية (MDS-REQ 3).",
                   "isActive":false
                },
                {
                   "index":12,
                   "question":"ما هو الترميز الموحد للأجهزة الطبية (UDI)؟",
                   "answer":"يهدف الترميز لتعريف الجهاز الطبي بشكل محدد وواضح من خلال تطبيق معايير ترميز معتمدة من منظمات ترميز دولية معترف بها من الهيئات الرقابية. مثل . GS1, ICCBBA, HIBCC\nيمكنكم الاطلاع على متطلبات الترميز الموحد في الدليل (MDS-REQ7)،ويمكنكم الوصول الى نظام الترميز الموحد للأجهزة الطبية SAUDI-DI  وكذلك الاطلاع على الية التسجيل من خلال النظام عبر الرابط: \n https://udi.sfda.gov.sa/  ",
                   "isActive":false
                },
                {
                   "index":13,
                   "question":"مالمقصود بضابط الإتصال لدى مرافق الرعاية الصحية؟ و ماهي مهام و مسؤوليات ضابط الإتصال؟",
                   "answer":"شخص معين من مقدم الرعاية الصحية يمثل حلقة وصل مع المركز الوطني لبلاغات الأجهزة والمستلزمات الطبية.\nيمكن الإطلاع على مهام و مسؤوليات ضابط الإتصال من خلال الرجوع الى متطلبات رقابة ما بعد التسويق للأجهزة والمستلزمات الطبية (MDS-REQ 11) ",
                   "isActive":false
                },
                {
                   "index":14,
                   "question":"هل توجد متطلبات لأنشطة ما بعد التسويق للأجهزة والمستلزمات الطبية؟",
                   "answer":"نعم، توجد متطلبات رقابة ما بعد التسويق للأجهزة والمستلزمات الطبية (MDS-REQ 11) تم نشرها بموقع الهيئة، مثل كيفية الإبلاغ عن حوادث الأجهزة و المستلزمات الطبية - مراحل الإجراءات التصحيحة لإنذارات السلامة - متطلبات إعادة المعالجة - إتلاف الأجهزة و المستلزمات الطبية و غيرها. ",
                   "isActive":false
                },
                {
                   "index":15,
                   "question":"ماهي تقارير تحليل المخاطر ؟",
                   "answer":"هي تقارير لتحليل وتقييم إشارات السلامة المحتملة والمتعلقة  بجهاز\\/ مستلزم طبي وتحديد المخاطرالمحتملة والخروج بتوصيات تعالج تلك المخاطر",
                   "isActive":false
                },
                {
                   "index":16,
                   "question":"ماذا نقصد بدراسات التقييم السريري لما بعد التسويق؟",
                   "answer":"هي دراسات يتم اجراءاها بمنهجية علمية على جهاز \\/مستلزم طبي للتحقق من سلامته\\/فعاليته نتيجة وجود إشارات سلامة على ذلك الجهاز المسوق محلياً",
                   "isActive":false
                },
                {
                   "index":17,
                   "question":"ماهي المخرجات المتوقعه من دراسات التقييم السريري لما بعد التسويق؟",
                   "answer":"قرارات \\/ إجراءات رقابية  تشمل على سبيل المثال لا الحصر إجراءات تصحيحية أو وقائية تهدف لتقليل أو إزالة الخطر المراد دراسته والمتعلق بالجهاز \\/ المستلزم الطبي ",
                   "isActive":false
                },
                {
                   "index":18,
                   "question":"هل معايير ومتطلبات التبليغ عن حوادث الأجهزة والمستلزمات الطبية المدرجة ضمن الدراسات السريرية تختلف عن المعايير العامة للتبليغ عن الأجهزة التي لا تخضع للدراسات السريرية؟",
                   "answer":"نعم تختلف، يمكن الاطلاع على متطلبات الإبلاغ والتحقيق في حوادث وشكاوى الأجهزة والمستلزمات الطبية من خلال الرجوع إلى متطلبات رقابة ما بعد التسويق للأجهزة والمستلزمات الطبية (MDS-REQ-11)، ويمكن الاطلاع على متطلبات الإبلاغ والتحقيق في حوادث الأجهزة والمستلزمات الطبية المدرجة ضمن الدراسات السريرية من خلال الرجوع إلى متطلبات الدراسات السريرية للأجهزة والمستلزمات الطبية (MDS-REQ-2)",
                   "isActive":false
                },
                {
                   "index":19,
                   "question":"هل من الممكن اتلاف الأجهزة والمستلزمات الطبية بالطرق التقليدية للإتلاف (مثل الرمي المباشر في سلة المهملات)؟",
                   "answer":"لا، يجب الإمتثال لمتطلبات \"إتلاف الأجهزة والمستلزمات الطبية المستخدمة\" المذكورة في متطلبات رقابة مابعد التسويق للأجهزة والمستلزمات الطبية (MDS-REQ-11)",
                   "isActive":false
                },
                {
                   "index":20,
                   "question":"ماهي خطة المراقبة السنوية للأجهزة الطبية المنزلية؟",
                   "answer":"أحد النشاطات الاستباقية التي تقوم بها الهيئة العامة للغذاء والدواء ممثلة بقطاع الأجهزة والمستلزمات الطبية للتحقق من أن الأجهزة والمستلزمات الطبية التي يتم تسويقها بالمملكة العربية السعودية تتوافق مع معايير ومتطلبات الهيئة، عن طريق إجراء اختبارات معملية لعدد من الأجهزة والمستلزمات الطبية، التي يتم اختيارها سنويا ، ويتم سحبها عشوائيا من مختلف نقاط البيع، للتأكد من سلامتها وكفاءتها و وفعاليتها في تحقيق الغرض الذي صنعت من اجله.",
                   "isActive":false
                },
                {
                   "index":21,
                   "question":"ماذا نقصد بإنذار السلامة ؟",
                   "answer":"إشعار يصدره المركز يوضح الخطر المرتبط بالجهاز أو المستلزم الطبي والإجراءات التصحيحية المطلوب القيام بها؛ تلافيًا للخطر المرتبط به.",
                   "isActive":false
                },
                {
                   "index":22,
                   "question":"هل الجهاز الذي يصدر عليه انذار سلامة لايمكن استخدامه؟ ",
                   "answer":"يعتمد على توصيات المصنع والاجراء التصحيحي المعتمد، فقد يتضمن الاجراء التصحيحي  التوقف عن الاستخدام او الاستمرار مع تطبيق التوصيات الوقائية ",
                   "isActive":false
                },
                {
                   "index":23,
                   "question":"هل من الممكن أستخدام جهاز طبي حاصل على أذن تسويق منتهي الصلاحية",
                   "answer":"يجب عدم استخدام أي جهاز او مستلزم طبي غير حاصل على أذن تسويق والتأكد من الجهاز والمستلزم الطبي حاصل على شهادة اذن بالتسويق (ساري الصلاحية)",
                   "isActive":false
                },
                {
                   "index":24,
                   "question":"متى يجب الابلاغ عن حوادث الأجهزة والمستلزمات الطبية",
                   "answer":"يجب على المصانع والممثلين المعتمدين ومقدمي الرعاية الصحية إبلاغ المركز عن حوادث الأجهزة والمستلزمات الطبية التابعة لهم خلال الفترة المحددة أدناه: \n• خلال (يومين) من وقوع الحادثة أوالعطل محل الشكوى أوالعلم به عندما تمثل تهديداً للصحة العامة. \n• خلال (10) أيام من وقوع الحادثة أو العطل محل الشكوى أوالعلم به عندما تمثل تهديداً قد يسبب أويسهم بصورة مباشرة أو غيرمباشرة في وفاة أوإصابة خطيرة. \n• خلال (30) يوم من وقوع الحادثة أو العطل محل الشكوى أوالعلم به بخالف ماذكر.\n",
                   "isActive":false
                },
                {
                   "index":25,
                   "question":"     ما هو نظام تسجيل المواد الطبية المشعة؟",
                   "answer":"هو نظام إلكتروني معني بتسجيل طلبات استيراد وإعادة تصدير المواد الطبية المشعة للمنشآت الصحية  والشركات المستوردة والمصدرة للمواد الطبية المشعة. كما يساهم النظام في التنسيق بين جميع الأطراف ذات العلاقة بحيث يضمن وصول تلك المواد للمستفيد بالنشاطية والكمية المصرح بها، ويمكن الدخول على النظام عبر الرابط التالي:\nhttps://mrmr.sfda.gov.sa",
                   "isActive":false
                },
                {
                   "index":26,
                   "question":"ما هي المنشات المستهدفة في النظام الإلكتروني لتسجيل المواد الطبية المشعة؟ ",
                   "answer":"المنشات المستوردة والمصدرة للمواد الطبية المشعة\nالمنشات الناقلة للمواد الطبية المشعة\nالمنشآت الصحية المستخدمة للمواد الطبية المشعة\nالمنشآت المنتجة للمواد الطبية المشعة",
                   "isActive":false
                },
                {
                   "index":27,
                   "question":"ما هي رسوم التسجيل في النظام الالكتروني لتسجيل المواد الطبية المشعة؟ وهل يوجد رسوم على اصدار الفسح؟",
                   "answer":"لا يتطلب دفع رسوم لفتح حساب في النظام الالكتروني في الوقت الحالي ولا يوجد رسوم على اصدار الفسح",
                   "isActive":false
                },
                {
                   "index":28,
                   "question":"ماهي طريقة التقديم على ترخيص نشاط تقديم خدمات توكيد الجودة النوعية؟",
                   "answer":"يتم التقديم على نشاط تقديم خدمات توكيد الجودة النوعية عن طريق تسليم المستندات ورقيًا عبر الاتصالات الإدارية وفق النماذج المتوفرة على موقع الهيئة",
                   "isActive":false
                },
                {
                   "index":29,
                   "question":"ماهي رسوم الحصول على رخصة نشاط تقديم خدمات توكيد الجودة ومدة صلاحية الشهادة؟",
                   "answer":"الرخصة سارية المفعول لمدة ثلاث سنوات من تاريخ الإصدار، والمقابل المالي لها 5 الاف ريال",
                   "isActive":false
                },
                {
                   "index":30,
                   "question":"ماهي متطلبات تقييم الاستخدام الأمن للأجهزة المصدرة للإشعاع في المنشآت الصحية؟",
                   "answer":"يمكن الوصول للمتطلبات عبر الرابط التالي\nhttps://sfda.gov.sa/sites/default/files/2022-01/MDS-REQ3.pdf",
                   "isActive":false
                },
                {
                   "index":31,
                   "question":"ماهي مواد التصوير الطبي؟",
                   "answer":"هي مواد صيدلانية غير مشعة أو مواد تستخدم في التنظير الإشعاعي، ويشترط موافقة الهيئة لاستيرادها",
                   "isActive":false
                },
                {
                   "index":32,
                   "question":"ما هي متطلبات استيراد وفسح مواد التصوير الطبي؟ ",
                   "answer":"يمكن الوصول للمتطلبات عبر الرابط التالي\nhttps://sfda.gov.sa/sites/default/files/2022-01/MDS-REQ4.pdf",
                   "isActive":false
                },
                {
                   "index":33,
                   "question":"ما هي المواصفة القياسية؟ ",
                   "answer":"هي وثيقة تقرها الهيئة وتنص على قواعد أو مبادئ توجيهية أو خصائص للمنتجات أو عمليات وأساليب الإنتاج المرتبطة بها والتي يكون أتباعها غير إلزامي.\n\n كما قد تشمل او تقتصر على المصطلحات أو الرموز أو التعبئة أو متطلبات التوسيم أو البطاقة التعريفية المتعلقة بالمنتج أو عملية أو طريقة إنتاج ما أو طريقة الاختبار\n",
                   "isActive":false
                },
                {
                   "index":34,
                   "question":"كيف أحصل على مواصفة قياسية؟ ",
                   "answer":"يمكن الوصول الى مشاريع المواصفات السعودية من خلال نظام آراء لإبداء الرأي، كما يمكن الحصول على المواصفات القياسية السعودية عن طريق المتجر الإلكتروني للمواصفات\nرابط نظام آراء: https://ideasbank.sfda.gov.sa/Regulations/Index \nرابط المتجر الالكتروني للمواصفات: https://mwasfah.sfda.gov.sa/Home \n",
                   "isActive":false
                },
                {
                   "index":35,
                   "question":"كيفية الوصول للوائح والأدلة الإرشادية  الخاصة بالأجهزة الطبية؟",
                   "answer":"من خلال موقع الهيئة العامة للغذاء والدواء -  المجالات - أجهزة طبية - اللوائح والإجراءات\nhttps://www.sfda.gov.sa/ar",
                   "isActive":false
                }
             ],
        },
        {
            "type": "حلال",
            "typeId": 6,
            "questionsAndAnswers": [
                {
                    "index": 1,
                    "question": "هل تصدر شهادة حلال من الهيئة العامة للغذاء والدواء أم من جهة أخرى ؟ ",
                    "answer": "تصدر شهادة حلال من مركز حلال بالهيئة العامة للغذاء و الدواء .",
                    "isActive": false
                },                
                {
                    "index": 2,
                    "question": "هل يجب اصدار شهادة حلال لجميع المنتجات الغذائية؟",
                    "answer": "   حاليا شهادة حلال هي الزامية مع شحنات اللحوم والدواجن ومنتجاتها وتكون صادرة من جهة معينة من قبل مركز حلال وكذلك المنتجات الغذائية المركبة التي يدخل في تركيبها بأي نسبة شي من اللحوم ومنتجاتها ومستخلصاتها وجميع المنتجات التي تحمل شعار أو كلمة \"حلال\" على منتجاتها (يمكنكم الاطلاع على التعميم)\nتعميم بشأن التأكيد على إلزامية شهادة حلال من الجهات المُعينة من قبل المركز السعودي لحلال بالهيئة العامة للغذاء والدواء | الهيئة العامة للغذاء والدواء (sfda.gov.sa) https://www.sfda.gov.sa/ar/node/89334",
                    "isActive": false
                },
                {
                    "index": 3,
                    "question": "كم مدة الاجراء للإصدار الشهادة؟",
                    "answer": "لا تزيد عن 16 يوم عمل .",
                    "isActive": false
                },
                {
                    "index": 4,
                    "question": "ماهي المنشآت المعتمدة من قبل الهيئة لاستخراج شهادة حلال ؟ ",
                    "answer": "لمعرفة قائمة الجهات المعينة يمكنكم زيارة موقع حلال الالكتروني https://halal.sfda.gov.sa/",
                    "isActive": false
                },
                {
                    "index": 5,
                    "question": "هل شهادة حلال مختصة باللحوم فقط ؟",
                    "answer": "لا (يمكنكم الاطلاع على التعميم)\nتعميم بشأن التأكيد على إلزامية شهادة حلال من الجهات المُعينة من قبل المركز السعودي لحلال بالهيئة العامة للغذاء والدواء | الهيئة العامة للغذاء والدواء (sfda.gov.sa) https://www.sfda.gov.sa/ar/node/89334",
                    "isActive": false
                },
                {
                    "index": 6,
                    "question": "ماهي المنتجات المتطلبة لشهادة حلال غير اللحوم والمنتجات الغذائية ؟ ",
                    "answer": "  حاليا شهادة حلال هي الزامية مع شحنات اللحوم والدواجن ومنتجاتها وتكون صادرة من جهة معينة من قبل مركز حلال وكذلك المنتجات الغذائية المركبة التي يدخل في تركيبها بأي نسبة شي من اللحوم ومنتجاتها ومستخلصاتها وجميع المنتجات التي تحمل شعار أو كلمة \"حلال\" على منتجاتها (يمكنكم الاطلاع على التعميم)\nتعميم بشأن التأكيد على إلزامية شهادة حلال من الجهات المُعينة من قبل المركز السعودي لحلال بالهيئة العامة للغذاء والدواء | الهيئة العامة للغذاء والدواء (sfda.gov.sa) https://www.sfda.gov.sa/ar/node/89334",
                    "isActive": false
                },
                {
                    "index": 7,
                    "question": "ماهي شروط ومتطلبات استخراج شهادة حلال ؟",
                    "answer": "المتطلبات الأساسية للمنشآت المحلية هي سجل تجاري صالح و قائمة المنتجات و ملف الشركة .\nالمتطلبات الأساسية للجهات المانحة لشهادات الحلال هي\n\
                    1.    شهادة اعتماد في مواصفات الحلال الخليجية صادرة اما من:\n\
                        •        مركز السعودي للاعتماد SAC\n\
                        •        او مركز الاعتماد الخليجي GAC\n\
                        •        او من مركز هو عضو في منظمة IHAF التي تتبع مواصفات الحلال الخليجية المقبولة لدى المركز\n\
                    2.    شهادة قبول من هيئة المواصفات والمقاييس السعودية SASO.\n\
                    3.    رخصة تجارية سارية المفعول\n\
                    ",
                    "isActive": false
                },
                {
                    "index": 8,
                    "question": "كيف يمكن معرفة ان المنتج الغذائي المسجل لدى الهيئة لا يحتوي على مواد غير حلال ؟ ",
                    "answer": "جميع المنتجات الغذائية الحاصلة على شهادة حلال تم التدقيق عليها . ",
                    "isActive": false
                },
                {
                    "index": 9,
                    "question": "ماهي شهادة حلال المعتمدة لدى الهيئة هل هي شهادة سيدال ام فامراس ؟ ",
                    "answer": "لمعرفة قائمة الجهات المعينة يمكنكم زيارة موقع حلال الالكتروني https://halal.sfda.gov.sa/",
                    "isActive": false
                },
                {
                    "index": 10,
                    "question": "هل يلزم على مستوردي الأغذية وضع كلمه ( حلال ) على منتجاتهم قبل عرضها بالأسواق ؟ ",
                    "answer": "لا يلزم .",
                    "isActive": false
                },
                {
                    "index": 13,
                    "question": "هل يتم استخراج شهادة حلال مستقلة لكل شحنة لحوم مستوردة ؟ ام شهادة واحدة تكفي في حين استيراد نفس المنتج اكثر من مره ؟ ",
                    "answer": " يتم استخراج شهادة ارسالية  لكل شحنة لحوم مستوردة .",
                    "isActive": false
                },
                {
                    "index": 14,
                    "question": "هل شهادة حلال برسوم؟",
                    "answer": "نعم برسوم",
                    "isActive": false
                }
            ],
        },
        {
            "type": "الأعلاف",
            "typeId": 7,
            "questionsAndAnswers": [
                {
                    "index": 1,
                    "question": "ماهي متطلبات ترخيص مصنع اعلاف محلي ؟ ",
                    "answer": " يتوجب على المستثمر الراغب في إقامة المصنع الحصول على موافقات الجهات الحكومية التالية: موافقة على الموقع ترخيص بلدي من وزارة الشئون البلدية والقروية  أو  موافقة وزارة الزراعة في حال كان المصنع يقع في مشروع زراعي أو رخصة تشغيل للمصانع الواقعة في المدن الصناعية والا الاقتصادية او الملكية ومافي حكمها.\n\n1. وزارة الصناعة والثروة المعدنية للحصول على الترخيص الصناعي النهائي.\n\n\n2. بعد حصول المنشأة على موافقات الجهات الحكومية المعنية يقوم المستثمر بإنشاء وتجهيز المصنع مع ضرورة تطبيق الاشتراطات الفنية الواردة في اللوائح الفنية والمواصفات القياسية، بالإضافة إلى اشتراطات الجهة المانحة لترخيص الموقع مثل: وزارة الشئون البلدية والقروية أو المدن الصناعية أو الاقتصادية.  \n3. التسجيل في النظام الإلكتروني لتسجيل وترخيص المنشآت والمنتجات العلفية بعد إرفاق جميع الموافقات من الجهات الحكومية المعنية والمذكورة في البند رقم (1).\n4. الحصول على الموافقة النهائية (ترخيص مزاولة النشاط) من الهيئة العامة للغذاء والدواء. \n",
                    "isActive": false
                },
                {
                    "index": 2,
                    "question": "ماهي اشتراطات الهيئة العامة للغذاء والدواء لترخيص مستودع اعلاف ؟ ",
                    "answer": "1. يكون التقديم لمستودع الأعلاف والإضافات العلفية الواقع خارج المدن الصناعية عبر موقع بلدي الإلكتروني التابع لوزارة الشؤون البلدية والقروية، وفي حال كان موقع المستودع داخل المدن الصناعية التابع لهيئة السعودية للمدن الصناعية ومناطق التقنية يتم توقيع عقد مع الهيئة السعودية للمدن الصناعية.\n2. تقوم المنشأة باستكمال باقي الاجراءات مع وزارة الشؤون البلدية والقروية والدفاع المدني او مع هيئة المدن الصناعية والحصول الترخيص البلدي ورخصة الدفاع المدني للمستودع أو مركز التوزيع أو رخصة التشغيل.\n3. يقوم المستثمر بتجهيز المستودع وفق الاشتراطات الفنية.\n4. التقديم بطلب الحصول على ترخيص مزاولة النشاط عبر النظام الالكتروني.ش\n5. الحصول على الموافقة النهائية (ترخيص مزاولة النشاط) من الهيئة العامة للغذاء والدواء.\n",
                    "isActive": false
                },
                {
                    "index": 3,
                    "question": "ماهي اشتراطات الهيئة العامة للغذاء والدواء لتسجيل منتج علفي مصنوع محليا او  مستورد ؟ ",
                    "answer": "أولاً يجب أن تكون المنشأة حاصلة على ترخيص من الهيئة العامة للغذاء والدواء لتتمكن من طلب خدمة تسجيل منتج علفي كما يجب ان تكون المنتجات العلفية المستوردة مطابقة لما ورد في نظام الأعلاف ولائحتة التنفيذية على الرابط ادناه:\nhttps://www.sfda.gov.sa/sites/default/files/2021-05/lawFeedA.pdf\nو مستوفية لجميع الإشتراطات الموضحة في دليل تسجيل المنتجات العلفية على الرابط ادناه: \nhttps://www.sfda.gov.sa/sites/default/files/2021-10/SFDAfeedkhjk32.pdf",
                    "isActive": false
                },
                {
                    "index": 4,
                    "question": "ماهي متطلبات الهيئة لفسح شحنة علفية مسجلة في نظام الاعلاف ؟ ",
                    "answer": "شروط فسح إرساليات المنتجات العلفية تجدونها على الرابط التالي: https://www.sfda.gov.sa/sites/default/files/2021-05/2352021aa1.pdf",
                    "isActive": false
                },
                {
                    "index": 5,
                    "question": "متى وأين أستطيع أن أتقدم بطلب فسح؟",
                    "answer": "عن طريق منصة فسح عبر الرابط  https://www.fasah.sa/trade/home/ar/index.html",
                    "isActive": false
                },
                {
                    "index": 6,
                    "question": "ماهي الطريقة المتبعة للحصول على أذن تصدير منتجات علفية ؟",
                    "answer": "يتم التقديم عن طريق البريد الالكتروني FES.Food@sfda.gov.sa كما يمكن الاطلاع على شروط ومتطلبات الحصول على أذن التصدير للمنتجات العلفيةالاعلاف عن طريق الرابط التالي : https://sfdaservices.sfda.gov.sa/Home/ServiceDetails/180 ",
                    "isActive": false
                },
                {
                    "index": 7,
                    "question": "هل هناك شهادة صحية خاصة لتصدير المنتجات العلفية ؟",
                    "answer": "نعم. ويتم طلبها حسب رغبة العميل ويمكن الاطلاع على النموذج والشروط الخاصة بالشهادة عبر الرابط التالي : https://sfdaservices.sfda.gov.sa/Home/ServiceDetails/180",
                    "isActive": false
                },
                {
                    "index": 8,
                    "question": "هل هناك شهادة حرية بيع منتج علفي ؟",
                    "answer": "نعم. ويتم طلبها حسب رغبة العميل ويمكن الاطلاع على النموذج والشروط الخاصة بالشهادة عبر الرابط التالي : https://sfdaservices.sfda.gov.sa/Home/ServiceDetails/180",
                    "isActive": false
                },
                {
                    "index": 9,
                    "question": "ماهي فترة صلاحية اذن التصدير للمنتجات العلفية ؟",
                    "answer": " فترة صلاحية اذن التصدير للمنتجات العلفية هي 4 أشهر من تاريخ صدوره",
                    "isActive": false
                },
                {
                    "index": 10,
                    "question": "ماهي فترة صلاحية الشهادة الصحية للمنتجات العلفية ؟",
                    "answer": " فترة صلاحية الشهادة الصحية للمنتجات العلفية هي 4 أشهر من تاريخ صدوره",
                    "isActive": false
                },
                {
                    "index": 11,
                    "question": "ماهي فترة صلاحية شهادة حرية بيع منتج علفي ؟",
                    "answer": "فترة صلاحية شهادة حرية بيع منتج علفي هي سنة واحدة من تاريخ الإصدار ",
                    "isActive": false
                },
                {
                    "index": 12,
                    "question": "كيف أحصل على اللوائح الفنية والمواصفات القياسية؟",
                    "answer": "يمكن الحصول على اللوائح الفنية والمواصفات القياسية من خلال المتجر الإلكتروني للمواصفات: https://mwasfah.sfda.gov.sa/Home",
                    "isActive": false
                },
                {
                    "index": 13,
                    "question": "ما هي الأعلاف الغير تقليدية؟",
                    "answer": "الأعلاف الغير تقليدية هي الأعلاف المكونة أو المشتقة من الكائنات الحية الدقيقة أو النباتات أو المصادر الحيوانية التي لم يتم اعتمادها في تصنيفات الأعلاف بالهيئة و / أو تحتوي على مكون أو سمة جديدة",
                    "isActive": false
                },
                {
                    "index": 14,
                    "question": "ماهي المنتجات العلفية   الخاضعة للتقييم العلمي؟",
                    "answer": "جميع الأعلاف الغير تقليدية",
                    "isActive": false
                },
                {
                    "index": 15,
                    "question": "ماهي متطلبات الملف العلمي للمنتجات العلفية؟  ",
                    "answer": "يجب ان يحتوي الملف العلمي على المعلومات التالية:\n• دراسات الأمان للإضافة المراد تسجيلها\n• دراسات الأمان على العمال ومستخدمي الإضافة\n• دراسات الأمان على البيئة\n• دراسة الثباتية الطبيعية والكيميائية للمنتج طبقاً لصلاحية المنتج",
                    "isActive": false
                },
                {
                    "index": 16,
                    "question": "هل يسمح باستخدام الأعلاف المعدلة وراثياً؟",
                    "answer": "يجب الالتزام واستيفاء جميع الاشتراطات الواردة في اللوائح والمواصفات الخاصة بذلك.  \n1- اللائحة الفنية (المتطلبات العامة للأغذية والأعلاف المصنعة المحورة وراثيا) رقم GSO 2142.\n2- اللائحة الفنية (المتطلبات العامة للمنتجات الزراعية غير المصنعة المحورة وراثيَا) رقم GSO 2141. ",
                    "isActive": false
                },
                {
                    "index": 17,
                    "question": "ماهي الملوثات بالأعلاف؟",
                    "answer": "الملوثات: المواد التي تؤدي إلى تلوث العلف، سواءً أكانت كيميائية (مثل: المعادن الثقيلة، والديوكسين، والمبيدات)، أو فيزيائية (مثل: الأجسام الغريبة، أو الحشرات)، أو ميكروبية (مثل: السالمونيلا، وبكتيريا القولون)، أو سموم فطرية وغيرها.",
                    "isActive": false
                },
                {
                    "index": 18,
                    "question": "هل يتم اجراء رصد للأعلاف المنتجة محلياً والمستوردة؟",
                    "answer": "نعم",
                    "isActive": false
                },
                {
                   "index":19,
                   "question":"هل يلزم أن يكون التفويض على الورق الرسمي للمنشأة؟",
                   "answer":"نعم .", 
                   "isActive":false
                },
                {
                   "index":20,
                   "question":"ماهي الصيغة المعتمدة لنموذج التفويض ؟",
                   "answer":"يجب كتابة التفويض الكترونيا وفق الصيغة المطلوبة والذي يمكنكم الحصول عليه بالرجوع للرابط\nhttps:\\/\\/www.sfda.gov.sa\\/ar\\/food\\/about\\/administration\\/mangement_food\\/Documents\\/fooddoc002.docx\n",
                   "isActive":false
                },
                {
                   "index":21,
                   "question":"ماذا لو اختلفت البيانات في التفويض عن المسجل في النظام؟",
                   "answer":"يرفض الطلب، ويتم ذكر سبب الرفض ويرسل لك رسالة الكترونية. ",
                   "isActive":false
                },
                {
                   "index":22,
                   "question":"هل أستطيع استخدام اسم المستخدم المرفوض عند فتح حساب جديد مرة اخرى؟",
                   "answer":"لا.",
                   "isActive":false
                },
                {
                   "index":23,
                   "question":"هل أستطيع أن أسجل عدة مرات في خدمات الهيئة؟",
                   "answer":"نعم حسب كل نظام .",
                   "isActive":false
                },
                {
                   "index":24,
                   "question":"ماهي خطوات فتح حساب في نظام غد  ؟",
                   "answer":" الدخول على رابط النظام الموحد (غد):https:\\/\\/ghad.sfda.gov.sa\\/ar                                   - تسجيل وانشاء الحساب ذلك من خلال الدخول على صفحة الموقع والضغط إنشاء حساب جديد.\n- تعبئة البريد الإلكتروني وكلمة المرور الخاصة بالمستخدم ثم الموافقة والضغط على تسجيل.\n- بعد ذلك سيتم ارسال بريد الكتروني من النظام للبريد الالكتروني المسجل  لتفعيل الحساب.\n- التفعيل والدخول الى صفحة الحساب عبر صفحة النظام يتم اختيار إنشاء حساب جديد.\n- يتم اختيار نوع حساب جديد تظهر خيارات نوع الحساب حساب تجاري .\n- تظهر المقدمة والخطوات الموضحة لتعبئة المعلومات .\n- اختيار نوع الاستثمار ونوع السجل التجاري.\n- ادخال معلومات السجل التجاري وتأكيد معلومات السجل التجاري.\n- ادخال معلومات المالك.\n- ادخال معلومات الاستثمار اجنبي محلي أو مختلط.\n- ادخال المجالات والانشطة الخاصة بالشركة موضح خيار نشاط مستودع أعلاف .\n- ادخال العنوان والموقع.\n- ادخال معلومات الاتصال مع المفوض .\n- الخطوة الأخيرة في انشاء الحساب التأكيد والارسال.\n",
                   "isActive":false
                },
                {
                   "index":25,
                   "question":"ماهي آلية ترخيص مستودع أعلاف ؟",
                   "answer":"آلية الحصول على ترخيص مزاولة نشاط:\n1. يكون التقديم لنشاط مستودعات الأعلاف الواقعة خارج المدن الصناعية عبر موقع بلدي الإلكتروني التابع لوزارة الشؤون البلدية والقروية والإسكان، وفي حال كان الموقع داخل المدن الصناعية التابع لهيئة السعودية للمدن الصناعية ومناطق التقنية يتم توقيع عقد مع الهيئة السعودية للمدن الصناعية.\n2. تقوم المنشأة باستكمال باقي الاجراءات مع وزارة الشؤون البلدية والقروية والإسكان او مع هيئة المدن الصناعية والحصول على الترخيص البلدي أو رخصة التشغيل.\n3. يقوم المستثمر بتجهيز المستودع وفق الاشتراطات الفنية.\n4. التقديم بطلب الحصول على ترخيص مزاولة النشاط عبر النظام الالكتروني الموحد \"غد\" للهيئة على الرابط التالي : (https:\\/\\/ghad.sfda.gov.sa) \n5. الحصول على الموافقة النهائية (ترخيص مزاولة النشاط) من الهيئة العامة للغذاء والدواء.\n\nملاحظة: لا يحق للمنشأة البدء في ممارسة نشاطها إلا بعد الحصول على ترخيص مزاولة النشاط من قبل الهيئة العامة للغذاء والدواء.\n\n\n\n",
                   "isActive":false
                },
                {
                   "index":26,
                   "question":"ماهي الإجراءات المتبعة للتقديم على طلب ترخيص اجار لدى الغير ؟ ",
                   "answer":"وفي حال كان المستثمر مستأجر مساحة من مستودع فإنه يتطلب التقديم بطلب الحصول على ترخيص مزاولة النشاط عبر النظام الالكتروني الموحد \"غد\" للهيئة على الرابط التالي : (https:\\/\\/ghad.sfda.gov.sa) وارفاق العقد المبرم مع المستودع المؤجر والالتزام بتطبيق بالشروط المذكورة في رحلة المستثمر .",
                   "isActive":false
                },
                {
                   "index":27,
                   "question":"ماهي متطلبات ترخيص مصنع اعلاف محلي ؟ ",
                   "answer":"1- الحصول على ترخيص موقع للمنشأة حسب موقع المنشأة:\n- ترخيص بلدي للمنشآت الواقعة تحت رقابة وزارة الشئون البلدية والقروية والإسكان\n- ترخيص من وزارة البيئة والمياه والزراعة للمنشآت الواقعة في الاراضي والمشاريع الزراعية\n- رخصة تشغيل للمنشآت الواقعة في المناطق الصناعية والاقتصادية.\n2- بعد حصول المنشأة على موافقات الجهات الحكومية المعنية يقوم المستثمر بإنشاء وتجهيز المصنع مع ضرورة تطبيق الاشتراطات الفنية الواردة في اللوائح الفنية والمواصفات القياسية، بالإضافة إلى اشتراطات الجهة المانحة لترخيص الموقع مثل: وزارة الشئون البلدية والقروية والإسكان أو المدن الصناعية أو الاقتصادية.  \n3- التقديم بطلب الحصول على ترخيص مزاولة النشاط عبر النظام الالكتروني الموحد \"غد\" للهيئة على الرابط التالي: (https:\\/\\/ghad.sfda.gov.sa).\n4- الحصول على الموافقة النهائية (ترخيص مزاولة النشاط) من الهيئة العامة للغذاء والدواء.  ",
                   "isActive":false
                },
                {
                   "index":28,
                   "question":"من الذي يقوم بتقديم الطلب لدى الهيئة؟ ",
                   "answer":"يقوم بعملية التقديم شخص مسئول ومخول (مفوض) من قِبل المنشأة ويكون لديه معلومات فنيه كافية لإكمال إجراءات التقديم.",
                   "isActive":false
                },
                {
                   "index":29,
                   "question":"متى يحق للمستودع مزاولة النشاط؟",
                   "answer":"       يحق للمستودع مزاولة النشاط حال حصوله على الترخيص من قبل الهيئة\nالعامة الغذاء والدواء ويمنع مزاولة النشاط قبل ذلك وتطبيق بحق المخالف\nالعقوبات المقررة وفق نظام الاعلاف الصادر بالمرسوم الملكي رقم (م٦٠\\/)\nوتاريخ ١٤٣٥\\/٩\\/٢٣هـ والتي تنص على (تتولى الهيئة توقيع العقوبات المنصوص\nعليها في الفقرة (١) من هذه المادة.",
                   "isActive":false
                },
                {
                   "index":30,
                   "question":"ما مدة صلاحية رخصة مزاولة النشاط الصادر من هيئة الغذاء والدواء ؟",
                   "answer":"سنه، سنتين , 3 سنوات ",
                   "isActive":false
                },
                {
                   "index":31,
                   "question":"متى يجب على المستثمر القيام بطلب تجديد ترخيص مزاولة النشاط؟",
                   "answer":"      على المنشأة بطلب تجديد للترخيص عبر النظام الكتروني وذلك قبل (90 يوم) من تاريخ انتهاء الترخيص الحالي.",
                   "isActive":false
                },
                {
                   "index":32,
                   "question":"إذا كان لدى المستثمر أكثر من مستودع أعلاف هل يتم ترخيصها كمستودع واحدة لدى الهيئة؟ ",
                   "answer":"يجب على المستثمر تقديم طلب على حده (لكل مستودع أعلاف ترخيص مستقل)",
                   "isActive":false
                },
                {
                   "index":33,
                   "question":" هل يتم قبول طلب بسجل تجاري واحد لعدة مستودعات أعلاف بمناطق متفرقة؟",
                   "answer":"لا يقبل الطلب بسجل تجاري مختلف عن المنطقة الإدارية الواقع بها مستودع الأعلاف.",
                   "isActive":false
                },
                {
                   "index":34,
                   "question":"ما هي الأنشطة المعتمدة لمنشآت الأعلاف ؟",
                   "answer":"الاطلاع على قائمة الأنشطة الاقتصادية الخاصة بالهيئة – ISIC4 :https:\\/\\/www.sfda.gov.sa\\/ar\\/informationlist\\/65649",
                   "isActive":false
                },
                {
                   "index":35,
                   "question":"ما هي عناوين الاتصال التي يمكنني الاتصال بها في حال احتياجي للمساعدة؟",
                   "answer":"يمكن للمتقدمين في حال احتياجهم للمساعدة الاتصال على الرقم الموحد 19999 ",
                   "isActive":false
                },
                {
                    "index":36,
                    "question":"كيف يمكنني الإعلان عن المنتجات بعد تسجيلها في النظام الالكتروني الموحد (غد)",
                    "answer":"يمكن الإعلان من خلال الدخول على نظام تراخيص الدعاية والإعلان \"إعلان\" عبر الرابط https://ads.sfda.gov.sa/Account/Login?ReturnUrl=%2F ومن ثم استخدام اسم المستخدم وكلمة المرور التي تملكها لنظام غد",
                    "isActive":false
                 }
             ],
        },
        {
            "type": "التبغ",
            "typeId": 8,
            "questionsAndAnswers": [
                {
                    "index": 1,
                    "question": "ماهو دور الهيئة في تنظيم منتجات التبغ ؟",
                    "answer": "تتولى الهيئة  مسؤولية فحص ووضع المواصفات واللوائح الفنية الخاصة بمنتجات التبغ",
                    "isActive": false
                },
                {
                    "index": 2,
                    "question": "ماهي اشتراطات الهيئة لاستيراد منتجات التبغ؟ وهل يشترط تسجيل منتجات التبغ لدى الهيئة؟",
                    "answer": "لا يتطلب تسجيل منتجات التبغ لدى الهيئة،  و يمكنكم معرفة اشتراطات فسح منتجات التبغ من خلال الرابط (https://www.sfda.gov.sa/sites/default/files/2021-08/SFDATobacoo.pdf) وكذلك يمكنكم الاطلاع على اللائحة التنفيذية لنظام مكافحة التدخين من خلال الرابط (https://www.moh.gov.sa/Ministry/Rules/Documents/22.pdf).",
                    "isActive": false
                },
                {
                    "index": 3,
                    "question": "هل من الممكن مطابقة ملصق المنتج أو تحليله قبل وصول الشحنة؟ وهل تشترط شهادة المطابقة معها؟",
                    "answer": "لا تقوم الهيئة بمطابقة ملصق منتجات التبغ قبل وصول الشحنة، ويشترط الالتزام بتطبيق اللوائح الفنية على منتجات التبغ.\nعلما بأن الهيئة  لم تعتمد شركات مطابقة لإصدار شهاداتها في مجال منتجات التبغ. كما يمكنكم الاطلاع على متطلبات وشروط فسح منتجات التبغ  على الرابط: \nhttps://www.sfda.gov.sa/sites/default/files/2021-08/SFDATobacoo.pdf",
                    "isActive": false
                },
                {
                    "index": 4,
                    "question": "ماهي اشتراطات فسح تبغ المعسل؟ ",
                    "answer": "يمكنكم الحصول على اشتراطات فسح منتجات التبغ من خلال الرابط (https://www.sfda.gov.sa/sites/default/files/2021-08/SFDATobacoo.pdf) ويمكنكم الحصول على على لائحة المعسل الفنية من خلال  الموقع الالكتروني لهيئة التقييس لدول مجلس التعاون لدول الخليج العربية  من خلال الربط (https://www.gso.org.sa/store/).",
                    "isActive": false
                },
                {
                    "index": 5,
                    "question": "كيف يتم الحصول على لوائح و مواصفات منتجات التبغ؟",
                    "answer": "يمكن الحصول على لائحة التغليف العادي لمنتجات التبغ ولائحة السوائل الالكترونية والتبغ المعد للتسخين في النظم الالكترونية للتدخين من متجر الهيئة الالكتروني للمواصفات (https://mwasfah.sfda.gov.sa/) و يمكن الحصول على بقية مواصفات ولوائح منتجات التبغ بالدخول على الموقع الالكتروني لهيئة التقييس لدول مجلس التعاون لدول الخليج العربية  (https://www.gso.org.sa/store/) .",
                    "isActive": false
                },
                {
                    "index": 6,
                    "question": "كيف يتم الحصول على التعاميم الخاصة بمنتجات التبغ؟",
                    "answer": "يمكن الاطلاع على التعاميم الصادرة الخاصة بمنتجات التبغ من خلال زيارة موقع الهيئة العامة للغذاء والدواء – تعاميم منتجات التبغ من خلال الرابط (https://www.sfda.gov.sa/ar/circulars?tags=5).",
                    "isActive": false
                },
                {
                    "index": 7,
                    "question": "ما هو التغليف العادي لمنتجات التبغ؟",
                    "answer": " التغليف العادي يحدد المواصفات الفنية لإخراج المنتجات من حيث حجم التحذير الصحي ولون العبوة والعبارات المسموحة ونحوها ليقلل من جاذبية منتجات التبغ ويساعد في زيادة فاعلية التحذيرات الصحية على العبوات، ويهدف إلى تعزيز الصحة العامة من خلال ثني الناس عن البدء في التدخين أو تعاطي منتجات التبغ، إضافة إلى التشجيع للإقلاع عن التدخين وتعاطي هذه المنتجات.",
                    "isActive": false
                },
                {
                    "index": 8,
                    "question": "ماهي الآلية المعتمدة حاليا لمداورة بيانات التحذير الصحي على منتجات التبغ؟",
                    "answer": "يجب استخدام بيانات التحذير الصحي المعتمدة للعام الميلادي الواحد بالتساوي من ناحية الكمية على كل شحنة من منتجات التبغ.\nويمكنكم الاطلاع على بيانات التحذير الصحي المشار إليها أعلاه من خلال الرابط   (https://www.sfda.gov.sa/sites/default/files/2020-12/circulationTobacco.pdf)",
                    "isActive": false
                },
                {
                    "index": 9,
                    "question": "ماهو الفرق بين العلامة التجارية واسم العلامة التجارية؟",
                    "answer": "يقصد بالعلامة التجارية: الشعار الخاص بالمنتج.\nويقصد باسم العلامة التجارية: الاسم الاولي الذي يعرف به هذا المنتج. \nويمكن وضع اسم العلامة التجارية (وليس الشعار) وفق اشتراطات لائحة التغليف العادي.",
                    "isActive": false
                }
            ],
        },
        {
            "type": "الغذاء",
            "typeId": 9,
            "questionsAndAnswers": [
                {
                    "index":1,
                    "question":"هل يلزم أن يكون التفويض على الورق الرسمي للمنشأة؟",
                    "answer":"نعم .",
                    "isActive":false
                 },
                 {
                    "index":2,
                    "question":"ماهي الصيغة المعتمدة لنموذج التفويض؟",
                    "answer":"يجب كتابة التفويض الكترونيا وفق الصيغة المطلوبة والذي يمكنكم الحصول عليه بالرجوع للرابطhttps://www.sfda.gov.sa/ar/food/about/administration/mangement_food/Documents/fooddoc002.docx",
                    "isActive":false
                 },
                 {
                    "index":3,
                    "question":"ماذا لو اختلفت البيانات في التفويض عن المسجل في النظام؟",
                    "answer":"يرفض الطلب، ويتم ذكر سبب الرفض ويرسل لك رسالة الكترونية.",
                    "isActive":false
                 },
                 {
                    "index":4,
                    "question":"هل أستطيع استخدام اسم المستخدم المرفوض عند فتح حساب جديد مرة اخرى؟",
                    "answer":"لا.",
                    "isActive":false
                 },
                 {
                    "index":5,
                    "question":"هل أستطيع أن أسجل عدة مرات في خدمات الهيئة؟",
                    "answer":"نعم حسب كل نظام .",
                    "isActive":false
                 },
                 {
                    "index":6,
                    "question":"ماهي الإجراءات المتبعة للتقديم على طلب ترخيص اجار لدى الغير؟",
                    "answer":"وفي حال كان المستثمر مستأجر مساحة من مستودع فإنه يتطلب التقديم بطلب الحصول على ترخيص مزاولة النشاط عبر النظام الالكتروني الموحد غد للهيئة على الرابط التالي : (https://ghad.sfda.gov.sa) وارفاق العقد المبرم مع المستودع المؤجر والالتزام بتطبيق بالشروط المذكورة في رحلة المستثمر الفصل السادس.",
                    "isActive":false
                 },
                 {
                    "index":7,
                    "question":"ما هو الهدف من تسجيل المنشآت الخارجية؟",
                    "answer":"هي خطوة لتسهيل انسياب البضائع من المنشآت الخارجية إلى المنافذ الحدودية.",
                    "isActive":false
                 },
                 {
                    "index":8,
                    "question":"هل جميع المنشآت الخارجية المصدرة للمملكة يلزمها التسجيل؟",
                    "answer":"لا .",
                    "isActive":false
                 },
                 {
                    "index":9,
                    "question":"ما هي الأنشطة المعتمدة للمنشآت الغذائية؟",
                    "answer":"الاطلاع على قائمة الأنشطة الاقتصادية الخاصة بالهيئة – ISIC4 :https://www.sfda.gov.sa/ar/informationlist/65649",
                    "isActive":false
                 },
                 {
                    "index":10,
                    "question":"ما هي الآلية لاستلام ترخيص مصنع أغذية؟",
                    "answer":"\\xa0\\xa0يتم التواصل من قبل الاتصالات الادارية لتسليم الترخيص (ورقي ) من خلال بيانات المسجلة للمفوض في النظام.",
                    "isActive":false
                 },
                 {
                    "index":11,
                    "question":"ماهي شروط رخصة تعبئة العسل؟",
                    "answer":"يمكن لمنشآت العسل الحصول على ترخيص مزاولة نشاط مصنع غذائي أو الحصول على ترخيص مستودع غذائي وتطبيق لائحة التعبئة والتغليف للمنتجات منخفظة الخطورة داخل المستودع.",
                    "isActive":false
                 },
                 {
                    "index":12,
                    "question":"ماهي آلية البحث عن المنشآت المعتمدة لدى الهيئة العامة للغذاء والدواء؟ وفي حال عدم وجود منشأه معتمدة ماهي الية اعتمادها؟",
                    "answer":"بالنسبة للمنشآت المعتمدة الخارجية والمصدرة للمملكة يمكن الحصول عليها من خلال موقع الهيئةعلى الرابط\\xa0https://www.sfda.gov.sa/ar/list_countries_products\\xa0\\xa0والية اعتماد المنشآت منشورة على الرابط التالي:\\xa0https://www.sfda.gov.sa/ar/regulations/69500",
                    "isActive":false
                 },
                 {
                    "index":13,
                    "question":"ماهي المنتجات التي تتطلب اعتماد منشأه قبل استيرادها؟",
                    "answer":"تقوم الهيئة باعتماد أصناف المنتجات بالتدرج حيث ان منتجات اللحوم والدواجن\\xa0\\xa0والاسماك\\xa0\\xa0\\xa0والعسل يجب ان تكون من منشآت معتمدة\\xa0\\xa0بالإضافة الى الأرز الهندي ومنتجات الحليب المصرية والخضار والفواكة المصنعة المصرية وفي حال استهداف منتجات جديدة سوف يتم التنسيق مع الجهات الرقابية المستوردين في حينه.\\xa0",
                    "isActive":false
                 },
                 {
                    "index":14,
                    "question":"في حال استيراد كميات قليلة من المنتجات الغذائية (تجارية) هل يلزم اصدار ترخيص مستودع؟",
                    "answer":"يتطلب من جميع المستودين الحصول على ترخيص مستودع غذائي ويستثنى من ذلك الذين لا تتجاوز ارسالياتهم في السنة الميلادية عن 5 ارساليات ومجموع اوزانها لا يزيد عن 2000 كجم مع توقيع التعهد الخاص بذلك .",
                    "isActive":false
                 },
                 {
                    "index":15,
                    "question":"ما هي المستندات المطلوب توافرها عند تسجيل المنتجات في النظام؟",
                    "answer":"صورة بطاقة المنتج.صورة المنتج.",
                    "isActive":false
                 },
                 {
                    "index":16,
                    "question":"ما هي الخطوات التي تتم بعد عملية تسجيل المنتجات بالنظام؟",
                    "answer":"يمكن بعد ذلك الاستيراد وفقاً لشروط ومتطلبات الفسح.",
                    "isActive":false
                 },
                 {
                    "index":17,
                    "question":"ماهي الملفات التي يحتاجها المستورد لتسجيل المنتج في الموقع الإلكتروني؟ وهل يجب تسليم عينات قبل الاستيراد؟",
                    "answer":"صورة بطاقة المنتج وصورة المنتج، ولا يحتاج تسليم عينات قبل الاستيراد.",
                    "isActive":false
                 },
                 {
                    "index":18,
                    "question":"في حال استيراد منتج معين من عدة دول. هل يلزمني تسجيله مرة واحدة أو عدة مرات؟",
                    "answer":"نعم، يلزم تسجيل المنتج في حال تم الاستيراد من دول مختلفة.",
                    "isActive":false
                 },
                 {
                    "index":19,
                    "question":"هل يلزم تعبئة جميع الحقول الخاصة بتسجيل المنتجات؟",
                    "answer":"لا، يوجد حقول اختيارية لا يلزم تعبئتها.",
                    "isActive":false
                 },
                 {
                    "index":20,
                    "question":"ماهي الطريقة المعتمدة لتسجيل المنتجات المصنعة محلياً في الهيئة؟",
                    "answer":"الدخول على رابط الخدمةإدخال اسم المستخدم وكلمة السرالضغط على (المنتجات) واختيار (تسجيل إخطار بتسويق المنتجات)تعبئة كافة الحقول الإلزاميةإرفاق صورة بطاقة المنتج وصورة المنتجالمصادقة على الإقرارالضغط على (إرسال).",
                    "isActive":false
                 },
                 {
                    "index":21,
                    "question":"ما هي صورة بطاقة المنتج؟",
                    "answer":"هي الشكل الهندسي المصمم للبيانات قبل الطباعة على عبوة المنتج الغذائي وتكون شاملة من جميع الجهات للبطاقة وتكون بصيغة (ArtWork).",
                    "isActive":false
                 },
                 {
                    "index":22,
                    "question":"كيف يجب تعبئة حقل صورة المنتج؟",
                    "answer":"يلزم تحميل الصورة وتكون واضحة وقابلة للقراءة من جميع الجهات.",
                    "isActive":false
                 },
                 {
                    "index":23,
                    "question":"ما هو أقصى حد لحجم ملف الصور؟",
                    "answer":".1\\xa0ميجا\\xa0بايت لكل صورة",
                    "isActive":false
                 },
                 {
                    "index":24,
                    "question":"كيف أحصل على أفضل صورة لبطاقة المنتج؟",
                    "answer":"من الشركة الصانعة.",
                    "isActive":false
                 },
                 {
                    "index":25,
                    "question":"في حال لم يظهر وصف HS8. ماذا يعني؟",
                    "answer":"يعني ذلك أن رقم بند التعريفة غير صحيح.",
                    "isActive":false
                 },
                 {
                    "index":26,
                    "question":"كيف يجب تعبئة رقم اعتماد الشركة الصانعة؟",
                    "answer":"كتابة نفسم الرقم المنشور للمنشآت المعتمدة في حالة كانت المنشأة معتمدة\\xa0\\xa0اما اذا كانت غير معتمدة او غير مستهدفة بالاعتماد فانه يمكن الحصول على رقم الاعتماد من المنشأة المصدرة.",
                    "isActive":false
                 },
                 {
                    "index":27,
                    "question":"كيف يجب تعبئة حقل الباركود؟",
                    "answer":"بكتابة أرقام فقط دون فواصل أو شرطات، وفي حال عدم وجود رقم باركود للمنتج يمكن كتابة رقم (0) في الحقل.",
                    "isActive":false
                 },
                 {
                    "index":28,
                    "question":"ما هو رقم الاعتماد أو الـ Approval No.؟",
                    "answer":"هو رقم تقوم\\xa0\\xa0الجهات الرقابية في الدول المصدرة باصدارة للمنشآت ، وتقوم الهيئة باضافته الى القائمة قبل اسم المنشأة في قائمة الدول على الرابط\\xa0https://www.sfda.gov.sa/ar/list_countries_products\\xa0",
                    "isActive":false
                 },
                 {
                    "index":29,
                    "question":"كيف يجب تعبئة حقل نوع التعبئة؟",
                    "answer":"يمكن اختيار نوع التعبئة من القوائم المنسدلة في النظام الإلكتروني.",
                    "isActive":false
                 },
                 {
                    "index":30,
                    "question":"ما هي حقول البلدان؟",
                    "answer":"\\xa0\\xa0\\xa0\\xa0\\xa0بلد المنشأ : البلد الذي نشأت منه المادة الخام.·\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0بلد الإنتاج : البلد الذي تم تصنيع المنتج فيه.·\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0بلد التعبئة : البلد التي تم تعبئة المنتج فيه بدون أي عمليات تصنيعية على المنتج.",
                    "isActive":false
                 },
                 {
                    "index":31,
                    "question":"ما هو الوزن المصفى؟",
                    "answer":"وزن المادة الغذائية الصلبة من الوزن الصافي (الصلبة +السائلة) وتكون أقل دائماً من الوزن الصافي.",
                    "isActive":false
                 },
                 {
                    "index":32,
                    "question":"ما هو الوزن الصافي؟",
                    "answer":"هو محتوى المادة الغذائية الصافية في العبوة ويسجل بالنظام المتري.",
                    "isActive":false
                 },
                 {
                    "index":33,
                    "question":"كيف يجب تعبئة حقل العلامة التجارية؟ وتعبئة حقل الماركة؟",
                    "answer":"في حال وجود علامتين تجاريتين أو ماركتين على المنتج اكتب واحدة مكان حقل الماركة والأخرى في حقل العلامة التجارية، وفي حال لديك ماركة واحدة أو علامة تجارية واحدة، فاكتب الماركة أو العلامة التجارية في كلا الحقلين.",
                    "isActive":false
                 },
                 {
                    "index":34,
                    "question":"هل يسمح كتابة اسم المنتج (الصنف) باللغة الانجليزية؟",
                    "answer":"لا، يجب كتابة اسم المنتج باللغة العربية فقط كما هو مكتوب على بطاقة بيان المنتج.",
                    "isActive":false
                 },
                 {
                    "index":35,
                    "question":"بعد الانتهاء من تسجيل الأصناف الخاصة بي، هل أستطيع أن أضيف أصنافاً جديدة؟",
                    "answer":"نعم، يمكن ذلك في نموذج جديد.",
                    "isActive":false
                 },
                 {
                    "index":36,
                    "question":"هل المنتجات التي تحتوي على ادعاءات طبية كالتخسيس والمساهمة في زيادة الوزن وغيرها تصنف كغذاء؟ وهل المنتجات التي تحتوي فيتامينات وبروتينات لها طريقة خاصة بالتسجيل؟",
                    "answer":"تعتبر المنتجات الغذائية التي تحتوي بطاقات بيانها على ادعاءات غير واردة في اللوائح الفنية المعتمدة مخالفة، أما بالنسبة للمنتجات التي تحتوي فيتامينات وبروتينات فهي تعتبر مكملات غذائية يلزم اتباع دليل تسجيل المكملات الغذائية ومشروبات الطاقة.",
                    "isActive":false
                 },
                 {
                    "index":37,
                    "question":"ماهي مدة اجراء تسجيل منتج غذائي تقليدي؟",
                    "answer":".5\\xa0ايام\\xa0عمل",
                    "isActive":false
                 },
                 {
                    "index":38,
                    "question":"هل يتم تسجيل المنتجات الغذائية عند كل استيراد؟",
                    "answer":"لا، يتم تسجيل المنتج مرة واحدة فقط، وفي حال الاستيراد يتم استخدام الفسح الإلكتروني وإدخال بيانات الشحنة الواردة.",
                    "isActive":false
                 },
                 {
                    "index":39,
                    "question":"هل يوجد تعديل لمنتجات مسجلة؟",
                    "answer":"لا يمكن ذلك.",
                    "isActive":false
                 },
                 {
                    "index":40,
                    "question":"هل يلزم التسجيل في الهيئة في حالة استيراد الفواكه الطازجة",
                    "answer":"نعم.",
                    "isActive":false
                 },
                 {
                    "index":41,
                    "question":"هل المعامل الخاصة بالأغذية والمنتجات المصنعة منزلياً يمكن تسجيلها في الهيئة؟",
                    "answer":"يمكن للمعامل ذلك في حال وجود سجل تجاري ونشاط ضمن الأنشطة التجارية المعتمدة، أما بالنسبة للمنتجات المصنعة منزلياً فهي غير إلزامية التسجيل.",
                    "isActive":false
                 },
                 {
                    "index":42,
                    "question":"ماهي مدة دراسة طلب اذن الاستيراد؟",
                    "answer":"أذونات إستيراد الدواجن والأسماك ومنتجاتها ( 30يوم عمل ).",
                    "isActive":false
                 },
                 {
                    "index":43,
                    "question":"ما هي الإجراءات المتبعة في حال تم رفض اذن الاستيراد؟",
                    "answer":"يتم التقديم المعاملة بكامل المستندات المطلوبة مرة أخرى\\xa0\\xa0بعد تصويب الملاحظات .",
                    "isActive":false
                 },
                 {
                    "index":44,
                    "question":"هل يجب احضار شهادة مطابقة لكل المنتجات؟",
                    "answer":"تكون شهادات المطابقة إلزامية للدول والمنتجات حسب القائمة المنشورة على موقع الهيئة على الرابط \\xa0https://www.sfda.gov.sa/sites/default/files/2024-02/Oper-f10-01.pd ن باقي المنتجات اختياري",
                    "isActive":false
                 },
                 {
                    "index":45,
                    "question":"ماهي الطريقة المتبعة لإصدار شهادة صلاحية تصدير للمنتجات الغذائية؟",
                    "answer":"يتم التقديم إلكترونيا عن طريق نظام إصدار شهادة صلاحية التصدير للمنتجات الغذائية عبر الرابط\\xa0https://tasdeer.sfda.gov.sa/Login.aspx",
                    "isActive":false
                 },
                 {
                    "index":46,
                    "question":"هل هناك شهادة صحية خاصة لتصدير المنتجات؟",
                    "answer":"يوجد شهادة صلاحية التصدير للمنتجات الغذائية حيث يتم الإفادة\\xa0\\xa0بأن المنتجات صالحة للاستهلاك الادمي ومطابقة للوائح الفنية والمواصفات القياسية.",
                    "isActive":false
                 },
                 {
                    "index":47,
                    "question":"ماهي فترة صلاحية فاتورة تصدير منتجات غذائية من تاريخ إصدارها؟",
                    "answer":"في حال لم يتم سداد الفاتورة خلال 30يوم من إصدارها لاتظهر بيانات للفاتورة ولابد من إرسال بريد إلكتروني بتحديث الفاتورة.",
                    "isActive":false
                 },
                 {
                    "index":48,
                    "question":"متى وأين أستطيع أن أتقدم بطلب فسح؟",
                    "answer":"يجب التقديم على إخطار طلب فسح عبر نظام\\xa0\\xa0فسح الغذاء FIRS على الرابط\\xa0\\xa0\\xa0https://frcs.sfda.gov.sa/Login.aspx?enc=YmnZnGScyVI= عن طريق منصة فسح عبر الرابط\\xa0\\xa0https://www.fasah.sa/trade/home/ar/index.html",
                    "isActive":false
                 },
                 {
                    "index":49,
                    "question":"ما الذي يلزمني لاستخدام خدمة الفسح الإلكتروني عبر المنافذ؟",
                    "answer":"تسجيل المنشأة والمنتجات عبر نظام فسح الغذاء FIRS على الرابط\\xa0\\xa0\\xa0https://frcs.sfda.gov.sa/Login.aspx?enc=YmnZnGScyVI=",
                    "isActive":false
                 },
                 {
                    "index":50,
                    "question":"ما هو الاجراء المتبع في حال تم إرسال إخطار للمخلص و تبين ان به أخطاء؟",
                    "answer":"يتم ارسال تذكرة طلب تعديل فسح عبر 19999.",
                    "isActive":false
                 },
                 {
                    "index":51,
                    "question":"ما هو الاجراء المتبع في حال تم استيراد بضاعة والكمية الواردة مخالفة للفواتير؟",
                    "answer":"يلزم تزويد الهيئة بالفاتورة الصحيحة والمطابقة للكميات الواردة في الشحنة.",
                    "isActive":false
                 },
                 {
                    "index":52,
                    "question":"كيف أحصل على المواصفات؟",
                    "answer":"يمكن الحصول على اللوائح الفنية و المواصفات القياسية الغذائية من خلال المتجر الالكتروني للمواصفات\\xa0\\xa0https://mwasfah.sfda.gov.sa\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0",
                    "isActive":false
                 },
                 {
                    "index":53,
                    "question":"هل للمنتجات المستوردة لوائح خاصة بها وشروط عامة ، كاللحوم والمكملات الغذائية .. إلخ؟",
                    "answer":"نعم توجد لوائح فنية تختص بالمنتجات الغذائية مثل لائحة الارز والحليب المنكه وغيرها من المنتجات الغذائية، كما توجد لوائح فنية عامة تطبق على جميع المنتجات الغذائية بشكل عام مثل لائحة بطاقات المواد الغذائية المعبأة و لائحة فترات صلاحية المنتجات الغذائية ووكذلك لائحة المواد المضافة المسموح باضافتها في المواد الغذائية وغيرها.",
                    "isActive":false
                 },
                 {
                    "index":54,
                    "question":"كيف أحصل على فترات الصلاحية لكل المنتجات الغذائية؟",
                    "answer":"يمكن الحصول عليها من خلال زيارة المتجر الالكتروني للمواصفات\\xa0\\xa0\\xa0https://mwasfah.sfda.gov.sa/\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0رقم المواصفة SFDA.FD 150-1\\xa0\\xa0\\xa0وكذلك SFDA.FD / GSO 150-2 .",
                    "isActive":false
                 },
                 {
                    "index":55,
                    "question":"\\xa0ماهي الحدود المسموح بها لبقايا المبيدات في المنتجات الزراعية والغذائية؟",
                    "answer":"يمكن الحصول عليها من خلال زيارة المتجر الالكتروني للمواصفات\\xa0\\xa0https://mwasfah.sfda.gov.sa/\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0رقم المواصفة SFDA.FD /SFDA.FD 382 .",
                    "isActive":false
                 },
                 {
                    "index":56,
                    "question":"كيف أحصل على متطلبات البطاقة الغذائية؟",
                    "answer":"\\xa0يمكن\\xa0\\xa0الحصول عليها من خلال زيارة المتجر الالكتروني للمواصفات\\xa0\\xa0\\xa0https://mwasfah.sfda.gov.sa/\\xa0\\xa0\\xa0\\xa0\\xa0\\xa0رقم اللائحة الفنية رقم SFDA.FD/GSO 9 بطاقات المواد الغذائية المعبأة\\xa0\\xa0- النسخة الآخيرة.",
                    "isActive":false
                 },
                 {
                    "index":57,
                    "question":"ما هي النسب المسموح بها من البرومات والفلورايد والرقم الهيدروجيني في الماء والتي يجب أن لاتزيد في المكونات؟(المسموح والممنوع)",
                    "answer":"اللائحة الخاصة بمياه الشرب المعبأة رقم GSO 1025 نصت على أنه يجب أن لايزيد البرومات في مياه الشرب على 10 ميكروغرام/لتر وأن يكون الفلوريد في مياه الشرب المعبأة بين 1.5-0.7 ملغرام /لتروالرقم الهيدروجيني بين 8.5-6.5",
                    "isActive":false
                 },
                 {
                    "index":58,
                    "question":"هل يوجد فرق بين الدهون المتحولة والمهدرجة؟",
                    "answer":"الفرق أن الزيوت المهدرجة هي أحد مصادر الدهون المتحولة، ولكنها ليست المصدر الوحيد. لذلك فإن كل الزيوت المهدرجة\\xa0\\xa0ستنتج دهون متحولة، ولكن يمكن أن تكون الدهون المتحولة من مصادر أخرى غير الزيوت المهدرجة.",
                    "isActive":false
                 },
                 {
                    "index":59,
                    "question":"ماهو تاريخ بدء تطبيق قرار منع الزيوت المهدرجة من الاسواق في السعودية؟",
                    "answer":"بدأ تطبيق منع الزيوت المهدرجة جزئياً في الصناعات الغذائية بتاريخ 01/01/2020",
                    "isActive":false
                 },
                 {
                    "index":60,
                    "question":"هل يوجد فرق بين المواصفات الأوربية والمواصفات المعتمدة من قبلكم بخصوص المنتجات الزراعية؟",
                    "answer":"يتم إعداد المواصفات السعودية بناءً على مراجع واسانيد علمية داخلية و خارجية، كما ويتم الرجوع إلى الجهات التشريعية الأخرى على سبيل المثال لا الحصر هيئة دستور الأغذية (Codex) و مواصفات الاتحاد الأوروبي، وذلك بما يتوافق مع التشريعات الوطنية للمنتجات الغذائية.",
                    "isActive":false
                 },
                 {
                    "index":61,
                    "question":"هل الخضروات المحليه خاضعه لمعايير هيئة الغداء والدواء وخاليه من المبيدات والنترات العاليه؟",
                    "answer":"اللائحة الفنية SFDA.FD 382:2019 الحدود القصوى لمتبقيات مبيدات الآفات في المنتجات الزراعية والغذائية تطبق على المنتجات المحلية والمستوردة على حد سواء.",
                    "isActive":false
                 },
                 {
                    "index":62,
                    "question":"هل عدم الإفصاح عن مسببات حساسية الطعام في المطاعم يعتبر مخالفة على المطعم؟",
                    "answer":"نعم يعتبر مخالف للائحة الفنية SFDA.FD 56:2018 الإفصاح عن مسببات الحساسية في قائمة وجبات المنشآت الغذائية التي تقدم الطعام للمستهلك خارج المنزل ووزارة الشؤون البلدية والقروية والإسكان تتحقق من مدى التقيد بها.",
                    "isActive":false
                 },
                 {
                    "index":63,
                    "question":"ما هي الألوان المسموحه بالغذاء؟",
                    "answer":"يمكن معرفة الأالون المسموح بها في المواد الغذائية من خلال اللائحة الفنية SFDA.FD 2500 المواد المضافة المسموح باستخدامها في المواد الغذائية\\xa0\\xa0ويمكن الحصول عليها عن طريق المتجر الألكتروني للمواصفات\\xa0\\xa0https://mwasfah.sfda.gov.sa.",
                    "isActive":false
                 },
                 {
                    "index":64,
                    "question":"ماهي الالية لاعتماد منشآت\ الأغذية الخارجية؟",
                    "answer":"\\xa0يتم من خلال حث المنشآت الراغبة في التصدير إلى المملكة بالتواصل مع الجهة الرقابية وذلك باتباع آلية اعتماد الجهـــة الرقابيـــة الرســـمية والمنشـــآت فـــي الدولـــة الراغبـــة بتصديـــر المنتجـــات الغذائية إلى المملكة العربية السعودية\\xa0\\xa0\\xa0والمنشور على موقع الهيئة على الرابط :https://www.sfda.gov.sa/sites/default/files/2021-11/85851.pdf",
                    "isActive":false
                 },
                 {
                    "index":65,
                    "question":"ماهو المقابل المالي؟",
                    "answer":"تقوم المنشآت الخارجية بدفع المقابل المالي مقابل خدمات التفتيش عليه بناء على قرار مجلس الإدارة رقم (5-26-1442) وتاريخ 22/3/1442ه بحسب الدول والمنشور في آلية اعتماد الجهـــة الرقابيـــة الرســـمية والمنشـــآت فـــي الدولـــة الراغبـــة بتصديـــر المنتجـــات الغذائية إلى المملكة العربية السعودية\\xa0\\xa0\\xa0على الرابط :https://www.sfda.gov.sa/sites/default/files/2021-11/85851.pdf",
                    "isActive":false
                 },
                 {
                    "index":66,
                    "question":"كيف يمكن لي التسجيل في المتجر الإلكتروني للمواصفات؟",
                    "answer":"يمكن معرفة آلية التسجيل من خلال دليل المستخدم لبرنامج المتجر الإلكتروني للمواصفات: الرابط",
                    "isActive":false
                 },
                 {
                    "index":67,
                    "question":" هل يمكن اقتراح تبني/اعداد لائحة/مواصفة غذائية؟",
                    "answer":"يمكن ارسال بريد الكتروني يتضمن المشروع المقترح والمبررات (دراسات و/أو تشريعات)",
                    "isActive":false
                 },
                 {
                    "index":68,
                    "question":"هل يمكن ابداء المرئيات على مشاريع اللوائح الفنية والمواصفات؟",
                    "answer":"يمكن الاطلاع وابداء المرئيات على مشاريع اللوائح الفنية والمواصفات من خلال زيارة منصة استطلاع. من خلال الروابط التالية:\r\nhttps://www.sfda.gov.sa/ar/eservices/78786\r\n-         https://istitlaa.ncc.gov.sa/ar/health/sfda/Pages/default.aspx\r\n",
                    "isActive":false
                 },
                 {
                    "index":69,
                    "question":" كيف أحصل على متطلبات البطاقة الغذائية؟",
                    "answer":"يمكنك الرجوع للوائح الفنية التالية لكل ما تريد معرفته عن البطاقة الغذائية:\r\n-          اللائحة الفنية رقم (SFDA.FD/GSO 9) بعنوان \"بطاقات المواد الغذائية المعبئة\"،\r\n-          واللائحة الفنية رقم (SFDA.FD.GSO2233) بعنوان \"اشتراطات البيانات التغذوية على البطاقة\"،\r\n-         واللائحة الفنية رقم (SFDA.FD.2333) بعنوان \"اشتراطات الأغذية ذات الادعاءات الصحية والتغذوية\"",
                    "isActive":false
                 },
                 {
                    "index":70,
                    "question":"ماهي اللوائح الفنية والمواصفات القياسية الخاصة بفترات الصلاحية للمنتجات الغذائية؟",
                    "answer":"يمكنك معرفة فترات صلاحية المنتجات الغذائية بالرجوع إلى اللائحة الفنية رقم (SFDA.FD 150-1) بعنوان \"فترات صلاحية المنتجات الغذائية – الجزء الأول: فترات الصلاحية الإلزامية\" والمواصفة القياسية رقم (SFDA.FD/GSO 150-2) بعنوان \"فترات صلاحية المنتجات الغذائية – الجزء الثاني: فترات الصلاحية الاختيارية\"",
                    "isActive":false
                 },
                 {
                    "index":71,
                    "question":"ماهي متطلبات بطاقة المنتجات الغذائية المعبأة؟",
                    "answer":"حددت اللائحة الفنية السعودية الخليجية SFDA.FD GSO 9 \"بطاقات المواد الغذائية المعبأة\" متطلبات البيانات التي يجب ذكرها على بطاقة المنتجات الغذائية المعبأة، كما أن هناك لوائح فنية أخرى حددت متطلبات تفصيلية لبعض البيانات التي يجب ذكرها على البطاقة مثل: اللائحة الفنية السعودية الخليجية SFDA.FD 150-1 والتي تتعلق بمتطلبات فترات صلاحية المنتجات الغذائية، واللائحة الفنية السعودية الخليجية SFDA.FD GSO 2233 \"البيانات التغذوية الايضاحية\"، واللائحة الفنية السعودية الخليجية SFDA.FD 2333 \"الادعاءات الصحية والتغذوية\".",
                    "isActive":false
                 },
                 {
                    "index":72,
                    "question":"ماهي المعلومات التي يجب الاطلاع عليها حول الادعاءات التغذوية والصحية للمنتجات الغذائية؟",
                    "answer":"وضحت اللائحة الفنية السعودية SFDA.FD 2333 جميع متطلبات الادعاءات الصحية والتغذوية التي يمكن وضعها على بطاقة المنتجات الغذائية، وتجدر الإشارة إلى ان الهيئة تقوم بدراسة الادعاءات الصحية غير المعتمدة في التشريعات الوطنية من خلال لجنة علمية تم تشكيلها لهذا الغرض ويمكن الاطلاع على الدليل الخاص بها بالضغط هنا.",
                    "isActive":false
                 },
                 {
                    "index":73,
                    "question":"ماهي متطلبات شراب ونكتار الفواكه؟",
                    "answer":"يمكنكم معرفة ذلك بالرجوع إلى اللائحة الفنية رقم (SFDA.FD/GSO 1820) بعنوان \"عصائر وشراب ونكتار الفواكه\" بالإضافة إلى اللائحة الفنية رقم (SFDA.FD/GSO 2577) بعنوان \"بطاقات عصائر ونكتار وشراب الفاكهة\"",
                    "isActive":false
                 },
                 {
                    "index":74,
                    "question":"هل يسمح كتابة بطاقة المنتج الغذائي باللغة الإنجليزية فقط؟",
                    "answer":"نصت اللائحة الفنية SFDA.FD/GSO 9 \" بطاقات المواد الغذائية المعبأة\" أنه يجب ان تكون بطاقات المواد الغذائية والبيانات الإيضاحية المصاحبة لها باللغة العربية، وعند استعمال لغة أخرى أو أكثر إلى جانب اللغة العربية، يجب أن تكون جميع البيانات باللغات الأخرى مطابقة للبيانات المكتوبة باللغة العربية.",
                    "isActive":false
                 },
                 {
                    "index":75,
                    "question":"ماهي متطلبات كتابة عبارة \"حلال\" على المنتج الغذائي؟",
                    "answer":"عند استخدام أي شعار خاص بالحلال أو علامات الجودة أو منتج عضوي فإنه يجب تقديم ما يثبت صحة هذا الادعاء وفقاً لما نصت عليه اللائحة الفنية SFDA.FD/GSO 9 \" بطاقات المواد الغذائية المعبأة\"، كما يمكنك الرجوع إلى اللائحة الفنية رقم (SFDA.FD/GSO 2055-1) بعنوان \"الأغذية الحلال – الجزء الأول: الاشتراطات العامة للأغذية الحلال\"",
                    "isActive":false
                 },
                 {
                    "index":76,
                    "question":"كيف احصل على متطلبات منتجات اللحوم؟",
                    "answer":"يمكنك ذلك بالجوع إلى اللوائح الفنية رقم (SFDA.FD GSO 996) بعنوان \"لحوم المواشي المبردة والمجمدة\" إضافة إلى اللائحة الفنية رقم (SFDA.FD/GSO 815) بعنوان \"دليل الممارسة الصحية لتجهيز ونقل وتداول وتخزين اللحوم الطازجة\"",
                    "isActive":false
                 }
            ],
        },
        {
            "type": "العمليات",
            "typeId": 10,
            "questionsAndAnswers": [
                {
                    "index": 1,
                    "question": "ماهي اشتراطات فسح الارساليات للأجهزة الطبية؟",
                    "answer": "اشتراطات الفسح في المنافذ الجمركية حسب الرابط : https://www.sfda.gov.sa/ar/medicaldevices/regulations/DocLib/(MDS-G21)ar.pdf",
                    "isActive": false
                },
                {
                    "index": 2,
                    "question": "  هل يحق لي الاعتراض على قرار الفسح؟",
                    "answer": "يوجد آلية الاعتراض على قرار رفض فسح ارسالية حسب الرابط : https://old.sfda.gov.sa/ar/oper/Pages/MissionClearance.aspx",
                    "isActive": false
                },
                {
                    "index": 3,
                    "question": "هل Declaration of conformity للفسح نفس المستند للتسجيل؟",
                    "answer": "يختلف المستند الفسح \"قرار مطابقة الشحنة للائحة الرقابة للأجهزة والمنتجات الطبية\" ومستند يذكر فيه معلومات تسجيل وترخيص المنتجات بالشحنة وإقرار المصنع بشحن المنتجات من قبل المستورد",
                    "isActive": false
                },
                {
                    "index": 4,
                    "question": "هل يتطلب DOC لكل شحنة أجهزة طبية؟",
                    "answer": "نعم يتطلب ارفاق قرار مطابقة ارسالية لكل شحنة أجهزة طبية ، يمكنكم الاطلاع على الدليل الإرشادي لمتطلبات الفسح عند المنافذ الجمركية: https://www.sfda.gov.sa/ar/medicaldevices/regulations/DocLib/(MDS-G21)ar.pdf",
                    "isActive": false
                },
                {
                    "index": 5,
                    "question": "ما هي المنافذ / الموانئ المخصصة لدخول الأجهزة الطبية؟",
                    "answer": "مطار الملك خالد الدولي- الرياض \nالميناء الجاف - الرياض\nمطار الملك - عبدالعزيز الدولي جده\nميناء جدة السلامي - جده \nميناء الملك عبدالله - رابغ\nمطار الملك فهد الدولي - الدمام\nميناء الملك عبدالعزيز -  الدمام\nمنفذ جسر الملك فهد - الخبر\nمنفذ البطحاء -  الأحساء\nمنفذ الحديثة - القريات",
                    "isActive": false
                },
                {
                    "index": 6,
                    "question": "هل بالإمكان استيراد أجهزة طبية تعمل على تيار كهربائي متردد 50 هيرتز؟",
                    "answer": "لا يجب عمل الأجهزة الطبية على تردد كهرباء 60 هيرتز.",
                    "isActive": false
                },
                {
                    "index": 7,
                    "question": "  ما هي الأصناف التي تحتاج لتزويدها بمؤشر / مقياس لدرجة الحرارة؟",
                    "answer": "أجهزة التشخيص المختبري\nالكواشف المخبرية الطبية\nالكواشف والمؤشرات غير الطبية\nجميع المنتجات التي تشترط الجهة الصانعة  بنقلها وتخزينها بدرجة حرارة معينة",
                    "isActive": false
                },
                {
                    "index": 8,
                    "question": "هل بالإمكان استيراد أجهزة طبية مستعملة؟",
                    "answer": "لا يسمح باستيراد أجهزة طبية مستعملة للمملكة",
                    "isActive": false
                },
                {
                    "index": 9,
                    "question": "ماهي شروط فسح الغذاء؟",
                    "answer": "بإمكانكم الاطلاع على الشروط المعلنة على الموقع الإلكتروني للهيئة على الرابط التالي:   https://www.sfda.gov.sa/sites/default/files/2021-04/SFADF12112020aa1_2.pdf",
                    "isActive": false
                },
                {
                    "index": 10,
                    "question": "ماهي شروط فسح المنتجات الغذائية العضوية؟",
                    "answer": "بإمكانكم الاطلاع على الشروط المعلنة على الموقع الإلكتروني للهيئة على الرابط التالي: https://www.sfda.gov.sa/sites/default/files/2020-07/sfda8978288273_0.pdf",
                    "isActive": false
                },
                {
                    "index": 11,
                    "question": "توجد ارساليات منتجات غذائية موقوفة أو مرفوضة بالمنفذ، ما هو الإجراء لإعادة النظر بشأنها؟",
                    "answer": "يمكن للمستورد تقديم طلب إعادة نظر بقرار الهيئة لدى فرع الهيئة العامة للغذاء والدواء بالمنطقة التي يقع فيها منفذ الدخول، ليتم دراسته واتخاذ القرار بشأنه.",
                    "isActive": false
                },
                {
                    "index": 12,
                    "question": "ماهي شروط فسح المنتجات العلفية؟",
                    "answer": "بإمكانكم الاطلاع على الشروط المعلنة على الموقع الإلكتروني للهيئة على الرابط التالي: https://www.sfda.gov.sa/sites/default/files/2021-05/2352021aa1.pdf",
                    "isActive": false
                },
                {
                    "index": 13,
                    "question": "ماهي شروط فسح منتجات التبغ؟",
                    "answer": "بإمكانكم الاطلاع على الشروط المعلنة على الموقع الإلكتروني للهيئة على الرابط التالي: https://sfda.gov.sa/sites/default/files/2021-08/SFDATobacoo.pdf",
                    "isActive": false
                },
                {
                    "index": 14,
                    "question": "كيف يمكن إضافة أو تعديل أو إزالة منشأة في قائمة المنشآت المعتمدة للتصدير إلى المملكة؟",
                    "answer": "يمكن تقديم طلب (إضافة، تعديل أو إزالة) إلى الهيئة عن طريق الجهة الرقابية عبر تعبئة النماذج الخاصة بذلك في موقع الهيئة والمذكورة بآﻟﻴﺔ اﻋﺘﻤﺎد اﻟﺠﻬﺔ اﻟﺮﻗﺎﺑﻴﺔ اﻟﺮﺳﻤﻴﺔ واﻟﻤﻨﺸﺂت\nhttps://www.sfda.gov.sa/ar/regulations/69500\nويتم ارسال الطلب الى الهيئة رسمياً ونسخة إلى بريد الإدارة التنفيذية للتعاون الدولي ICD@sfda.gov.sa",
                    "isActive": false
                },
                {
                    "index": 15,
                    "question": "كيف يتم اعتماد المنشآت الراغبة في التصدير إلى المملكة؟",
                    "answer": "يتم اعتماد المنشآت بعد تعبئة نموذج التقييم الذاتي من قبل المنشأة الغذائية وختمه من قبل الجهة الرقابية وإرساله عبر القنوات الدبلوماسية إلى الهيئة للنظر في إمكانية اعتماد المنشأة. شروط ومتطلبات استيراد الغذاء إلى المملكة العربية السعودية:\nhttps://www.sfda.gov.sa/ar/regulations/69500\nنموذج التقييم الذاتي:\nhttps://www.sfda.gov.sa/ar/forms/79055 ",
                    "isActive": false
                },
                {
                    "index": 16,
                    "question": "كيف يتم التفتيش على المنشآت الراغبة بالتصدير إلى المملكة بهدف اعتمادها؟",
                    "answer": "يتم التفتيش على المنشآت الراغبة بالتصدير (الزيارات الميدانية الحضورية و التي تقام عن بعد) بناء على عدة معايير عبر تقديم طلب عن طريق الجهة الرقابية عبر القنوات الدبلوماسية إلى الهيئة.",
                    "isActive": false
                },
                {
                    "index": 17,
                    "question": "هل يمكن اعتماد الشهادة الصحية الخاصة بالدولة المصدرة؟",
                    "answer": "قامت الهيئة باعتماد الشهادات الصحية الخاصة بالمنتجات الغذائية والتي تحتوي جميع المتطلبات الخاصة بالتصدير إلى المملكة ويمكن الحصول عليها عبر الرابط التالي:\n:https://www.sfda.gov.sa/en/regulations?tags=1  \nويمكن للدول الراغبة في الاعتماد الاطلاع على الشهادة والموافقة عليها وإبلاغ الهيئة بذلك ويمكن التواصل مع الهيئة في حال الرغبة في التعديل او مناقشة بنود الشهادة رسمياً. علماً بأنها منشورة في شروط ومتطلبات استيراد الغذاء الى المملكة\nhttps://sfda.gov.sa/ar/regulations/66195\n",
                    "isActive": false
                },
                {
                    "index": 18,
                    "question": "ما هو الهدف من تسجيل المنشآت الخارجية؟",
                    "answer": "للسماح بالتصدير الى المملكة العربية السعودية وذلك بهدف إحكام الرقابة على كامل السلسلة الغذائية من بلد المنشأ إلى السعودية بالتنسيق مع الجهات الرقابية في الدول المصدرة بناء على الآليات المعتمدة حيث يتم اعتماد المنشآت ونشرها على موقع الهيئة الإلكتروني في قائمة الدول والمنتجات والسماح لها بالتصدير الى السعودية",
                    "isActive": false
                },
                {
                    "index": 19,
                    "question": "هل يلزم على المنشآت الخارجية تسجيل جميع المنتجات التي تصنعها؟",
                    "answer": "نعم يجب ذلك",
                    "isActive": false
                },
                {
                    "index": 20,
                    "question": "هل جميع المنشآت الخارجية المصدرة للمملكة يلزمها التسجيل؟",
                    "answer": "نعم يجب ذلك",
                    "isActive": false
                },
                {
                    "index": 21,
                    "question": "ما هو رقم الاعتماد أو الـ Approval No.?",
                    "answer": "هو رقم اعتماد يعطى من قبل الجهة الرقابية الخارجية كرقم اعتماد مسجل لديها يتم نشره على موقع الهيئة الخارجي",
                    "isActive": false
                },
                {
                    "index": 22,
                    "question": "ما الهدف من نموذج تقييم الأنظمة والجهات الرقابية؟",
                    "answer": "التحقق من إجراءات الجهة الرقابية المسؤولة عن سلامة الغذاء في الدولة الراغبة بتصدير المنتجات الغذائية الى المملكة، تمهيداً لتفويضها باعتماد المنشآت الراغبة بتصدير منتجاتها الى المملكة، أو قيام الهيئة باعتماد المنشآت الراغبة بالتصدير الى المملكة\nhttps://www.sfda.gov.sa/ar/regulations/69500",
                    "isActive": false
                },
                {
                    "index": 23,
                    "question": "ماهي المدة المطلوبة لتصحيح مخالفات المنشآت الخارجية؟",
                    "answer": "مذكورة بالتفصيل في الآلية عبر الرابط:\nhttps://www.sfda.gov.sa/ar/regulations/69500",
                    "isActive": false
                },
                {
                    "index": 24,
                    "question": "كم المدة المتوقعة للرد على نموذج تقييم الأنظمة والجهات الرقابية؟",
                    "answer": "تقدر المدة الزمنية باتفاق الفريق الفني في الهيئة العامة للغذاء والدواء حسب الوقت المتوقع لكل حالة",
                    "isActive": false
                },
                {
                    "index": 25,
                    "question": "كيفية الحصول على نتائج زيارة فنية لمنشأة أو جهة رقابية خارجية؟",
                    "answer": "مذكورة بالتفصيل في الآلية عبر الرابط:\nhttps://www.sfda.gov.sa/ar/regulations/69500\n",
                    "isActive": false
                },
                {
                    "index": 26,
                    "question": "هل يمكن الاعتراض على تقرير نتائج زيارة لمنشأة أو دولة خارجية، ماهي الآلية لذلك؟ إن وجد",
                    "answer": "نعم، عن طريق ارسال بريد الكتروني الى الإدارة التنفيذية للتعاون الدولي \n(icd@sfda.gov.sa)",
                    "isActive": false
                },
                {
                    "index": 27,
                    "question": "ما هو المقابل المالي للتفتيش على المنشآت الخارجية وكيفية سداد الفواتير؟",
                    "answer": "تقوم المنشأة بدفع المقابل المالي حسب ما تم ذكره في آلية الاعتماد وفق الرابط:\nhttps://www.sfda.gov.sa/ar/regulations/69500\n",
                    "isActive": false
                },
                {
                    "index": 28,
                    "question": "ماهي المدة المتوقعة لإجراء الزيارة التفتيشية بعد سداد المقابل المالي؟",
                    "answer": "حسب خطة الفريق الفني وجدول الزيارات",
                    "isActive": false
                },
                {
                    "index": 29,
                    "question": "في حالة رفض إرسالية مستوردة هل يوجد إجراءات مطلوبة من الجهة الرقابية في الدولة المصدرة والشركة الصانعة أو المصدرة؟",
                    "answer": "لا يوجد ويجب الالتزام بشروط ومتطلبات الفسح المنشورة على الموقع الالكتروني للمنتجات الخاضعة لإشراف الهيئة من الشركة المصدرة أو من المستورد داخل المملكة على حد سواء .",
                    "isActive": false
                },
                {
                    "index": 30,
                    "question": "ماهي الجهات الرقابية المعتمدة لدى الهيئة؟",
                    "answer": "تفضل بزيارة الرابط للاطلاع: https://www.sfda.gov.sa/ar/list_countries_products ",
                    "isActive": false
                },
                {
                    "index": 31,
                    "question": "ما هو الفسح المركزي؟",
                    "answer": "هو عبارة عن مركز يتم من خلاله تطبيق إجراء موحد لفسح جميع المنتجات الخاضعة لرقابة الهيئة والواردة عبر المنافذ الحدودية والمطبقة لاشتراطات ومتطلبات يجب توافرها في مرحلة الفسح. وتوجد ثلاث مراكز للفسح المركزي لكلا من المنطقة الوسطى، الشرقية والغربية ",
                    "isActive": false
                },
                {
                    "index": 32,
                    "question": "ماهي مراكز الحلال وكيفية تقديم طلب اعتماد المراكز الخارجية؟",
                    "answer": "يمكنكم الاطلاع على قائمة الجهات المعينة المحدثة باستمرار تحت أيقونة عملاؤنا من خلال الموقع الالكتروني الخاص بمركز حلال:\nhttps://halal.sfda.gov.sa/\n\nالتقديم على تعيين الجهات المانحة عبر البريد الإلكتروني التالي:\nhalal@sfda.gov.sa\n\n",
                    "isActive": false
                },
                {
                    "index": 33,
                    "question": "ماهي المنتجات التي تتطلب إرفاق شهادة حلال؟",
                    "answer": "حاليا شهادة حلال إلزامية مع شحنات (اللحوم والدواجن ومنتجاتها) الواردة الى المملكة العربية السعودية وتكون صادرة من الجهات المانحة والمعينة من قبل المركز.",
                    "isActive": false
                },
                {
                    "index": 34,
                    "question": "ما هو الفرق بين شهادة الذبح الحلال وشهادة الحلال؟",
                    "answer": "شهادة حلال: وثيقة تصادق بأن المنتج أو الخدمة أو الأنظمة المحددة بها مطابقة لمتطلبات الحلال في الشريعة الإسلامية ومدتها 3 سنوات.\nشهادة الارسالية للذبح الحلال: ترفق مع كل إرسالية لحوم أو الدواجن او منتجاتها والتي تكون صادرة من الجهات المانحة لشهادات الحلال المعينة من قبل مركز حلال.",
                    "isActive": false
                },
                {
                    "index": 35,
                    "question": "ماهي شروط التصدير إلى المملكة من خلال بلد ثالث (ترانزيت)؟",
                    "answer": " تتطلب المنتجات الخاضعة لإشراف الهيئة حسب تصنيفها لمتطلبات الفسح وكذلك شروط النقل والتخزين، وللاطلاع عليها بشكل مفصل يجب زيارة موقع الهيئة الالكتروني.",
                    "isActive": false
                },
                {
                    "index": 36,
                    "question": "ماهي آلية تصنيف المنتجات؟",
                    "answer": "لتصنيف منتج بالهيئة العامة للغذاء والدواء يتم فتح حساب إلكتروني و الدخول عن طريق الموقع الالكتروني لتصنيف المنتجات  https://pcs.sfda.gov.sa/Default.En.aspx\nويمكن الاطلاع على طريقه فتح حساب في نظام تصنيف المنتجات من خلال الرابط التالي: \nhttps://www.youtube.com/watch?v=EI09W325Ybo\nويمكن تقديم طلب في نظام تصنيف المنتجات من خلال الرابط التالي: \nhttps://www.youtube.com/watch?v=hx0Hn3EzV8I\nويمكن تقديم طلب استئناف على قرار تصنيف منتج من خلال الرابط التالي:\nhttps://www.youtube.com/watch?v=X1QPhAaWp3k\n\nكما يمكنكم الاطلاع على الدليل الإرشادي لتصنيف المنتجات من خلال الرابط التالي:  \nhttps://sfda.gov.sa/sites/default/files/2022-04/%D8%AF%D9%84%D9%8A%D9%84-%D8%AA%D8%B5%D9%86%D9%8A%D9%81-%D8%A7%D9%84%D9%85%D9%86%D8%AA%D8%AC%D8%A7%D8%AA.pdf",
                    "isActive": false
                },
                {
                    "index": 37,
                    "question": "هل من الضروري إصدار شهادة اعتماد للمنشأة الخارجية؟",
                    "answer": "لا",
                    "isActive": false
                },
                {
                    "index": 38,
                    "question": "هل يتطلب إرفاق شهادات تحليل للمنتجات المصدرة إلى المملكة؟",
                    "answer": "تتطلب المنتجات الخاضعة لإشراف الهيئة حسب تصنيفها لمتطلبات الفسح وكذلك شروط النقل والتخزين، وللاطلاع عليها بشكل مفصل يجب زيارة موقع الهيئة الالكتروني.",
                    "isActive": false
                },
                {
                    "index": 39,
                    "question": "ماهي المنتجات التي يتطلب اعتماد المنشأة المصدرة لها؟",
                    "answer": "للاطلاع تفضل بزيارة الرابط التالي:\nhttps://www.sfda.gov.sa/ar/regulations/69500",
                    "isActive": false
                },
                {
                    "index": 40,
                    "question": "ماهي متطلبات التفتيش على مصانع الأجهزة الطبية؟",
                    "answer": "متوفرة على الرابط التالي:\n\nhttps://www.sfda.gov.sa/sites/default/files/2022-08/%D9%85%D8%AA%D8%B7%D9%84%D8%A8%D8%A7%D8%AA-%D8%A7%D9%84%D8%AA%D9%81%D8%AA%D9%8A%D8%B4_0.pdf ",
                    "isActive": false
                },
                {
                    "index": 41,
                    "question": "    ماهي آلية اعتماد الجهة الرقابية؟",
                    "answer": "يمكن الاطلاع على الآلية من خلال موقع قائمة الدول المعتمدة\nhttps://www.sfda.gov.sa/ar/list_countries_products ",
                    "isActive": false
                },
                {
                    "index": 42,
                    "question": "ماهي آلية تقديم طلب اعتماد جهة رقابية خارجية؟",
                    "answer": "التحقق من إجراءات الجهة الرقابية المسؤولة عن سلامة الغذاء في الدولة الراغبة بتصدير المنتجات الغذائية الى المملكة، تمهيداً لتفويضها باعتماد المنشآت الراغبة بتصدير منتجاتها الى المملكة، او قيام الهيئة باعتماد المنشآت الراغبة بالتصدير الى المملكة",
                    "isActive": false
                }
            ]
        }
    ]
}