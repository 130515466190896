
const SocialLinks = () => {

    const currentPageUrl = window.location.href;

    return (
        <div className="btn-group" role="group" style={{ float: "left" }}>
            <a className="no-styling-link secondary-social-link" data-action="share/whats  app/share" href={`whatsapp://send?text=${currentPageUrl}`} target="_blank" type="button" style={{ backgroundColor: "#24d364" }}>
                <i className="fab fa-whatsapp"></i>
            </a>
            <a className="no-styling-link secondary-social-link" href={`https://twitter.com/share?text=sfda&amp;url=${currentPageUrl}`} target="_blank" type="button" style={{ backgroundColor: "#44ace3" }}>
                <i className="fab fa-twitter"></i>
            </a>
            <a className="no-styling-link secondary-social-link" href={`https://www.facebook.com/sharer/sharer.php?u=${currentPageUrl}`} target="_blank" type="button" style={{ backgroundColor: "#2c54a4" }}>
                <i className="fab fa-facebook-f"></i>
            </a>
        </div>
    );
};

export default SocialLinks;
