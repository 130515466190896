import { useState } from "react";
import UseTranslations from "../../../Translation/UseTranslations";

const SubTable = ({ services, setServices, activites, currentServiceIndex, addDeleteEvent }) => {

    const Translation = UseTranslations();

    const handleTypeChange = (value, key, typeIndex) => {
        setServices(previousState => {
            if (key === "isFree" && value) {
                previousState[currentServiceIndex]["subServices"][typeIndex]["formula"] = "";
                previousState[currentServiceIndex]["subServices"][typeIndex][key] = value;
            }
            else if (key === "formula") {
                if (isValidFormula(value))
                    previousState[currentServiceIndex]["subServices"][typeIndex][key] = value;
                else
                    previousState[currentServiceIndex]["subServices"][typeIndex][key] = "x";
                if (value === "")
                    previousState[currentServiceIndex]["subServices"][typeIndex][key] = "x";
            }
            else
                previousState[currentServiceIndex]["subServices"][typeIndex][key] = value;
            return [...previousState];
        });
    }

    const handleAddServiceType = () => {
        setServices(previousState => {
            previousState[currentServiceIndex]["subServices"].push({
                id: generateUniqueId(), name: "نوع جديد", isFree: false, formula: "x", nameEn: "New Type", activityId: 1, parentId: services[currentServiceIndex]["id"]
            });
            return [...previousState];
        });
    }

    const handleDeleteServiceType = (typeIndex) => {
        setServices(previousState => {
            addDeleteEvent(services[currentServiceIndex]["subServices"][typeIndex]["id"], "SUB_SERVICE");

            previousState[currentServiceIndex]["subServices"] = previousState[currentServiceIndex]["subServices"].filter((_, currentIndex) => currentIndex !== typeIndex);

            return [...previousState];
        });
    }

    const generateUniqueId = () => {
        return (Math.random() * (99999 - 9999 + 1)) << 0;
    }

    const isValidFormula = (formula) => {
        return /^[0-9+\-*/.x]+$/.test(formula);
        // return /^([\dx+]+|[\dx]+[\+|\-|\*|\/]+[\dx]+)+$/gm.test(formula);
    }

    return (
        <>
            <table className="calculator-table calculator-sub-table">
                <tr>
                    <th>{(services[currentServiceIndex]["subServicesName"] || "") + " " + Translation.calculator.inArabic}</th>
                    <th>{(services[currentServiceIndex]["subServicesNameEn"] || "") + " " + Translation.calculator.inEnglish}</th>
                    {services[currentServiceIndex]["hasActivity"] && <th>{Translation.calculator.activity}</th>}
                    <th>{Translation.calculator.free}</th>
                    <th>{Translation.calculator.formula}</th>
                    <th>{Translation.calculator.descriptionAr}</th>
                    <th>{Translation.calculator.descriptionEn}</th>
                    <th>{Translation.calculator.unitAr}</th>
                    <th>{Translation.calculator.unitEn}</th>
                    <th>{Translation.calculator.variableAr}</th>
                    <th>{Translation.calculator.variableEn}</th>
                    <th>{Translation.calculator.deleteService}</th>
                </tr>

                {
                    services[currentServiceIndex]["subServices"].map((type, typeIndex) => {
                        return (
                            <tr>
                                <td><input onChange={(event) => handleTypeChange(event.target.value, "name", typeIndex)} value={type["name"]} /></td>
                                <td><input onChange={(event) => handleTypeChange(event.target.value, "nameEn", typeIndex)} value={type["nameEn"]} /></td>
                                {
                                    services[currentServiceIndex]["hasActivity"] && <td>
                                        <select onChange={(event) => handleTypeChange(parseInt(event.target.value), "activityId", typeIndex)}>
                                            {activites.map(activity => <option value={activity["id"]}>{activity["name"]}</option>)}
                                        </select>
                                    </td>
                                }
                                <td><input onChange={(event) => handleTypeChange(event.target.checked, "isFree", typeIndex)} defaultChecked={type["isFree"]} type="checkbox" /></td>
                                <td><input onChange={(event) => { handleTypeChange(event.target.value, "formula", typeIndex)}} disabled={type["isFree"]} value={type["formula"]} type="text" /></td>
                                <td><input onChange={(event) => { handleTypeChange(event.target.value, "descriptionAr", typeIndex)}} disabled={type["isFree"]} value={type["descriptionAr"]} type="text" /></td>
                                <td><input onChange={(event) => { handleTypeChange(event.target.value, "descriptionEn", typeIndex)}} disabled={type["isFree"]} value={type["descriptionEn"]} type="text" /></td>
                                <td><input onChange={(event) => { handleTypeChange(event.target.value, "unitAr", typeIndex)}} disabled={type["isFree"]} value={type["unitAr"]} type="text" /></td>
                                <td><input onChange={(event) => { handleTypeChange(event.target.value, "unitEn", typeIndex)}} disabled={type["isFree"]} value={type["unitEn"]} type="text" /></td>
                                <td><input onChange={(event) => { handleTypeChange(event.target.value, "variableAr", typeIndex)}} disabled={type["isFree"]} value={type["variableAr"]} type="text" /></td>
                                <td><input onChange={(event) => { handleTypeChange(event.target.value, "variableEn", typeIndex)}} disabled={type["isFree"]} value={type["variableEn"]} type="text" /></td>
                                <td><button className="primary-button pl-2 pr-2 mt-1 mb-1" onClick={(event) => handleDeleteServiceType(typeIndex)}><i className="fa fa-times"></i></button></td>
                            </tr>
                        )
                    })
                }
            </table>
            <button onClick={(event) => handleAddServiceType(currentServiceIndex)} className="secondary-button m-0 w-100 mb-2" >{Translation.calculator.addType}</button>
        </>
    );
}

export default SubTable;