
const STAGING_BASE_URL = "http://rb1-dev-03:9998";
const LOCAL_BASE_URL = "http://localhost:57866";
const PRODUCTION_BASE_URL = "https://customerjourney.sfda.gov.sa/API";

const BACKEND_BASE_URL = PRODUCTION_BASE_URL;

const BACKEND_LOGIN_URI = "/Account/Login";
const BACKEND_LOGOUT_URI = "/Account/Logout";
 
const BACKEND_LEVELS_DATA_URI = "/Home/Data";

const BACKEND_VIDEO_URI = "/Video/Content/";
const BACKEND_VIDEOS_URI = "/Video/All/Content/";
const BACKEND_VIDEO_ADD_URI = "/Video/Source/";
const BACKEND_VIDEO_UPLOAD_URI = "/Video/Upload/";
const BACKEND_VIDEO_STREAM_URI = "/Video/Stream/";
const BACKEND_VIDEO_DELETE_URI = "/Video/Delete/";

const BACKEND_JOURNEY_URI = "/Journey/";
const BACKEND_JOURNEY_UPDATE_URI = "/Journey/";

const BACKEND_CONTENT_URI = "/Content/";
const BACKEND_CONTENT_BY_JOURNEY_ID_URI = "/Content/Journey/";

const BACKEND_DISPLAY_CONTENT_STATUS = "/DisplayContentStatus"
const BACKEND_INSERT_DISPLAY_CONTENT_STATUS = "/DisplayContentStatus/InsertContentStatus"

const BACKEND_BUTTON_ADD_EVENT_URI = "/Admin/Event/Button/Add";
const BACKEND_BUTTON_MODIFY_EVENT_URI = "/Admin/Event/Button/Modify";
const BACKEND_BUTTON_DELETE_EVENT_URI = "/Admin/Event/Button/Delete/";

const BACKEND_STEP_BY_JOURNEY_ID_URI = "/Step/Journey/";
const BACKEND_STEP_ADD_URI = "/Step/Add";
const BACKEND_STEP_URI = "/Step/";
const BACKEND_STEP_REMOVE_URI = "/Step/Remove/";
const BACKEND_STEP_UPDATE_URI = "/Step/Update";

const BACKEND_CALCULATOR_SERVICES = "/ServiceCalculator/Services";
const BACKEND_CALCULATOR_SERVICES_DELETE_HANDLER_URI = "/ServiceCalculator/Services/Delete/Handler";
const BACKEND_CALCULATOR_ACTIVITES = "/ActivityCalculator/Activates";

export {
    BACKEND_STEP_URI,
    BACKEND_BASE_URL,
    BACKEND_LOGIN_URI,
    BACKEND_VIDEO_URI,
    BACKEND_LOGOUT_URI,
    BACKEND_VIDEOS_URI,
    BACKEND_CONTENT_URI,
    BACKEND_JOURNEY_URI,
    BACKEND_STEP_ADD_URI,
    BACKEND_VIDEO_ADD_URI,
    BACKEND_STEP_REMOVE_URI,
    BACKEND_STEP_UPDATE_URI,
    BACKEND_LEVELS_DATA_URI,
    BACKEND_VIDEO_DELETE_URI,
    BACKEND_VIDEO_STREAM_URI,
    BACKEND_VIDEO_UPLOAD_URI,
    BACKEND_JOURNEY_UPDATE_URI,
    BACKEND_CALCULATOR_SERVICES,
    BACKEND_BUTTON_ADD_EVENT_URI,
    BACKEND_CALCULATOR_ACTIVITES,
    BACKEND_STEP_BY_JOURNEY_ID_URI,
    BACKEND_BUTTON_MODIFY_EVENT_URI,
    BACKEND_BUTTON_DELETE_EVENT_URI,
    BACKEND_CONTENT_BY_JOURNEY_ID_URI,
    BACKEND_CALCULATOR_SERVICES_DELETE_HANDLER_URI,
    BACKEND_DISPLAY_CONTENT_STATUS,
    BACKEND_INSERT_DISPLAY_CONTENT_STATUS
};