import UseTranslations from "../../Translation/UseTranslations";
import BottomNavigationBar from "../Layout/BottomNavigationBar";
import NavigationBar from "../Layout/NavigationBar";
import InformationCard from "./InformationCard";
import toast, { Toaster } from 'react-hot-toast';

const ContactUs = () => {

    const Translation = UseTranslations();

    const copyToClipboardOnClick = (text) => {

        navigator.clipboard.writeText(text);

        toast.success(Translation.messages.contentWereCopied);
    }


    return (
        <div id="contactus-page">

            <Toaster
                position="top-center"
                reverseOrder={true}
                toastOptions={{
                    className: '',
                    duration: 5000,
                }}
            />

            <NavigationBar secondNavbarClassName="second-navbar-content-page" />
            <div className="home-page-secondary-background"></div>
            <div id="contactus-container-wrapper" style={{ direction: Translation.toCSSDirection }} >
                <div id="content-container" className="container">
                    <div className="container">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3046.93310116112!2d46.61008296529394!3d24.746286628333984!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee378551e51c1%3A0xaaa841c613d98cf!2sSaudi+Food+%26+Drug+Authority+(Head+Office)!5e0!3m2!1sen!2ssa!4v1560256869797!5m2!1sen!2ssa&amp;language=ar" width="100%" height="405" frameborder="0" style={{ border: "0" }} allowfullscreen=""></iframe>
                        <div className="row justify-content-end mb-5 mt-5">
                            <h5 id="main-title-header" style={{ marginLeft: '1rem', marginRight: '1rem' }}> {Translation.contactUs.contactUsTitle} </h5>
                            <div id="home-title-icon-starter"></div>
                        </div>
                        <InformationCard copyToClipboardOnClick={copyToClipboardOnClick} description={Translation.contactUs.emailDescription} faicon="fas fa-at" title={Translation.contactUs.email} />
                        <InformationCard copyToClipboardOnClick={copyToClipboardOnClick} description={Translation.contactUs.socialMediaAccountsDescription} faicon="fas fa-hashtag" title={Translation.contactUs.socialMediaAccounts} />
                        <InformationCard copyToClipboardOnClick={copyToClipboardOnClick} description={Translation.contactUs.unifiedNumberDescription} faicon="fas fa-phone" title={Translation.contactUs.unifiedNumber} />
                        <InformationCard copyToClipboardOnClick={copyToClipboardOnClick} description={Translation.contactUs.internationalNumberDescription} faicon="fas fa-phone" title={Translation.contactUs.internationalNumber} />
                        <div className="row justify-content-end mb-5 mt-5">
                            <h5 id="main-title-header" style={{ marginLeft: '1rem', marginRight: '1rem' }}>{Translation.contactUs.postAddressTitle}</h5>
                            <div id="home-title-icon-starter"></div>
                        </div>
                        <InformationCard copyToClipboardOnClick={copyToClipboardOnClick} description={Translation.contactUs.addressDescription} faicon="fas fa-at" title={Translation.contactUs.address} />
                        <InformationCard copyToClipboardOnClick={copyToClipboardOnClick} description={Translation.contactUs.unitNumberDescription} faicon="fas fa-hashtag" title={Translation.contactUs.unitNumber} />
                        <InformationCard copyToClipboardOnClick={copyToClipboardOnClick} description={Translation.contactUs.countryDescription} faicon="fas fa-phone" title={Translation.contactUs.country} />
                    </div>
                </div>
            </div>
            <BottomNavigationBar />
        </div>
    );
};

export default ContactUs;
