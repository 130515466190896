
import React, { useCallback, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import UseTranslations from "../../../Translation/UseTranslations";

const itemStyle = {
    padding: "0px",
    position: "relative",
    height: "26px",
    display: "flex",
    alignItems: "center",
};

const textStyle = {
    ...itemStyle,
    padding: "0px 4px",
    height: "26px",
    fontSize: "13px",
    color: "#0c263e",
    Text: "right"
};

const inputStyle = {
    width: "100%",
    fontSize: "13px",
    overflow: "hidden",
    color: "#0c263e",
};

const addRowButtonStyle = {
    width: "100%",
    borderRadius: "0 0 6px 6px",
    background: "white",
    color: "#0c263e",
    outline: "0",
    border: "none",
    padding: "0.5rem"
};

const DeleteRowButtonStyle = {
    color: "white",
    border: "none",
    background: "#072b46",
    curser: "pointer",
    width: "100%",
    height: "100%"
};

const SaveRowButtonStyle = {
    color: "#0c263e",
    border: "none",
    background: "white",
    curser: "pointer",
};

const tdStyle = {
    border: "1px solid #c6c6c6c6",
    overflow: "hidden",
    textAlign: "center",
};

const trHeaderStyle = {
    height: "3rem",
    background: "white",
    fontSize: "12px",
    color: "#0c263e",
};

const thHeaderStyle = {
    padding: "0 5px 0 5px",
};

const optionStyle = {
    ...tdStyle,
    padding: "2px 2px",
    width: "30px",
};

const orderOptionsStyle = {
    ...tdStyle,
    color: "#072b46",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
};

const orderOptionStyle = {
    cursor: "pointer"
}

const tableStyle = {
    borderSpacing: "1",
    borderCollapse: "collapse",
    background: "white",
    borderRadius: "6px",
    overflow: "hidden",
    width: "75%",
    margin: "10px auto",
};

const subHeaderStyle = {
    background: "#072b46",
    color: "white"
}


const Cell = React.memo(({ data, path, mode, onChange, valueType, levelData }) => {
    const Translation = UseTranslations();

    const [localMode, setLocalMode] = React.useState(mode ?? "read");
    const [localValue, setLocalValue] = React.useState(() => data[path]);

    React.useEffect(() => setLocalMode(mode ?? "read"), [mode]);
    React.useEffect(() => setLocalValue(() => data[path]), [data[path]]);

    if (localMode === "edit") {

        const handleInputChange = (event) => {

            const inputValue = event.target.value;

            if (valueType === 'dropdown') {
                setLocalMode("read");
                onChange?.({ buttonId: inputValue, oldButton: data[path] });
            }
            else 
                setLocalValue(inputValue);

        }

        const handleSaveClick = () => {

            setLocalValue(localValue === '' ? data[path] : localValue);
            setLocalMode("read");

            onChange?.(localValue);
        };

        if (valueType === 'dropdown' && levelData['previusLevelData'].length > 0)
            return (
                <select defaultValue={data[path]} onChange={handleInputChange} style={{ width: "100%", height: "100%" }}>
                    {
                        [
                            <option value={data[path].buttonId} selected>{data[path]['titleAr']}</option>,
                            levelData['previusLevelData'].map(branch =>
                                branch['buttons'].map(button => button.buttonId != data[path].buttonId && <option value={button.buttonId}>{button.titleAr}</option>)
                            )
                        ]
                    }
                </select>
            );
        else if (valueType === 'dropdown' && levelData['previusLevelData'].length === 0) {
            return (
                <div style={textStyle}></div>
            );
        }

        return (
            <div style={itemStyle}>
                <input
                    type="text"
                    value={localValue}
                    style={inputStyle}
                    onChange={handleInputChange}
                    onBlur={handleSaveClick}
                />
            </div>
        );
    }

    if (localMode === "read") {
        const handleEditClick = () => setLocalMode("edit");

        if (valueType === 'dropdown' && levelData['previusLevelData'].length === 0)
            return (
                <div style={textStyle}></div>
            );
        else if (valueType === 'dropdown')
            return (
                <div style={textStyle} onClick={handleEditClick}>
                    {path === 'parent' ? (Translation.languageLocal === 'ar' ? localValue?.['titleAr'] : localValue?.['titleEn']) : localValue}
                </div>
            );

        return (
            <div style={textStyle} onClick={handleEditClick}>
                {localValue}
            </div>
        );
    }
    return null;
});

const Row = React.memo(({ mode, swapUp, swapDown, columns, data, onChange, onDelete, levelData }) => {
        const handleDeleteClick = () => onDelete?.();

        const onSwapUp = (event) => {
            swapUp?.(data);
        }

        const onSwapDown = (event) => {
            swapDown?.(data);
        }

        return (
            <tr>
                {columns.map(({ path, type }) => {

                    const handleChange = (value) => {
                        onChange?.(path, { ...data, [path]: value })
                    };

                    return (
                        <td key={path} style={tdStyle}>
                            <Cell levelData={levelData} mode={mode} valueType={type} path={path} data={data} onChange={handleChange} />
                        </td>
                    );
                })}


                <td style={{ ...optionStyle }}>
                    <button style={DeleteRowButtonStyle} onClick={handleDeleteClick} className="admin-table-delete-button">
                        X
                    </button>
                </td>

                <td style={{ ...orderOptionsStyle }}>
                    <i title="إزاحة للأعلى" onClick={onSwapUp} style={{ ...orderOptionStyle }} className="fas fa-arrow-up mr-1" ar></i>
                    <i title="إزاحة للاسفل" onClick={onSwapDown} style={{ ...orderOptionStyle }} className="fas fa-arrow-down ml-1"></i>
                </td>
            </tr>
        );
    }
);

const Table = React.memo(
    ({ columns, swapUp, swapDown, handleAddNextLevel, setIsContentManagementMode, handleSaveChanges, handleCancelChanges, levelData, onAdd, onChange, onDelete, onSave }) => {

        const handleAddClick = (atLevel) => onAdd?.(atLevel);
        const Translation = UseTranslations();

        const numberOfColumns = Object.keys(columns).length + 2;
        let currentLevel = 1;

        const renderSubHeader = (atLevel) => (
            <tr style={subHeaderStyle}>
                <td style={{ position: "relative" }} colSpan={numberOfColumns}>
                    <div style={{ padding: "0 1rem 0 1rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <button type="button" style={{ position: "relative", left: 0, margin: "6px 0 6px 0", backgroundColor: "white", outline: "none", border: "none", color: "#072b46" }} title="اضافة سطر جديد في الجدول" class="btn btn-primary admin-table-add-button mr-2" onClick={() => handleAddClick(atLevel)}>{Translation.homepage.add}</button>
                        <span><strong>{currentLevel++}</strong> {Translation.homepage.level}</span>
                    </div>
                </td>
            </tr>
        );

        const renderLevelBranchesRows = (level) => {
            return (level['branches'].map((branch) => branch['buttons'].sort((previous, next) => previous["orderIndex"] - next["orderIndex"]).map((buttonData) => {

                const buttonId = buttonData['buttonId'];

                const handleChange = (columnHeader, changedData) => onChange?.(buttonId, changedData, columnHeader);
                const handleDelete = () => onDelete?.(buttonId, buttonData);

                return (<Row
                    key={buttonId}
                    swapDown={swapDown}
                    swapUp={swapUp}
                    moded="read"
                    columns={columns}
                    data={buttonData}
                    onChange={handleChange}
                    onDelete={handleDelete}
                    levelData={level}
                />)
            })));
        }

        return (
            <div>
                <table className="admin-table" style={tableStyle}>
                    <tbody>
                        <tr style={trHeaderStyle}>
                            {columns.map(({ path, name, enName }) => (
                                <th key={path} style={thHeaderStyle}>
                                    {Translation.languageLocal === 'ar' ? name : enName}
                                </th>
                            ))}
                        </tr>
                        {
                            levelData.length === 0 ?
                                <tr style={trHeaderStyle}>
                                    <td colSpan={numberOfColumns}>
                                        لا يوجد مستويات
                                    </td>
                                </tr>
                                :
                                levelData.map(level => {

                                return [renderSubHeader(level.atLevel),
                                    renderLevelBranchesRows(level)
                                ];
                            })
                        }

                    </tbody>
                </table>
                <div className="tool-bar" style={{ width: "100%", display: "inline-block", marginTop: "1rem", marginBottom: "1rem" }}>
                    <button type="button" style={{ backgroundColor: "#072b46", outline: "none", border: "none" }} title="الغاء جميع التغييرات قبل الحفظ" className="btn btn-primary admin-table-cancel-button mr-2" onClick={handleCancelChanges}>{Translation.homepage.cancel}</button>
                    <button type="button" style={{ backgroundColor: "#072b46", outline: "none", border: "none" }} title="تجربة التغييرات من منظور العميل" className="btn btn-primary admin-table-preview-button mr-2" onClick={() => { setIsContentManagementMode(false) }}>{Translation.homepage.preview}</button>
                    <button type="button" style={{ backgroundColor: "#072b46", outline: "none", border: "none" }} title="للاضافة مستوى جديد" className="btn btn-primary admin-table-add-level mr-2" onClick={handleAddNextLevel}>{Translation.homepage.addLevel}</button>
                    <button type="button" style={{ backgroundColor: "#072b46", outline: "none", border: "none" }} title="حفظ التغييرات يمكن العملاء لمشاهدة التغييرات مباشرة" className="btn btn-primary admin-table-save-button mr-2" onClick={handleSaveChanges}>{Translation.homepage.save}</button>
                </div>
            </div>
        );
    }
);

export default Table;
