import { useContext, useReducer } from 'react';
import { useState } from 'react';
import { UserContext } from '../../Context/UserContext';
import UseTranslations from '../../Translation/UseTranslations';

const Questions = ({ tabData }) => {

    const showQuestionBatchLimit = 8;

    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const { currentLanguage } = useContext(UserContext);
    const [currentShowenQuestionsCount, setCurrentShowenQuestionsCount] = useState(showQuestionBatchLimit);
    
    const Translation = UseTranslations();

    const handleClick = (index) => {
        forceUpdate();

        tabData.questionsAndAnswers.forEach(item => {
            if (item.index === index) {
                item.isActive = !item.isActive;
            }
        });
    };

    const cleanAnswer = (answer) => {
        return ({
            __html: answer.replaceAll(/[-]{1,}/gi, '\n')
                .replaceAll(/(https?:\/\/[^\s]+)/gi, "\t<a href='$1'>$1</a>")
        });
    }

    const handleLoadMore = () => setCurrentShowenQuestionsCount(currentShowenQuestionsCount + showQuestionBatchLimit);

    return (
        <div>
            <div id="food" data-tab-content className="active">
                <div>
                    {tabData['questionsAndAnswers'].slice(0, currentShowenQuestionsCount).map(item => (
                        <div>
                            <a className="collapsible active">
                                <div
                                    
                                    key={item.index}
                                >
                                    <div className="content-question-container" onClick={() => handleClick(item.index)} style={{ direction: currentLanguage === 'ar' ? 'rtl' : 'ltr'}}>
                                        <i className={(item.isActive ? "fas fa-minus" : "fas fa-plus") + " content-card-container-icon"}></i>
                                        <p className="question-content-paragraph">
                                            {currentLanguage === 'ar' ? item.question : item.questionEn}
                                        </p>
                                    </div>

                                    {/* show answers on click */}
                                    {item.isActive &&
                                        (<div>
                                            <p dangerouslySetInnerHTML={cleanAnswer(currentLanguage === 'ar' ? item.answer : item.answerEn)} style={{ textAlign: currentLanguage === 'ar' ? 'right' : 'left' }} className="answer-content-paragraph"></p>
                                        </div>)
                                    }
                                </div>
                            </a>
                        </div>
                    ))}
                    {
                        currentShowenQuestionsCount <= tabData['questionsAndAnswers'].length && <button onClick={handleLoadMore} className="primary-button">{Translation.FAQ.loadMore}</button>
                    }
                </div>
            </div>
        </div>
    );
};

export default Questions;
