
const getRequest = (endpoint, token = null) => {
        
    return fetch(endpoint, {
        method: 'GET',
        ...(token && { headers: { authorization: 'Bearer ' + token }})
    }).then(response => response.json().then((data) => buildSuccessResponse(response.status, data)))
        .catch(error => buildErrorResponse(error));
}

const putRequest = async (endpoint, body, token) => {
    return fetch(endpoint, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...(token && { authorization: 'Bearer ' + token })
        },
        body: JSON.stringify(body),
    }).then(response => response.json().then((data) => buildSuccessResponse(response.status, data)))
        .catch(error => buildErrorResponse(error));
}

const promisePutRequest = async (endpoint, body, token) => {
    return fetch(endpoint, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...(token && { authorization: 'Bearer ' + token })
        },
        body: JSON.stringify(body),
    });
}

const deleteRequest = async (endpoint, id, token) => {
    return fetch(endpoint + id, {
        method: 'GET',
        ...(token && { headers: { authorization: 'Bearer ' + token } })
    }).then(response => response.json().then((data) => buildSuccessResponse(response.status, data)))
        .catch(error => buildErrorResponse(error));
}

const promiseDeleteRequest = async (endpoint, id, token) => {
    return fetch(endpoint + id, {
        method: 'DELETE',
        ...(token && { headers: { authorization: 'Bearer ' + token } })
    });
}

const postRequest = async (endpoint, body, token) => {
    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...(token && { authorization: 'Bearer ' + token })
        },
        body: JSON.stringify(body),
    }).then(response => response.json().then((data) => { return buildSuccessResponse(response.status, data) }))
        .catch(error => buildErrorResponse(error));
}

const promisePostRequest = async (endpoint, body, token) => {
    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...(token && { authorization: 'Bearer ' + token })
        },
        body: JSON.stringify(body),
    });
}

const headRequest = async (endpoint, token) => {
    return fetch(endpoint, {
        method: 'HEAD',
        ...(token && { headers: { authorization: 'Bearer ' + token } })
    }).then(response => response)
        .catch(error => buildErrorResponse(error));
}

const postFormFileRequest = async (endpoint, formKey, file, token) => {
    const formData = new FormData();

    formData.append(formKey, file);

    return fetch(endpoint, {
        method: 'POST',
        body: formData,
        ...(token && { headers: { authorization: 'Bearer ' + token } })
    }).then(response => response.json().then((data) => buildSuccessResponse(response.status, data)))
        .catch(error => buildErrorResponse(error));
}

const buildSuccessResponse = (status, data) => ({ success: true, status: status, data: data });

const buildErrorResponse = (error) => ({ success: false, error: error });

export { getRequest, putRequest, promisePutRequest, deleteRequest, promiseDeleteRequest, postRequest, promisePostRequest, headRequest, postFormFileRequest };