import FormGroup from "./FormGroup";
import SubmitButton from "./SubmitButton";

const LoginForm = ({ onEmailChanged, onPasswordChanged, onFormSubmit }) => {
  return (
    <div class="row justify-content-center">
      <div id="login-form" class="card">
        <div class="card-body">
          <form>
            <FormGroup id="email-input" onChangeFunction={onEmailChanged} label="البريد الإلكتروني" type="email" placeholder="username@sfda.gov.sa" />
            <FormGroup id="password-input" onChangeFunction={onPasswordChanged} label="رقم السري" type="password" placeholder="**************" />
            <SubmitButton id="login-button" onFormSubmit={onFormSubmit} value="سجل الدخول" type="submit" />
          </form>
        </div>
      </div>
      <div id="right-login-form-line"></div>
    </div>
  );
};

export default LoginForm;
