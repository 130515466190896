import React, { useImperativeHandle, useLayoutEffect } from "react";
import { useContext } from "react";
import { BACKEND_BASE_URL, BACKEND_STEP_URI, BACKEND_VIDEOS_URI, BACKEND_VIDEO_ADD_URI, BACKEND_VIDEO_DELETE_URI, BACKEND_VIDEO_STREAM_URI, BACKEND_VIDEO_UPLOAD_URI, BACKEND_VIDEO_URI } from "../../../APIs/BackendUrls";
import { deleteRequest, getRequest, headRequest, postFormFileRequest, postRequest } from "../../../APIs/GenericFetch";
import { UserContext } from "../../../Context/UserContext";
import VideoPlayer from "./VideoPlayer";
import toast from 'react-hot-toast';
import UseTranslations from "../../../Translation/UseTranslations";

const DELETE_VIDEO_EVENT = "DELTE";
const UPDATE_VIDEO_SOURCE_EVENT = "SOURCE";
const UPLOAD_VIDEO_SOURCE_EVENT = "UPLOAD";

const Video = React.forwardRef(({ contentId }, ref) => {

    const [preChangesVideosData, setPreChangesVideosData] = React.useState([]);
    const [videosData, setVideosData] = React.useState([]);
    const [adminChangeEvents, setAdminChangeEvents] = React.useState(() => []);
    const [currentVideoIndexData, setCurrentVideoIndexData] = React.useState([]);

    const { isAuthenticated, getToken, currentLanguage } = useContext(UserContext);
    const Translation = UseTranslations();

    const deepCloneOjbect = (targetObject) => {
        return JSON.parse(JSON.stringify(targetObject));
    }

    // sends a HEAD request to the video url to get 
    const getVideoMetadata = async (videoUrl) => {
        return await headRequest(videoUrl).then(responseObject => {
            let metadata = {
                MimeType: null,
                Extension: null
            };
            if (responseObject.ok) {
                const mimeType = responseObject.headers.get('Content-Type');
                const extension = mimeType.split('/').pop();
                metadata = {
                    MimeType: mimeType,
                    Extension: extension
                };
            }
            return metadata;
        });
    }

    const generateUniqueId = () => {
        return (Math.random() * (99999 - 9999 + 1)) << 0;
    }

    // Uploads a video as form file
    const postVideoFile = (videoId, stepId) => {
        const targetVideo = getVideoById(videoId);

        if (targetVideo)
            postFormFileRequest(BACKEND_BASE_URL + BACKEND_VIDEO_UPLOAD_URI + videoId + BACKEND_STEP_URI + stepId, "video", targetVideo["file"], getToken());
    }

    // updates or insert source of video if provided outside of this system
    // TODO: validate if source is not from other content page in this system
    const postVideoSource = (videoId) => {
        getVideoMetadata(currentVideoUrlById(videoId)).then(metadata => {
            postRequest(BACKEND_BASE_URL + BACKEND_VIDEO_ADD_URI + videoId,
                {
                    contentId: contentId,
                    Url: currentVideoUrlById(videoId),
                    Extension: metadata.Extension,
                    MimeType: metadata.MimeType
                }, getToken()
            );
        });
    }

    // Deletes video's source or file if exists
    const deleteVideo = (videoId) => {
        getRequest(BACKEND_BASE_URL + BACKEND_VIDEO_DELETE_URI + videoId, getToken());
    }

    const handleAddVideo = () => {

        if (videosCountByContentId(contentId) > 3) {
            toast.error("لا يمكن اضافة اكثر من 4 مقاطع فيديو");
            return;
        }

        setVideosData(currentState => {
            let targetContentData = currentState.find(data => data.contentId === contentId);
            const tempVideoId = generateUniqueId();

            if (targetContentData) {
                targetContentData["videos"] = [...targetContentData["videos"], { "url": "", videoId: tempVideoId }];
            } else {
                currentState.push({ videos: [{ "url": "", videoId: tempVideoId }], contentId: contentId });
            }

            return [...currentState];
        });
    }

    const getCurrentVideoIndex = () => {
        const currentContent = currentVideoIndexData.find(indexData => indexData.contentId === contentId);

        return currentContent ? currentContent["index"] : 0;
    }

    const setCurrentVideoNewIndex = (index, inNextVideo = false) => {

        setCurrentVideoIndexData(currentState => {
            const currentVideoIndexContent = currentState.find(indexData => indexData.contentId === contentId);

            if (currentVideoIndexContent) {
                currentVideoIndexContent["index"] = index;
            } else {
                currentState.push({ index: inNextVideo ? 1 : 0, contentId: contentId });
            }

            return [...currentState];
        })
    }

    // remove video url and store event
    const handleDeleteVideo = () => {

        setVideosData(currentState => {
            const currentContent = currentState.find(videoData => videoData.contentId === contentId);

            if (currentContent) {
                const currentIndex = getCurrentVideoIndex();

                setOrUpdateAdminEvent(DELETE_VIDEO_EVENT, currentContent["videos"]?.[currentIndex]["videoId"], contentId);

                currentContent["videos"].splice(currentIndex, 1);
                setCurrentVideoNewIndex(0);
            }

            return [...currentState];
        });
    }

    const getVideoIdByIndex = (index) => {
        const currentContent = videosData.find(videoData => videoData.contentId === contentId);

        if (currentContent) {
            return currentContent["videos"]?.[index]?.["videoId"] || -1;
        }

        return -1;
    }

    // sets video source modifed by admin in the state
    const handleChangeVideoSource = (event) => {
        const currentIndex = getCurrentVideoIndex();

        const currentVideoId = getVideoIdByIndex(currentIndex);

        setOrUpdateAdminEvent(UPDATE_VIDEO_SOURCE_EVENT, currentVideoId, contentId);

        setOrUpdateDataState(setVideosData, getVideoIdByIndex(currentIndex), contentId, "url", event.target.value);
    }

    const setOrUpdateDataState = (state, videoId, contentId, videoType, value, isUpdate = true) => {
        state(currentState => {
            let targetContentData = currentState.find(data => data.contentId === contentId);

            if (targetContentData) {
                let videoIndex = 0;

                if (isUpdate)
                    videoIndex = getCurrentVideoIndex();
                else
                    videoIndex = currentState.find(videoData => videoData["contentId"] === contentId)?.['videos'].length || 0;

                let targetVideo = targetContentData["videos"][videoIndex];

                if (targetVideo)
                    targetContentData["videos"][videoIndex] = { ...targetContentData["videos"][videoIndex], [videoType]: value, "videoId": videoId };
                else
                    targetContentData["videos"] = [...targetContentData["videos"], { [videoType]: value, "videoId": videoId }];
            }
            else
                currentState = [...currentState, { videos: [{ [videoType]: value, "videoId": videoId }], contentId: contentId }];

            currentState = [...currentState];

            return currentState;
        });
    }

    const setOrUpdateAdminEvent = (event, videoId, contentId) => {
        setAdminChangeEvents(currentState => {
            const currentContent = currentState.find(eventData => eventData.contentId === contentId);

            if (currentContent) {

                const targetVideo = currentContent["videos"].find(video => video["videoId"] === videoId);

                if (targetVideo)
                    targetVideo["event"] = event;
                else
                    currentContent["videos"] = [...currentContent["videos"], { "event": event, "videoId": videoId }];
            }
            else
                currentState = [...currentState, { videos: [{ "event": event, "videoId": videoId }], contentId: contentId }];

            return currentState;
        });
    }

    // validate admin uploaded video and store it in state
    const handleUploadLocalVideoFile = (event) => {
        const files = event.target.files;

        if (!files || files.length === 0) { toast.error(Translation.messages.pickOneFileAtLeast); return; }

        const videoFileObject = files[0];

        const mimeType = videoFileObject.type;

        if (!mimeType.match('video.*')) { toast.error(Translation.messages.fileMustBeVideo); return; }

        if ((videoFileObject.size / 1024 / 1024) > 30) { toast.error(Translation.journey.fileToBig); return; }

        // to reflect the video uploaded directly in the ui
        const newVideoUrl = URL.createObjectURL(videoFileObject);

        let videoId = getVideoIdByIndex(getCurrentVideoIndex());

        if (videoId === -1)
            videoId = generateUniqueId();

        setOrUpdateAdminEvent(UPLOAD_VIDEO_SOURCE_EVENT, videoId, contentId);

        setOrUpdateDataState(setVideosData, videoId, contentId, "file", videoFileObject);
        setOrUpdateDataState(setVideosData, videoId, contentId, "url", newVideoUrl);
    }

    useImperativeHandle(ref, () => ({
        executeVideoAdminChange() {
            adminChangeEvents.forEach(stepEvents => {
                stepEvents["videos"].forEach(videoEvent => {
                    switch (videoEvent.event) {
                        case UPLOAD_VIDEO_SOURCE_EVENT:
                            postVideoFile(videoEvent.videoId, stepEvents.contentId);
                            break;
                        case UPDATE_VIDEO_SOURCE_EVENT:
                            postVideoSource(videoEvent.videoId);
                            break;
                        case DELETE_VIDEO_EVENT:
                            deleteVideo(videoEvent.videoId);
                            break;
                    }
                })
            });
        },
        resetAdminChange() {
            setAdminChangeEvents([]);
            setVideosData(deepCloneOjbect(preChangesVideosData));
        },
        refreshVideo() {
            setAdminChangeEvents([]);
            setVideosData(currentState => {
                currentState.forEach(content => {
                    content["videos"].forEach((video, index, videos) => {
                        if (!video["url"]) {
                            videos.splice(index, 1);
                        }
                    });
                });

                return currentState;
            })
        },
        updateContentIds(updatedContentIds) {
            videosData.forEach(videoData => {
                const content = updatedContentIds.find(contentId => contentId.oldId === videoData.contentId)

                if (content)
                    videoData.contentId = content.newId;
            });
            adminChangeEvents.forEach(event => {
                const content = updatedContentIds.find(contentId => contentId.oldId === event.contentId)

                if (content)
                    event.contentId = content.newId;
            });
        }
    }));

    const fetchAndSetVideoData = async () => {

        if (!videoExitstByContentId(contentId))
            return await getRequest(BACKEND_BASE_URL + BACKEND_VIDEOS_URI + contentId).then(responseObject => {
                if (responseObject.success) {
                    if (responseObject.status === 200) {

                        responseObject['data'].forEach(videoData => {
                            if (videoData["isUploaded"]) {
                                setOrUpdateDataState(setVideosData, videoData["videoId"], contentId, "url", BACKEND_BASE_URL + BACKEND_VIDEO_STREAM_URI + videoData["videoId"] + "?forceupdate=" + generateUniqueId(), false);
                                setOrUpdateDataState(setPreChangesVideosData, videoData["videoId"], contentId, "url", BACKEND_BASE_URL + BACKEND_VIDEO_STREAM_URI + videoData["videoId"] + "?forceupdate=" + generateUniqueId(), false);
                            }
                            else {
                                setOrUpdateDataState(setVideosData, videoData["videoId"], contentId, "url", videoData["url"], false);
                                setOrUpdateDataState(setPreChangesVideosData, videoData["videoId"], contentId, "url", videoData["url"], false);
                            }

                            setCurrentVideoNewIndex(0);
                        });

                    }
                }
            });
    }

    const videoExitstByContentId = (contentId) => {
        const currentContent = videosData.findIndex(videoData => videoData.contentId === contentId);

        if (currentContent !== -1) {
            const videoExists = videosData[currentContent]["videos"][getCurrentVideoIndex()];

            if (videoExists)
                return true;
        }

        return false;
    };

    const getVideoById = (videoId) => {
        const targetContentVideo = videosData.find(content => content["videos"].some(video => video["videoId"] === videoId));

        if (targetContentVideo) {
            return targetContentVideo["videos"].find(video => video["videoId"] === videoId);
        }

        return null;
    }

    const currentVideoUrlById = (videoId) => {

        const currentContentData = videosData.find(videoData => videoData["videos"].some(video => video["videoId"] === videoId));

        if (currentContentData) {
            const targetVideo = currentContentData["videos"].find(videoData => {
                return videoData["videoId"] === videoId;
            });

            if (targetVideo && targetVideo["url"])
                return targetVideo["url"];
        }

        return "";
    }

    const currentVideoUrlByContentId = (contentId) => {

        const currentContentData = videosData.find(videoData => videoData.contentId === contentId);

        if (currentContentData) {
            const targetVideo = currentContentData["videos"].find((videoData, index) => {
                return index === getCurrentVideoIndex();
            });

            if (targetVideo && targetVideo["url"])
                return targetVideo["url"];
        }

        return "";
    }

    const playVideoAtIndex = (index) => setCurrentVideoNewIndex(index);

    const playNextVideo = () => {
        let currentIndex = getCurrentVideoIndex();
        if (videosCountByContentId(contentId) - 1 > currentIndex) {
            setCurrentVideoNewIndex(++currentIndex, true);
        }
        else
            setCurrentVideoNewIndex(0);
    }

    const playPreviousVideo = () => {
        if (getCurrentVideoIndex() > 0) {
            let currentIndex = getCurrentVideoIndex();
            setCurrentVideoNewIndex(--currentIndex);
        }
        else
            setCurrentVideoNewIndex(videosCountByContentId(contentId) - 1);
    }

    const videosCountByContentId = (contentId) => videosData.find(videoData => videoData["contentId"] === contentId)?.['videos'].length || 0;

    // to fetch content data on page load and will be fetched once
    React.useEffect(() => {
        fetchAndSetVideoData();
    }, [contentId]);

    console.log(videosData);

    return (
        <div id="video-container" >
            {
                videoExitstByContentId(contentId) && <>
                    <div class="video-carousel-container">
                        <VideoPlayer videoUrl={currentVideoUrlByContentId(contentId)} />
                    </div>
                    {
                        <nav class="video-carousel-utility">
                            <button onClick={(event) => { playPreviousVideo(); }} class="video-carousel-control-button video-carousel-next-button"> <img width="20"
                                src="/images/forward-step-solid.svg" style={currentLanguage === 'ar' ? { transformOrigin: "50% 50%", transform: "scale(-1,1)" } : {}} /> </button>
                            <ol>
                                {
                                    Array.from(Array(videosCountByContentId(contentId)), (element, index) =>
                                        <li onClick={(event) => { playVideoAtIndex(index); }} className={`video-dot-indicator ${getCurrentVideoIndex() === index ? 'active' : ''}`}></li>
                                    )
                                }
                            </ol>
                            <button onClick={(event) => { playNextVideo(); }} class="video-carousel-control-button video-carousel-previous-button"> <img width="20" style={currentLanguage === 'ar' ? {} : { transformOrigin: "50% 50%", transform: "scale(-1,1)" }} src="/images/forward-step-solid.svg" /> </button>
                        </nav>
                    }
                    {
                        isAuthenticated() &&
                        <div className="row justify-content-center align-items-center video-upload-tool-bar">
                            <div className="col-1">
                                <button onClick={handleDeleteVideo} style={{ padding: "0 8px", borderRadius: "4px", margin: "0" }} class="submit-button">X</button>
                            </div>
                            <div className="col-5">
                                <label className="upload-video-label" style={{ width: "100%", height: "2.5rem", }}>
                                    <input accept="video/*" type="file" onChange={handleUploadLocalVideoFile} className="btn upload-video-input" />
                                    {Translation.journey.replaceVideo}
                                </label>
                            </div>
                            <div className="col-1">
                                {Translation.journey.or}
                            </div>
                            <div className="col-5">
                                <input onChange={handleChangeVideoSource} value={currentVideoUrlByContentId(contentId)} placeholder={Translation.journey.replaceVideoUrl} style={{ width: "100%", height: "2.5rem", textAlign: "center" }} />
                            </div>
                        </div>
                    }
                </>
            }
            {
                isAuthenticated() && <div className="row justify-content-center align-items-center video-upload-tool-bar mt-3">
                    <div className="col-12">
                        <button onClick={handleAddVideo} style={{ padding: "0 4px", borderRadius: "4px", margin: "0", width: "100%" }} class="submit-button">{Translation.journey.addVideo}</button>
                    </div>
                </div>
            }
        </div>
    );

});

export default Video;
