import UseTranslations from "../../Translation/UseTranslations";


const Title = () => {
    const Translation = UseTranslations();

    return (
        <div className="row justify-content-end align-items-center mb-3">
            <h3 id="main-title-header" style={{ marginLeft: '1rem', marginRight: '1rem' }}><strong>:</strong> {Translation.journey.description}  </h3>
            <div id="home-title-icon-starter"></div>
        </div>
    );
};

export default Title;
