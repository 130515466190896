import UseTranslations from "../../../Translation/UseTranslations";
import LevelButton from "./LevelButton";

const Level = ({ currentLanguage, isAuthenticated, isContentManagementMode, levelNumber, branchsData, onOpenBranch, isANewButton }) => {

    const Translation = UseTranslations();

    return (
        <div class="row justify-content-center level-container">
            {
                branchsData.map(branchData =>

                    <div style={{ display: isContentManagementMode ? "" : (branchData.isHidden ? "none" : "") }}>
                        {branchData['buttons'].length > 0 ?
                            <div className="col">
                                {
                                    isContentManagementMode ?
                                        <div className="admin-level-header">
                                            <lebel>
                                                <span style={{ padding: "0 6px 0 6px" }}>
                                                    {Translation.homepage.level}: {levelNumber}
                                                </span>
                                                <span style={{ padding: "0 6px 0 6px" }}>
                                                    {Translation.homepage.branch}: {branchData['atBranch']}
                                                </span>
                                            </lebel>
                                        </div> : <></>
                                }
                                <div className="btn-group level flex-wrap " role="group" >
                                    {
                                        [...branchData['buttons']].sort((previous, current) => current["orderIndex"] - previous["orderIndex"]).map(buttonData =>
                                            <LevelButton isANewButton={isANewButton} isAuthenticated={isAuthenticated} isContentManagementMode={isContentManagementMode} currentLanguage={currentLanguage} currentLevel={levelNumber} currentBranch={branchData.atBranch} isActive={buttonData.buttonId == branchData.currentActiveButtonId ? true : false} onOpenBranch={onOpenBranch} buttonData={buttonData} />
                                        )
                                    }
                                </div>
                            </div> : <></>}
                    </div>
                )
            }
        </div>
    );
};

export default Level;