import React, { useEffect } from "react";
import SubmitChangesButton from "../Admin/SubmitChangesButton";
import BottomNavigationBar from "../Layout/BottomNavigationBar";
import NavigationBar from "../Layout/NavigationBar";
import InformationCard from "./InformationCard";
import SocialLinks from "./SocialLinks";
import Title from "./Title";
import Video from "./Video/Video";
import { useState } from 'react';
import Spinner from "../Generic/Spinner";
import { useParams } from "react-router-dom";
import { getRequest, postRequest, promisePostRequest, putRequest } from "../../APIs/GenericFetch";
import { BACKEND_BASE_URL, BACKEND_JOURNEY_UPDATE_URI, BACKEND_JOURNEY_URI } from "../../APIs/BackendUrls";
import UseTranslations from "../../Translation/UseTranslations";
import { UserContext } from "../../Context/UserContext";
import { useContext } from "react";
import toast, { Toaster } from 'react-hot-toast';
import JourneyStartedNavBar from "../Layout/JourneyStartedNavBar";
import TourStepsJourney from "../Admin/TourGuide/TourStepsJourney";
import TourButton from "../Admin/TourGuide/TourButton";
import InformationSteps from "./Steps/InformationSteps";
import { useRef } from "react";


const Journey = () => {

    const [journeyContents, setJourneyContents] = useState(() => []);
    const [isDataLoaded, setIsDataLoaded] = React.useState(() => false);
    const [preChangesJourneyContents, setPreChangesJourneyContents] = useState(() => []);
    const [currentActiveStepOrder, setCurrentActiveStepOrder] = useState(0);

    const videoComponentReference = useRef();
    const { isAuthenticated, getToken, currentLanguage } = useContext(UserContext);

    const { id } = useParams();
    const Translation = UseTranslations();

    const deepCloneOjbect = (targetObject) => {
        return JSON.parse(JSON.stringify(targetObject));
    }

    // to fetch content data on page load and will be fetched once
    React.useEffect(() => {
        getRequest(BACKEND_BASE_URL + BACKEND_JOURNEY_URI + id).then(responseObject => {
            if (responseObject.success) {
                if (responseObject.status === 200 && responseObject['data']) {

                    const contentStepsData = sortContentByStepOrder(responseObject['data']['contentStepsList']['contentSteps']);

                    setJourneyContents(contentStepsData);
                    setPreChangesJourneyContents(deepCloneOjbect(contentStepsData));
                    setCurrentActiveStepOrder(0);

                } else {
                    toast.error(Translation.messages.somethingWentWrong);
                }
            }
            setIsDataLoaded(true);
        });
    }, []);

    const sortContentByStepOrder = (contentData) => {
        return contentData.sort((currentStep, previousStep) => currentStep['step']['stepOrder'] - previousStep['step']['stepOrder']);
    }

    const getNextStepOrder = () => journeyContents.sort((previous, current) => current['step']['stepOrder'] - previous['step']['stepOrder'])[0]['step']['stepOrder'] + 1;

    const handleAddStepContent = () => {

        if (journeyContents.length === 10) {
            toast.error(Translation.messages.cantAddMoreSteps);
            return;
        }

        const newStepId = generateUniqueId();
        const newContentId = generateUniqueId();
        const nextStepOrder = getNextStepOrder();

        setJourneyContents(currentState => {

            currentState = [...currentState,
            {
                "step": {
                    "stepId": newStepId,
                    "stepTitleAr": "خطوة جديدة",
                    "stepTitleEn": "New Step",
                    "stepOrder": nextStepOrder,
                    "journeyId": id,
                },
                "content": {
                    "contentId": newContentId,
                    "afterExtractingRecord": "",
                    "afterExtractingRecordEn": "",
                    "beforeAgreeingToPurchaseShipment": "",
                    "beforeAgreeingToPurchaseShipmentEn": "",
                    "beforeExtractingRecord": "",
                    "beforeExtractingRecordEn": "",
                    "correctivePlan": "",
                    "correctivePlanEn": "",
                    "cost": "",
                    "costEn": "",
                    "description": "",
                    "descriptionEn": "",
                    "detailedEvidence": "",
                    "detailedEvidenceEn": "",
                    "duration": "",
                    "durationEn": "",
                    "facilityLicensingDocuments": "",
                    "facilityLicensingDocumentsEn": "",
                    "introduction": "",
                    "introductionEn": "",
                    "introductoryVideo": "",
                    "introductoryVideoEn": "",
                    "previewInMerchantWarehouse": "",
                    "previewInMerchantWarehouseEn": "",
                    "productRegistrationRequirements": "",
                    "productRegistrationRequirementsEn": "",
                    "reliability": "",
                    "reliabilityEn": "",
                    "requestForReconsideration": "",
                    "requestForReconsiderationEn": "",
                    "requiredDocuments": "",
                    "requiredDocumentsEn": "",
                    "requirements": "",
                    "requirementsEn": "",
                    "selfEvaluationOfFacility": "",
                    "selfEvaluationOfFacilityEn": "",
                    "serviceUrl": "",
                    "shipmentFollowUp": "",
                    "shipmentFollowUpEn": "",
                    "whenPrintingCustomsDeclaration": "",
                    "whenPrintingCustomsDeclarationEn": "",
                    "whenPurchasingShipment": "",
                    "whenPurchasingShipmentEn": "",
                    "journeyId": id,
                    "stepId":newStepId
                },
                // should add the status of each content
                "displayContentStatus": {
                    "displayContentStatusId": newContentId,
                    "description": false,
                    "serviceUrl": false,
                    "duration": false,
                    "cost": false,
                    "reliability": false,
                    "requirements": false,
                    "requiredDocuments": false,
                    "descriptionEn": false,
                    "costEn": false,
                    "reliabilityEn": false,
                    "requirementsEn": false,
                    "requiredDocumentsEn": false,
                    "durationEn": false,
                    "introduction": false,
                    "introductionEn": false,
                    "introductoryVideo": false,
                    "introductoryVideoEn": false,
                    "beforeExtractingRecord": false,
                    "beforeExtractingRecordEn": false,
                    "afterExtractingRecord": false,
                    "afterExtractingRecordEn": false,
                    "productRegistrationRequirements": false,
                    "productRegistrationRequirementsEn": false,
                    "facilityLicensingDocuments": false,
                    "facilityLicensingDocumentsEn": false,
                    "beforeAgreeingToPurchaseShipment": false,
                    "beforeAgreeingToPurchaseShipmentEn": false,
                    "whenPurchasingShipment": false,
                    "whenPurchasingShipmentEn": false,
                    "whenPrintingCustomsDeclaration": false,
                    "whenPrintingCustomsDeclarationEn": false,
                    "shipmentFollowUp": false,
                    "shipmentFollowUpEn": false,
                    "previewInMerchantWarehouse": false,
                    "previewInMerchantWarehouseEn": false,
                    "requestForReconsideration": false,
                    "requestForReconsiderationEn": false,
                    "correctivePlan": false,
                    "correctivePlanEn": false,
                    "selfEvaluationOfFacility": false,
                    "selfEvaluationOfFacilityEn": false,
                    "detailedEvidence": false,
                    "detailedEvidenceEn": false,
                    "stepId": newStepId
                }
            }
            ]
            currentState = currentState.sort((currentStep, previousStep) => currentStep['step']['stepOrder'] - previousStep['step']['stepOrder']);

            return currentState;
        });
    }

    const handleDeleteStepContent = (stepId) => {

        if (journeyContents.length === 1) {
            toast.error(Translation.messages.cantRemoveLastStep);
            return;
        }

        setJourneyContents(currentState => {
            currentState = currentState.filter(content => content['step']['stepId'] !== stepId)
            currentState.forEach((stepContent, index) => stepContent['step']['stepOrder'] = index);
            currentState = currentState.sort((currentStep, previousStep) => currentStep['step']['stepOrder'] - previousStep['step']['stepOrder']);
            return currentState;
        });

        setCurrentActiveStepOrder(0);
    }

    const updateAdminChanges = (journeyContent) => {
        return postRequest(BACKEND_BASE_URL + BACKEND_JOURNEY_UPDATE_URI + id, { ContentSteps: journeyContent }, getToken());
    }

    const generateUniqueId = () => {
        return (Math.random() * (99999 - 9999 + 1)) << 0;
    }

    const handleStepChange = (newValue, contentKey, stepId) => {
        const stepIndex = journeyContents.findIndex(step => step['step']['stepId'] === stepId);

        if (stepIndex === -1) {
            toast.error(Translation.messages.somethingWentWrong);
            return;
        }

        setJourneyContents(currentState =>
            currentState.map((stepContent, index) => {
                if (index === stepIndex)
                    stepContent['step'][contentKey] = newValue;
                return stepContent;
            })
        );
    }

    const isValidUrl = (urlInput) => {
        return urlInput.match(/\b(https?:\/\/\S*\b)/g);
    }

    const handleContentChange = (contentId, contentKey, newValue) => {

        // the rich editor has a bug where it replace every content value with a new lien until I find the cause the if statment below remains
        if (newValue == "<p><br></p>") {
            return;
        }

        const contentIndex = journeyContents.findIndex(stepContent => stepContent['content']['contentId'] === contentId);

        if (contentIndex === -1) {
            toast.error(Translation.messages.somethingWentWrong);
            return;
        }

        setJourneyContents(currentState =>
            currentState.map((stepContent, index) => {
                if (index === contentIndex)
                    stepContent['content'][contentKey] = newValue;
                return stepContent;
            })
        );
    };

    const handleDisplayStatusChange = ({ newStatus, contentKey, contentId }) => {
        // here we should update the journeyContents for that specific content id if its visiable or not
        const contentIndex = journeyContents.findIndex(item => item['displayContentStatus']['displayContentStatusId'] === contentId);

        if (contentIndex === -1) {
            toast.error(Translation.messages.somethingWentWrong);
            return;
        }

        setJourneyContents(currentState =>
            currentState.map((item, index) => {
                if (index === contentIndex)
                    item['displayContentStatus'][contentKey] = newStatus;
                return item;
            })
        );

    }

    const handleSave = (event) => {
        updateAdminChanges(journeyContents).then(responseObject => {
            const toastId = toast.loading(Translation.messages.changesAreBeingUploaded);

            if (!responseObject.success || responseObject.status != 200)
                toast.error(Translation.messages.somethingWentWrong);
            else {

                const newJourneyContent = responseObject['data']['contentSteps'];

                const newIds = journeyContents.map((content, index) => ({
                    newId: newJourneyContent[index]['content']['contentId'],
                    oldId: content['content']['contentId']
                }));

                if (newIds) videoComponentReference.current.updateContentIds(newIds);

                setJourneyContents(newJourneyContent);
                setPreChangesJourneyContents(newJourneyContent);

                toast.success(Translation.messages.changesUploadedSuccessfully);
            }

            toast.dismiss(toastId);

            videoComponentReference.current.executeVideoAdminChange();
            videoComponentReference.current.refreshVideo();
        })


    }


    const handleCancelChanges = () => {
        videoComponentReference.current.resetAdminChange();

        setJourneyContents(deepCloneOjbect(preChangesJourneyContents));
        setCurrentActiveStepOrder(0);

        toast.success(Translation.messages.changesWereCanceled);
    }

    // this is a helper function to enable admin to always see the information cards. but the client will see only the checked cards. 
    const visibilityStatus = () => {

        return true;
    }

    return (
        <div >

            <Toaster
                position="top-center"
                reverseOrder={true}
                toastOptions={{
                    className: '',
                    duration: 5000,
                    style: {
                        maxWidth: 750,
                    }
                }}
            />

            <NavigationBar secondNavbarClassName="second-navbar-content-page" />
            <div className="home-page-secondary-background"></div>
            <JourneyStartedNavBar languageLocal={Translation.languageLocal} title={Translation.journey.journeyStarted} />

            <InformationSteps
                currentLanguage={currentLanguage}
                journeyContents={journeyContents}
                journeyId={id}
                setCurrentActiveStepOrder={setCurrentActiveStepOrder}
                currentActiveStepOrder={currentActiveStepOrder}
                handleAddStepContent={handleAddStepContent}
                handleDeleteStepContent={handleDeleteStepContent}
                isAuthenticated={isAuthenticated()}
                handleStepChange={handleStepChange}
            />

            {isAuthenticated() && <TourButton tourSteps={TourStepsJourney} />}

            <div id="content-container-wrapper" style={{ direction: (currentLanguage === 'ar' ? 'rtl' : 'ltr') }}  >
                <div id="content-container" className="container">
                    <div className="container">

                        {
                            isDataLoaded ?
                                journeyContents
                                &&
                                journeyContents
                                    .filter(stepContent => stepContent['step']['stepOrder'] === currentActiveStepOrder)
                                    .map((journeyContent, index) => {
                                        return <React.Fragment key={`${currentActiveStepOrder}_${index}`}>
                                            <Title />
                                            <Video
                                                ref={videoComponentReference}
                                                contentId={journeyContent.content?.contentId}
                                            />
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent?.displayContentStatus?.introduction : journeyContent?.displayContentStatus?.introductionEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.introduction}
                                                    cardKey={currentLanguage === 'ar' ? "introduction" : "introductionEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.introduction : journeyContent.content?.introductionEn}
                                                    faicon="fas fa-file-alt content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.introduction : journeyContent.displayContentStatus?.introductionEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent?.displayContentStatus?.description : journeyContent?.displayContentStatus?.descriptionEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.description}
                                                    cardKey={currentLanguage === 'ar' ? "description" : "descriptionEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.description : journeyContent.content?.descriptionEn}
                                                    faicon="fas fa-file-alt content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.description : journeyContent.displayContentStatus?.descriptionEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent?.displayContentStatus?.introductoryVideo : journeyContent?.displayContentStatus?.introductoryVideoEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.introductoryVideo}
                                                    cardKey={currentLanguage === 'ar' ? "introductoryVideo" : "introductoryVideoEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.introductoryVideo : journeyContent.content?.introductoryVideoEn}
                                                    faicon="fas fa-file-alt content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.introductoryVideo : journeyContent.displayContentStatus?.introductoryVideoEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent?.displayContentStatus?.beforeExtractingRecord : journeyContent?.displayContentStatus?.beforeExtractingRecordEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.beforeExtractingRecord}
                                                    cardKey={currentLanguage === 'ar' ? "beforeExtractingRecord" : "beforeExtractingRecordEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.beforeExtractingRecord : journeyContent.content?.beforeExtractingRecordEn}
                                                    faicon="fas fa-file-alt content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.beforeExtractingRecord : journeyContent.displayContentStatus?.beforeExtractingRecordEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent?.displayContentStatus?.afterExtractingRecord : journeyContent?.displayContentStatus?.afterExtractingRecordEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.afterExtractingRecord}
                                                    cardKey={currentLanguage === 'ar' ? "afterExtractingRecord" : "afterExtractingRecordEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.afterExtractingRecord : journeyContent.content?.afterExtractingRecordEn}
                                                    faicon="fas fa-file-alt content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.afterExtractingRecord : journeyContent.displayContentStatus?.afterExtractingRecordEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                (journeyContent.displayContentStatus?.serviceUrl || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.serviceUrl}
                                                    cardKey={"serviceUrl"}
                                                    description={journeyContent.content?.serviceUrl}
                                                    faicon="fas fa-link content-card-container-icon"
                                                    displayStatus={journeyContent.displayContentStatus?.serviceUrl}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.requirements : journeyContent.displayContentStatus?.requirementsEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.requirements}
                                                    cardKey={currentLanguage === 'ar' ? "requirements" : "requirementsEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.requirements : journeyContent.content?.requirementsEn}
                                                    faicon="fas fa-folder-open content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.requirements : journeyContent.displayContentStatus?.requirementsEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.productRegistrationRequirements : journeyContent.displayContentStatus?.productRegistrationRequirementsEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.productRegistrationRequirements}
                                                    cardKey={currentLanguage === 'ar' ? "productRegistrationRequirements" : "productRegistrationRequirementsEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.productRegistrationRequirements : journeyContent.content?.productRegistrationRequirementsEn}
                                                    faicon="fas fa-folder-open content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.productRegistrationRequirements : journeyContent.displayContentStatus?.productRegistrationRequirementsEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.facilityLicensingDocuments : journeyContent.displayContentStatus?.facilityLicensingDocumentsEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.facilityLicensingDocuments}
                                                    cardKey={currentLanguage === 'ar' ? "facilityLicensingDocuments" : "facilityLicensingDocumentsEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.facilityLicensingDocuments : journeyContent.content?.facilityLicensingDocumentsEn}
                                                    faicon="fas fa-folder-open content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.facilityLicensingDocuments : journeyContent.displayContentStatus?.facilityLicensingDocumentsEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.duration : journeyContent.displayContentStatus?.durationEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.duration}
                                                    cardKey={currentLanguage === 'ar' ? "duration" : "durationEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.duration : journeyContent.content?.durationEn}
                                                    faicon="fas fa-hourglass-half content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.duration : journeyContent.displayContentStatus?.durationEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.cost : journeyContent.displayContentStatus?.costEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.cost}
                                                    cardKey={currentLanguage === 'ar' ? "cost" : "costEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.cost : journeyContent.content?.costEn}
                                                    faicon="fas fa-money-bill content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.cost : journeyContent.displayContentStatus?.costEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.beforeAgreeingToPurchaseShipment : journeyContent.displayContentStatus?.beforeAgreeingToPurchaseShipmentEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.beforeAgreeingToPurchaseShipment}
                                                    cardKey={currentLanguage === 'ar' ? "beforeAgreeingToPurchaseShipment" : "beforeAgreeingToPurchaseShipmentEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.beforeAgreeingToPurchaseShipment : journeyContent.content?.beforeAgreeingToPurchaseShipmentEn}
                                                    faicon="fas fa-money-bill content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.beforeAgreeingToPurchaseShipment : journeyContent.displayContentStatus?.beforeAgreeingToPurchaseShipmentEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.whenPurchasingShipment : journeyContent.displayContentStatus?.whenPurchasingShipmentEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.whenPurchasingShipment}
                                                    cardKey={currentLanguage === 'ar' ? "whenPurchasingShipment" : "whenPurchasingShipmentEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.whenPurchasingShipment : journeyContent.content?.whenPurchasingShipmentEn}
                                                    faicon="fas fa-money-bill content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.whenPurchasingShipment : journeyContent.displayContentStatus?.whenPurchasingShipmentEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.whenPrintingCustomsDeclaration : journeyContent.displayContentStatus?.whenPrintingCustomsDeclarationEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.whenPrintingCustomsDeclaration}
                                                    cardKey={currentLanguage === 'ar' ? "whenPrintingCustomsDeclaration" : "whenPrintingCustomsDeclarationEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.whenPrintingCustomsDeclaration : journeyContent.content?.whenPrintingCustomsDeclarationEn}
                                                    faicon="fas fa-money-bill content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.whenPrintingCustomsDeclaration : journeyContent.displayContentStatus?.whenPrintingCustomsDeclarationEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.shipmentFollowUp : journeyContent.displayContentStatus?.shipmentFollowUpEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.shipmentFollowUp}
                                                    cardKey={currentLanguage === 'ar' ? "shipmentFollowUp" : "shipmentFollowUpEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.shipmentFollowUp : journeyContent.content?.shipmentFollowUpEn}
                                                    faicon="fas fa-money-bill content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.shipmentFollowUp : journeyContent.displayContentStatus?.shipmentFollowUpEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.previewInMerchantWarehouse : journeyContent.displayContentStatus?.previewInMerchantWarehouseEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.previewInMerchantWarehouse}
                                                    cardKey={currentLanguage === 'ar' ? "previewInMerchantWarehouse" : "previewInMerchantWarehouseEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.previewInMerchantWarehouse : journeyContent.content?.previewInMerchantWarehouseEn}
                                                    faicon="fas fa-money-bill content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.previewInMerchantWarehouse : journeyContent.displayContentStatus?.previewInMerchantWarehouseEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.requestForReconsideration : journeyContent.displayContentStatus?.requestForReconsiderationEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.requestForReconsideration}
                                                    cardKey={currentLanguage === 'ar' ? "requestForReconsideration" : "requestForReconsiderationEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.requestForReconsideration : journeyContent.content?.requestForReconsiderationEn}
                                                    faicon="fas fa-money-bill content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.requestForReconsideration : journeyContent.displayContentStatus?.requestForReconsiderationEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.correctivePlan : journeyContent.displayContentStatus?.correctivePlanEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.correctivePlan}
                                                    cardKey={currentLanguage === 'ar' ? "correctivePlan" : "correctivePlanEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.correctivePlan : journeyContent.content?.correctivePlanEn}
                                                    faicon="fas fa-money-bill content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.correctivePlan : journeyContent.displayContentStatus?.correctivePlanEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.selfEvaluationOfFacility : journeyContent.displayContentStatus?.selfEvaluationOfFacilityEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.selfEvaluationOfFacility}
                                                    cardKey={currentLanguage === 'ar' ? "selfEvaluationOfFacility" : "selfEvaluationOfFacilityEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.selfEvaluationOfFacility : journeyContent.content?.selfEvaluationOfFacilityEn}
                                                    faicon="fas fa-money-bill content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.selfEvaluationOfFacility : journeyContent.displayContentStatus?.selfEvaluationOfFacilityEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.detailedEvidence : journeyContent.displayContentStatus?.detailedEvidenceEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.detailedEvidence}
                                                    cardKey={currentLanguage === 'ar' ? "detailedEvidence" : "detailedEvidenceEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.detailedEvidence : journeyContent.content?.detailedEvidenceEn}
                                                    faicon="fas fa-money-bill content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.detailedEvidence : journeyContent.displayContentStatus?.detailedEvidenceEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.reliability : journeyContent.displayContentStatus?.reliabilityEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.reliability}
                                                    cardKey={currentLanguage === 'ar' ? "reliability" : "reliabilityEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.reliability : journeyContent.content?.reliabilityEn}
                                                    faicon="fas fa-check-square content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.reliability : journeyContent.displayContentStatus?.reliabilityEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }                                            
                                            {
                                                ((currentLanguage === 'ar' ? journeyContent.displayContentStatus?.requiredDocuments : journeyContent.displayContentStatus?.requiredDocumentsEn) || isAuthenticated()) &&
                                                <InformationCard
                                                    contentId={journeyContent.content?.contentId}
                                                    isAuthenticated={isAuthenticated()}
                                                    onChange={handleContentChange}
                                                    title={Translation.journey.requiredDocuments}
                                                    cardKey={currentLanguage === 'ar' ? "requiredDocuments" : "requiredDocumentsEn"}
                                                    description={currentLanguage === 'ar' ? journeyContent.content?.requiredDocuments : journeyContent.content?.requiredDocumentsEn}
                                                    faicon="fas fa-file content-card-container-icon"
                                                    displayStatus={currentLanguage === 'ar' ? journeyContent.displayContentStatus?.requiredDocuments : journeyContent.displayContentStatus?.requiredDocumentsEn}
                                                    handleDisplayStatusChange={handleDisplayStatusChange}
                                                />
                                            }

                                            <SocialLinks />

                                            {isAuthenticated() && <SubmitChangesButton className={"admin-save-changes"} onClick={handleSave} value={Translation.journey.saveChanges} />}
                                            {isAuthenticated() && <SubmitChangesButton className={"admin-cancel-changes"} onClick={handleCancelChanges} value={Translation.journey.cancelChanges} />}
                                        </React.Fragment>
                                    }
                                    ) :
                                <Spinner />
                        }
                    </div>
                </div>
            </div>
            <BottomNavigationBar />
        </div>
    );
}
export default Journey;
