
const SubmitChangesButton = ({ onClick, value, className }) => {
    return (
        <button type="button" className={"btn submit-button " + className} onClick={onClick} style={{ marginRight: "1rem", marginLeft: "1rem", float: "right" }}>
            {value}
        </button>
    );
  };
  
  export default SubmitChangesButton;
  