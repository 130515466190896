import EditableInformationStepText from "./EditableInformationStepText";
import UseTranslations from "../../../Translation/UseTranslations";

const InformationSteps = ({ journeyContents, currentLanguage, handleStepChange, isAuthenticated, handleDeleteStepContent, currentActiveStepOrder, setCurrentActiveStepOrder, handleAddStepContent }) => {
    const Translation = UseTranslations();

    const handleStepClick = (stepId) => {
        let clickedStep = journeyContents.find(step => step['step']['stepId'] === stepId);
        setCurrentActiveStepOrder(clickedStep['step']['stepOrder']);
    }

    const handleContentChange = (newValue, contentKey, stepId) => handleStepChange?.(newValue, contentKey, stepId);

    const handleDeleteStep = (event, step) => {

        event.stopPropagation();
        handleDeleteStepContent(step['stepId']);

        setCurrentActiveStepOrder(0);
    }

    return (
        <div style={{ width: "100%" }} >
            <ol class="information-circule information-circule--large" style={{ direction: currentLanguage === 'ar' ? 'rtl' : 'ltr' }}>
                {
                    journeyContents && journeyContents.length > 0 &&
                    journeyContents.map((step) => {

                        step = step['step'];

                        return <li key={step['stepId']} onClick={(event) => handleStepClick(step['stepId'])}
                            class={currentActiveStepOrder === step['stepOrder'] ? "is-active" : currentActiveStepOrder > step['stepOrder'] ? "is-complete" : " "}
                            style={{ "--step-after-direction": currentLanguage === 'ar' ? "50%" : "-50%" }}
                        >
                            <EditableInformationStepText
                                stepId={step['stepId']}
                                contentKey={currentLanguage === 'ar' ? 'stepTitleAr' : 'stepTitleEn'}
                                onChange={handleContentChange}
                                value={currentLanguage === 'ar' ? step['stepTitleAr'] : step['stepTitleEn']}
                                styling={{ padding: "0 0.8rem", justifyContent: "center", display: "flex", alignItems: "center", fontSize: "0.8rem", textAlign: "center", borderRadius: "50%", height: "8rem", width: "8rem", position: "absolute", zIndex: "10", color: "white" }}
                                isAuthenticated={isAuthenticated} />
                            {isAuthenticated && <button onClick={(event) => handleDeleteStep(event, step)} className="information-circule-delete-button" >x</button>}
                        </li>
                    })
                }
            </ol>
            {isAuthenticated && <button className="admin-add-step-button" onClick={handleAddStepContent}>{Translation.journey.addStep}</button>}
        </div>
    );
}

export default InformationSteps;