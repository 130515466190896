import Text from "../Admin/Editable/Text";
import Editor, { TextEditor, TextEditor1 } from "../Admin/Editable/QuillEditor/Editor1";
import React, { useState } from "react";
import { BACKEND_BASE_URL, BACKEND_DISPLAY_CONTENT_STATUS } from "../../APIs/BackendUrls";
import { getRequest } from "../../APIs/GenericFetch";
import { toast } from "react-hot-toast";

const InformationCard = ({
    title,
    description,
    faicon,
    cardKey,
    isAuthenticated,
    onChange,
    contentId,
    handleDisplayStatusChange,
    displayStatus
}) => {
    const [checkboxClicked, setCheckboxClicked] = useState(displayStatus)

    const handleContentEditableClick = () => setLocalMode(() => "edit");
    const handleContentChange = (content) => {
        onChange?.(contentId, cardKey, content);
    }

    const handleCheckboxChange = (event) => {
        setCheckboxClicked(event.target.checked);
        performUpdateOnStatus(event.target.checked)
    }
    const handleContentAreaOnBlur = (value) => {
        onChange?.(contentId, cardKey, value);
        setLocalMode("read");
    }

    const performUpdateOnStatus = (checkedStatus) => {        
        handleDisplayStatusChange(
            {
                newStatus: checkedStatus,
                contentKey: cardKey,
                contentId: contentId
            }
        );
    }

    const [localMode, setLocalMode] = useState("read");

    return (
        <div className="row content-card-container">       
            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 right-content-card-container">
                <div className="row">
                    <div className="col">             
                        <span>{title}</span>                        
                    </div>
                    {/*<i className={faicon}></i> */}
                    {
                        isAuthenticated &&
                        <div className="form-check col">
                            <input className="form-check-input"
                                type="checkbox"
                                defaultChecked={checkboxClicked}
                                value={checkboxClicked}
                                checked={checkboxClicked}
                                id="flexCheckDefault"
                                onChange={handleCheckboxChange}
                            />
                        </div>
                    }
                </div>
            </div>
            <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 left-content-card-container">
                <div className="content-paragraph"
                    onClick={handleContentEditableClick}>                    
                    <TextEditor1
                        onBlur={handleContentAreaOnBlur}
                        isAuthenticated={isAuthenticated}
                        onChange={handleContentChange}
                        mode={localMode}
                        value={description}
                    />
                </div>
            </div>
        </div>
    );
}

export default InformationCard;
