
const JourneyStartedNavBar = ({ languageLocal, title }) => {

    return (
        <div className="journey-started-navbar" style={{ height: "5rem", backgroundColor: "rgb(7, 43, 70)", width: "100%" }}>
            <div className="journey-started-container container" style={{
                display: "flex", justifyContent: languageLocal === 'ar' ? 'end' : 'start', alignItems: "center", height: "100%", width: "100%", verticalAlign: "middle", color: "white"
            }}>
                <h5>{title}</h5>

                {
                    languageLocal === 'ar' ?
                        <div id="journey-title-icon-starter" style={{ right: '-12px' }}></div>
                        :
                        <div id="journey-title-icon-starter" style={{ left: '-12px' }}></div>
                }
            </div>
        </div>
    );
};

export default JourneyStartedNavBar;
