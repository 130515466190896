import { useContext } from "react";
import LocalizedStrings from "react-localization";
import { UserContext } from "../Context/UserContext"
import { TRANSLATIONS } from "./Translations";


const UseTranslations = () => {

     const { currentLanguage } = useContext(UserContext);

    let translation = new LocalizedStrings(TRANSLATIONS);

    translation.setLanguage(currentLanguage);

    return translation;
}

export default UseTranslations;