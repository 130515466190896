import { useState } from "react";
import UseTranslations from "../../../Translation/UseTranslations";
import SubTable from "./SubTable";
import React from "react";


const Table = ({ addDeleteEvent, handleCancel, handleSave, setDefaultSeviceType, services = [], setServices, activites = [] }) => {

    const Translation = UseTranslations();
    const [currentExpandedTable, setCurrentExpandedTable] = useState(null);

    const handleChange = (value, key, index) => {

        setServices(previousState => {
            if (key === "isFree" && value)
                previousState[index]["isFree"] = !previousState[index]["isFree"];
            else if (key === "formula") {
                if (isValidFormula(value))
                    previousState[index][key] = value;
                else
                    previousState[index][key] = "x";
                if (value === "")
                    previousState[index][key] = "x";
            } else if (key === "hasSubServices") {
                previousState[index]["subServices"].push({
                    id: generateUniqueId(), name: "نوع جديد", isFree: false, formula: "x", nameEn: "New Type", activityId: 1
                });
                previousState[index][key] = value;
            } else if (key === "descriptionAr"){
                previousState[index][key] = value
            } else if (key === "descriptionEn"){
                previousState[index][key] = value
            } else if (key === "unitAr"){
                previousState[index][key] = value
            } else if (key === "unitEn"){
                previousState[index][key] = value
            } else if (key === "variableAr"){
                previousState[index][key] = value
            } else if (key === "variableEn"){
                previousState[index][key] = value
            }else if (key === "name"){
                previousState[index][key] = value
            }
            else
                previousState[index][key] = value;
            return [...previousState];
        });
    }

    const handleAddService = () => {
        setServices(previousState => [...previousState, {
            id: generateUniqueId(), name: "خدمة 1", isFree: false, hasActivity: false, hasSubServices: false, formula: "x", subServicesName: "", subServicesNameEn: "", nameEn: "", isExpanded: false, subServices: []
        }]);
    }

    const handleDeleteService = (index) => {
        setServices(previousState => {
            addDeleteEvent(services[index]["id"], "SERVICE");

            return previousState.filter((_, currentIndex) => currentIndex !== index)
        });
    }

    const generateUniqueId = () => {
        return (Math.random() * (99999 - 9999 + 1)) << 0;
    }

    const isValidFormula = (formula) => {
        return /^[0-9+\-*/.x]+$/.test(formula);
        // return /^([\dx+]+|[\dx]+[\+|\-|\*|\/]+[\dx]+)+$/gm.test(formula);
    }

    return (
        <div id="content-container-calculator" className="">
            <h5 className="float-right mb-4">{Translation.calculator.manageServices}<i className="fa fa-list-alt ml-2 mr-2"></i></h5>
            <div className="">
                <table className="calculator-table calculator-main-table">
                    <tr>
                        <th>{Translation.calculator.serviceNameAr}</th>
                        <th>{Translation.calculator.serviceNameEn}</th>
                        <th>{Translation.calculator.hasTypes}</th>
                        <th>{Translation.calculator.hasActivity}</th>
                        <th>{Translation.calculator.typeNameAr}</th>
                        <th>{Translation.calculator.typeNameEn}</th>
                        <th>{Translation.calculator.free}</th>
                        <th>{Translation.calculator.formula}</th>
                        <th>{Translation.calculator.descriptionAr}</th>
                        <th>{Translation.calculator.descriptionEn}</th>
                        <th>{Translation.calculator.unitAr}</th>
                        <th>{Translation.calculator.unitEn}</th>
                        <th>{Translation.calculator.variableAr}</th>
                        <th>{Translation.calculator.variableEn}</th>
                        <th>{Translation.calculator.deleteService}</th>
                    </tr>
                    {
                        services.map((service, index) => (
                            <React.Fragment key={`${service["name"]}_${service["id"]}`}>
                                <tr key={service["id"]}>
                                    <td><input onChange={(event) => {handleChange(event.target.value, "name", index)}} value={service["name"] || ""} defaultValue="" autoFocus={true} /></td>
                                    <td><input onChange={(event) => handleChange(event.target.value, "nameEn", index)} value={service["nameEn"]} /></td>
                                    <td><input onChange={(event) => handleChange(event.target.checked, "hasSubServices", index)} defaultChecked={service["hasSubServices"]} type="checkbox" /></td>
                                    <td><input onChange={(event) => handleChange(event.target.checked, "hasActivity", index)} disabled={!service["hasSubServices"]} checked={service["hasSubServices"] && service["hasActivity"]} defaultChecked={service["hasActivity"]} type="checkbox" /></td>
                                    <td><input onChange={(event) => handleChange(event.target.value, "subServicesName", index)} disabled={!service["hasSubServices"]} readOnly={service["isFree"]} value={service["hasSubServices"] ? service["subServicesName"] : ""} /></td>
                                    <td><input onChange={(event) => handleChange(event.target.value, "SubServicesNameEn", index)} disabled={!service["hasSubServices"]} readOnly={service["isFree"]} value={service["hasSubServices"] ? service["SubServicesNameEn"] : ""} /></td>
                                    <td><input onChange={(event) => handleChange(event.target.checked, "isFree", index)} disabled={service["hasSubServices"]} defaultChecked={service["hasTypes"] ? false : service["isFree"]} type="checkbox" /></td>
                                    <td><input onChange={(event) => handleChange(event.target.value, "formula", index)} disabled={service["hasSubServices"] || service["isFree"]} value={service["hasSubServices"] || service["isFree"] ? "" : service["formula"]} /></td>
                                    <td><textarea onChange={(event) => handleChange(event.target.value, "descriptionAr", index)} disabled={service["hasSubServices"]} value={service["hasSubServices"] ? "" : service["descriptionAr"]} /></td>
                                    <td><textarea onChange={(event) => handleChange(event.target.value, "descriptionEn", index)} disabled={service["hasSubServices"]} value={service["hasSubServices"] ? "" : service["descriptionEn"]} /></td>
                                    <td><input onChange={(event) => handleChange(event.target.value, "unitAr", index)} disabled={service["hasSubServices"] || service["isFree"]} value={service["hasSubServices"] || service["isFree"] ? "" : service["unitAr"]} /></td>
                                    <td><input onChange={(event) => handleChange(event.target.value, "unitEn", index)} disabled={service["hasSubServices"] || service["isFree"]} value={service["hasSubServices"] || service["isFree"] ? "" : service["unitEn"]} /></td>
                                    <td><input onChange={(event) => handleChange(event.target.value, "variableAr", index)} disabled={service["hasSubServices"] || service["isFree"]} value={service["hasSubServices"] || service["isFree"] ? "" : service["variableAr"]} /></td>
                                    <td><input onChange={(event) => handleChange(event.target.value, "variableEn", index)} disabled={service["hasSubServices"] || service["isFree"]} value={service["hasSubServices"] || service["isFree"] ? "" : service["variableEn"]} /></td>
                                    <td><button className="primary-button pl-2 pr-2 mt-1 mb-1" onClick={(event) => handleDeleteService(index)}><i className="fa fa-times"></i></button></td>
                                </tr>
                                {
                                    service["hasSubServices"]
                                    &&
                                    <>
                                        {currentExpandedTable === index &&
                                            <tr>
                                                <td colSpan="100%">
                                                    <SubTable addDeleteEvent={addDeleteEvent} activites={activites} services={services} setServices={setServices} currentServiceIndex={index} />
                                                </td>
                                            </tr>
                                        }
                                        <tr>
                                            <td colSpan="100%">
                                                <button
                                                    onClick={(event) => {
                                                        setCurrentExpandedTable(index);
                                                        // we need to make the first element to appear. the rest will be handled on the component itself
                                                        setDefaultSeviceType(0) 
                                                    }}
                                                    className={"primary-button m-0 w-100 rounded-0 " + (currentExpandedTable === index ? "mb-3" : "")}
                                                >
                                                    {(currentExpandedTable === index ? (Translation.calculator.closeManageTypes) : (Translation.calculator.manageTypes + " " + service["name"]))}
                                                </button>
                                            </td>
                                        </tr>
                                    </>
                                }
                            </React.Fragment>
                        ))
                    }
                </table>
                <div className="mt-2">
                    <button className="primary-button mr-1 ml-1" onClick={(event) => handleAddService()} >{Translation.calculator.addService}</button>
                    <button className="primary-button mr-1 ml-1" onClick={(event) => handleCancel()}>{Translation.calculator.cancel}</button>
                    <button className="primary-button mr-1 ml-1" onClick={(event) => handleSave()}>{Translation.calculator.save}</button>
                </div>
            </div>
        </div>
    );
}

export default Table;