
const FormGroup = ({label, type, placeholder, id, onChangeFunction}) => {
    return (
        <div className="form-group">
            <label className="form-label"> {label} </label>
            <input onChange={onChangeFunction} className="form-control" type={type} name={type} id={id} placeholder={placeholder} />
        </div>
    );
  };
  
  export default FormGroup;
  