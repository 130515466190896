const TRANSLATIONS = {
    ar: {
        header: {
            homepage: 'الصفحة الرئيسية',
            frequentQuestions: 'أسئلة الشائعة',
            contactUs: 'تواصل معنا',
            calculator: 'حاسبة المستفيد'
        },
        homepage: {
            header: 'مرحباً بك في رحلة العميل مع الهيئة العامة للغذاء والدواء',
            secondHeader: 'شريكنا المستثمر',
            headerDiscerption: 'من خلال هذه الرحلة ستتعرف على الاجراءات و المتطلبات للمنشآت و المنتجات وفسح الإرساليات بالاضافة إلى خدمات أخرى متعددة',
            branch: 'فرع',
            level: 'مستوى',
            modify: 'عدل',
            arabicTitle: 'عنوان عربي',
            englishTitle: 'عنوان بالانجليزي',
            parentTitle: 'أصل',
            add: 'اضف',
            preview: 'معاينة',
            cancel: 'الغاء',
            addLevel: 'اضف مستوى',
            save: 'حفظ'
        },
        footer: {
            privacy: 'سياسة الخصوصية',
            copyRights: 'كل الحقوق محفوظة © الهيئة العامة للغذاء والدواء © ',
            unifiedNumber: 'مركز الإتصال الموحد',
            termsOfUse: 'سياسة الإستخدام'
        },
        journey: {
            description: 'وصف الخدمة',
            serviceUrl: 'رابط الخدمة',
            duration: 'مدة الحصول على الخدمة',
            cost: 'التكلفة',
            reliability: 'اعتمادية الخدمة',
            requirements: 'متطلبات الخدمة',
            requiredDocuments: 'المستندات المطلوبة',
            introductionVideo: 'فيديو تعريفي عن الخدمة',
            journeyStarted: ':رحلتك بدآت الأن',
            replaceVideo: "استبدل الفيديو",
            addVideo: "اضف فيديو",
            addStep: "اضف خطوة",
            or: "أو",
            replaceVideoUrl: "استبدل رابط الفيديو",
            saveChanges: "حفظ التغييرات",
            cancelChanges: "الغاء التغييرات",
            fileToBig: "حجم الملفات يجب ان يكون اقل من 30 م.ب",
            introduction: "مقدمة",
            introductoryVideo: "الفيديو التعريفي",
            beforeExtractingRecord: "قبل استخراج السجل",
            afterExtractingRecord: "بعد استخراج السجل",
            productRegistrationRequirements: "متطلبات تسجيل المنتج",
            facilityLicensingDocuments: "مستندات ترخيص المنشأة",
            beforeAgreeingToPurchaseShipment: "قبل الاتفاق على شراء الشحنة",
            whenPurchasingShipment: "عند شراء الشحنة",
            whenPrintingCustomsDeclaration: "عند طباعة البيان الجمركي",
            shipmentFollowUp: "متابعة الشحنة",
            previewInMerchantWarehouse:"معاينة في مستودع التاجر",
            requestForReconsideration: "طلب إعادة النظر",
            correctivePlan: "الخطة التصحيحية",
            selfEvaluationOfFacility: "التقييم الذاتي للمنشأة",
            detailedEvidence: "الأدلة التفصيلية"
        },
        contactUs: {
            email: "البريد الإلكتروني",
            emailDescription: "CEC@sfda.gov.sa",
            socialMediaAccounts: "منصات التواصل الاجتماعي",
            socialMediaAccountsDescription: "@Saudi_FDA",
            unifiedNumber: "الرقم الموحد المجاني",
            unifiedNumberDescription: "19999",
            internationalNumber: "الرقم الدولي",
            internationalNumberDescription: "966-11-880-6060",
            address: "العنوان",
            addressDescription: "4904 الدائري الشمالي الفرعي - حي حطين",
            unitNumber: "رقم الوحدة",
            unitNumberDescription: "1 الرياض 13513-7148",
            country: "الدولة",
            countryDescription: "المملكة العربية السعودية",
            contactUsTitle: ":تواصل معنا من خلال قنواتنا الرسمية",
            postAddressTitle: ":العنوان البريدي",
        },
        FAQ: {
            title: "أسئلة شائعة",
            loadMore: "أظهر المزيد"
        },
        messages: {
            changesUploadedSuccessfully: "تم رفع التغييرات",
            contentWereCopied: "تم نسخ المعلومة",
            changesAreBeingUploaded: "يتم رفع التغييرات...",
            changesWereCanceled: "تم الغاء التغييرات",
            attemptingToLogin: "يتم المحاولة تسجيل الدخول...",

            fileMustBeVideo: "الرجاء اختيار ملف بصيغة فيديو",
            pickOneFileAtLeast: "الرجاء اختيار ملف واحد على الاقل",
            cellMustBeNotEmpty: "المدخل يجب ان لا يكون خانة فارغة",
            cantAddMoreThanLevels: "لا يمكن اضافة اكثر من المستويات الحالية",
            cantAddMoreThanLevels: "لا يمكن اضافة اكثر من المستويات الحالية",
            cantAddButtonAtLevel: "لا يمكن اضافة خيار في المستوى لعدم وجود خيارات بالمستوى",
            cantRemoveLastStep: "لا يمكن حذف اخر خطوه",
            goToServiceDetails: "الذهاب الى صفحة تفاصيل خدمة",

            unSavedChangesLoss: "يجب حفظ تغييراتك قبل الذهاب الى صفحة تفاصيل الخدمة لتجنب ضياع التغييرات",
            unSavedButton: "يجب حفظ التغييرات قبل محاولة فتح محتوى خيار جديد",
            urlIsNotValid: "الرجاء ادخال رابط صالح",
            noDataWasFound: "لا يوجد معلومات في الوقت الحالي",
            wrongUsernameOrPassowrd: "البريد الإلكتروني او رقم السري غير صحيح",
            somethingWentWrong: "حدث خطأء ما الرجاء المحاولة لاحقا",
            cantAddMoreSteps: "لا يمكن اضافة اكثر من الخطوات الحالية",
        },
        calculator: {
            pickService: "اختر الخدمة التي تناسبك",
            serviceDescription: "تفاصيل الخدمة",
            serviceCost: ":الرسوم المالية",
            calculate: "احسب",
            endJourney: "انهاء الرحلة",
            header: "هذه حاسبة افتراضية للرسوم ولا تمثل التزام على الهيئة",
            save: "حفظ",
            cancel: "الغاء",
            deleteService: "حذف",
            addService: "اضف خدمة",
            manageServices: "ادارة الخدمات",
            formula: "معادلة أو التكلفة",
            free: "مجانية",
            serviceNameAr: "اسم الخدمة بالعربي",
            serviceDescription: ":وصف الخدمة",
            serviceDescriptionPrefix: "هذه الخدمة تتيح لك معرفة الرسوم المالية لخدمة ",
            landArea: "أدخل مساحة الأرض",
            estimatedCost: "الرسوم المالية التقريبية",
            serviceNameEn: "اسم الخدمة بالانجليزي",
            serviceName: "اسم الخدمة",
            hasTypes: "لديها انواع",
            manageTypes: "أدِر أنواع",
            closeManageTypes: "اغلق إدارة أنواع",
            addType: "اضف نوع",
            typeName: "اسم النوع",
            serviceType: "نوع الخدمة",
            pick: "اختر",
            typeNameAr: "النوع بالعربي",
            typeNameEn: "النوع بالانجليزي",
            forType: " للـ ",
            activity: "النشاط",
            pickActivity: "اختر نوع النشاط",
            inEnglish: "بالانجليزي",
            inArabic: "بالعربي",
            hasActivity: "لديها نشاط",
            noEmptyCellAllowed: "يجب ادخال جميع المدخلات",
            serviceNameShouldNotBeEmpty: "يجب ادخال اسم الخدمة بالعربي واسم الخدمة بالانجليزي",
            variableShouldNotBeEmpty: "يجب ادخال المتغيرات",
            currency: "ريال",
            descriptionAr: "الوصف بالعربي",
            descriptionEn: "الوصف بالانجليزي",
            unitAr: "الوحدة بالعربي",
            unitEn: "الوحدة بالانجليزي",
            variableAr: "المتغير بالعربي",
            variableEn: "المتغير بالانجليزي",
            calculate: "احسب"
        },
        language: 'اللغة',
        languageLocal: 'ar',
        toLanguage: 'English',
        toLanguageLocal: 'en',
        CSSDirecton: 'rtl',
        toCSSDirection: 'ltr'
    },
    en: {
        header: {
            homepage: 'Home Page',
            frequentQuestions: 'FAQ',
            contactUs: 'Contact Us',
            calculator: 'Calculator'
        },
        homepage: {
            header: 'Welcome to SFDA\'s investor journey ',
            secondHeader: 'Dear Investor',
            headerDiscerption: 'Through this journey you will get to know about our requirements, producers, products and other services we provide',
            branch: 'Branch',
            level: 'Level',
            modify: 'Modify',
            arabicTitle: 'Arabic Title',
            englishTitle: 'English Title',
            parentTitle: 'Parent',
            add: 'Add',
            preview: 'Preivew',
            cancel: 'Cancel',
            addLevel: 'Add Level',
            save: 'Save'
        },
        footer: {
            privacy: 'Privacy policy',
            copyRights: 'All rights reserved © SFDA © ',
            unifiedNumber: 'Unified Number Center',
            termsOfUse: 'Terms Of Use'
        },
        journey: {
            description: 'Description',
            serviceUrl: 'Service Url',
            duration: 'Duration',
            cost: 'Cost',
            reliability: 'Reliability',
            requirements: 'Requirements',
            requiredDocuments: 'Required Documents',
            introductionVideo: 'Introduction Video',
            journeyStarted: 'Your Journey Starts Now:',
            addVideo: "Add Video",
            replaceVideo: "Replace Video",
            addStep: "Add Step",
            saveChanges: "Save Changes",
            cancelChanges: "Cancel Changes",
            replaceVideoUrl: "Replace Video Url",
            or: "Or",
            fileToBig: "File size must be less than 30mb",
            introduction: "Introduction",
            introductoryVideo: "Introductory Video",
            beforeExtractingRecord: "Before Extracting Record",
            afterExtractingRecord: "After Extracting Record",
            productRegistrationRequirements: "Product Registration Requirements",
            facilityLicensingDocuments: "Facility Licensing Documents",
            beforeAgreeingToPurchaseShipment: "Before Agreeing To Purchase Shipment",
            whenPurchasingShipment: "When Purchasing Shipment",
            whenPrintingCustomsDeclaration: "When Printing Customs Declaration",
            shipmentFollowUp: "Shipment Follow Up",
            previewInMerchantWarehouse:"Preview In Merchant Warehouse",
            requestForReconsideration: "Request For Reconsideration",
            correctivePlan: "Corrective Plan",
            selfEvaluationOfFacility: "Self-Evaluation Of Facility",
            detailedEvidence: "Detailed Evidence"
        },
        contactUs: {
            email: "Email",
            emailDescription: "CEC@sfda.gov.sa",
            socialMediaAccounts: "Social Media Platforms",
            socialMediaAccountsDescription: "@Saudi_FDA",
            unifiedNumber: "Free Unified Number",
            unifiedNumberDescription: "19999",
            internationalNumber: "International Number",
            internationalNumberDescription: "966-11-880-6060",
            address: "Address",
            addressDescription: "4904 Hittin, Riyadh",
            unitNumber: "Unit Number",
            unitNumberDescription: "1 Riyadh 13513-7148",
            country: "Country",
            countryDescription: "Saudi Arabia",
            contactUsTitle: ":Contact Us Via Our Offical Channels",
            postAddressTitle: ":Post Address"
        },
        FAQ: {
            title: "Frequent Questions",
            loadMore: "Load More"
        },
        messages: {
            changesUploadedSuccessfully: "Changes Uploaded Successfully",
            contentWereCopied: "Content Were Copied!",
            changesAreBeingUploaded: "Changes Are Being Uploaded...",
            changesWereCanceled: "Changes Has Been Canceled",
            attemptingToLogin: "Attempting To Login...",

            fileMustBeVideo: "The File Must Be Video",
            pickOneFileAtLeast: "pick One File At Least",
            cellMustBeNotEmpty: "Cell Must Not Be Empty",
            cantAddMoreThanLevels: "Can't Add More Than The Current Levels",
            cantAddButtonAtLevel: "Can't Add Button At Level With No Levels In The Previous Level",
            cantRemoveLastStep: "Can't Remove The Last Step",
            cantAddMoreSteps: "Can't Add More Steps",
            goToServiceDetails: "Go To Joureny Details For",

            unSavedChangesLoss: "Going To Content Page Without Saving Your Changes Would Lead To Changes Loss",
            unSavedButton: "Can't Open Unsaved Button",
            urlIsNotValid: "URL Is Not Valid",
            noDataWasFound: "No Data Available",
            wrongUsernameOrPassowrd: "Wrong Password Or Email",
            somethingWentWrong: "Something Went Wrong",
        },
        calculator: {
            pickService: "Pick A Service",
            serviceDescription: "Service Description",
            serviceCost: "Service Cost",
            calculate: "Calculate",
            endJourney: "End Journey",
            header: "The cost showing is just an estimate and does not implies any commitment from us",
            addService: "Add Service",
            manageServices: "Manage Service",
            formula: "Formula Or Cost",
            free: "Free",
            serviceNameAr: "Arabic Service Name",
            save: "Save",
            typeName: "Type Name",
            cancel: "Cancel",
            deleteService: "Delete",
            // serviceDescription: "Service Description:",
            serviceDescriptionPrefix: "This service shows you the estimated cost of ",
            landArea: "Enter Land Area",
            estimatedCost: "Estimated Cost",
            serviceNameEn: "English Service Name",
            serviceName: "Service Name",
            hasTypes: "Has Types",
            manageTypes: "Manage Types",
            closeManageTypes: "Close Manage Types",
            addType: "Add Type",
            serviceType: "Service Type",
            pick: "pick",
            typeNameAr: "Type Name Arabic",
            typeNameEn: "Type Name English",
            forType: " For ",
            activity: "activity",
            pickActivity: "Pick Activity Type",
            inEnglish: "English",
            inArabic: "Arabic",
            hasActivity: "Has Activity",
            noEmptyCellAllowed: "You must fill the fields",
            serviceNameShouldNotBeEmpty: "you must fill arabic service name and english service name",
            variableShouldNotBeEmpty: "you must fill variables",
            currency:"SAR",
            descriptionAr: "Arabic Description",
            descriptionEn: "English Description",
            unitAr: "Arabic Unit",
            unitEn: "English Unit",
            variableAr: "Arabic Variable",
            variableEn: "English Variable",
            calculate: "calculate"
        },
        language: 'Language',
        languageLocal: 'en',
        toLanguage: 'العربية',
        toLanguageLocal: 'ar',
        CSSDirecton: 'ltr',
        toCSSDirection: 'rtl'
    }
};

const DEFAULT_LANGUAGE = "ar";

export { DEFAULT_LANGUAGE, TRANSLATIONS };