import React from "react";
import { useRef } from "react";


const NativeVideoPlayer = React.memo(({ videoSource }) => {

    // because when dynamically changing the video source the video element will not update without below lines
    const videoRef = useRef();
    videoRef.current?.load();

    return (
        <video ref={videoRef} key="native-video-player" controls style={{ width: "65%" }} >
            <source src={videoSource} />
            Your browser does not support the video tag.
        </video>
    );

});

export default NativeVideoPlayer;
