import { Link } from "react-router-dom";
import LanguageButton from "./LanguageButton";
import UseTranslations from "../../Translation/UseTranslations";
import LogoutButton from "../Admin/Login/LogoutButton";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../Context/UserContext";

const NavigationBar = ({ secondNavbarClassName }) => {

    const Translation = UseTranslations();
    const { isAuthenticated, currentLanguage } = useContext(UserContext);

    const [currentLocation,setCurrentLocation] = useState(window.location.pathname)


    useEffect(()=>{
        setCurrentLocation(window.location.pathname)
    }, [currentLocation])

    console.log({currentLocation})
    return (
        <div id="top-navbar">
            <nav id="first-navbar" className="navbar navbar-dark p-1">
                {isAuthenticated() ? <LogoutButton /> : <></>}
                {!currentLocation.includes("journey") && <LanguageButton />} 
            </nav>
            <nav className={"navbar navbar-expand-lg p-4 navbar-dark pl-5 pr-5 " + secondNavbarClassName} style={{ direction: Translation.languageLocal === 'ar' ? 'ltr' : 'rtl' }}>

                <a class="navbar-brand order-1" href="#">
                    <img id="vision-logo" src="/images/vision-logo.png" alt="vision logo" />
                </a>

                <button class="navbar-toggler order-4" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse order-2" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <Link className="navbar-link home-page-link" to="/calculator">
                                {Translation.header.calculator}
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link className="navbar-link home-page-link" to="/contactus">
                                {Translation.header.contactUs}
                            </Link>
                        </li>
                        { currentLanguage !== 'en' && <li class="nav-item">
                            <Link className="navbar-link home-page-link" to="/FAQ">
                                {Translation.header.frequentQuestions}
                            </Link>
                        </li>}
                        <li class="nav-item active">
                            <Link className="navbar-link home-page-link" to="/">
                                {Translation.header.homepage}
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="order-2">
                    <img className=" ml-2 mr-2" id="sfda-logo" src="/images/logo_white.png" alt="sfda logo" />
                </div>

            </nav>
        </div>
    );
};

export default NavigationBar;
