import React, { useContext } from 'react';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';


const ShepherdButton = () => {
    const tour = useContext(ShepherdTourContext);

    return <button onClick={tour.start} class="tour-guide-button" type="button" style={{
        zIndex: "999", width: "3rem", height: "3rem", color: "#072b46", position: "fixed", bottom: "1rem", right: "1rem", borderRadius: "50px", textAlign: "center", backgroundColor: "white", outline: "none", border: "none"
    }} className="btn btn-primary mr-2">?</button>;
}

const TourButton = ({ tourSteps }) => {

    return (
        <ShepherdTour steps={tourSteps} tourOptions={{
            defaultStepOptions: {
                modalOverlayOpeningPadding: 2,
                modalOverlayOpeningRadius: 4,
                scrollTo: { behavior: "smooth", block: "center", inline: "center" },
                popperOptions: {
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 15]
                            }
                        },
                        {
                            name: "arrow",
                            options: {
                                padding: 24
                            }
                        }
                    ]
                },
                cancelIcon: {
                    enabled: true
                }
            },
            useModalOverlay: true
        }}>
            <ShepherdButton />
        </ShepherdTour>
    );
}

export default TourButton;
