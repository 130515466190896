import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import UseTranslations from "../../../Translation/UseTranslations";

const LevelButton = ({ isANewButton, isAuthenticated, isContentManagementMode, currentLanguage, isActive, buttonData, onOpenBranch, currentLevel, currentBranch }) => {

    const handleOpenBranch = () => onOpenBranch?.(buttonData.buttonId, currentBranch, currentLevel, buttonData.targetBranch, buttonData.targetLevel);
    const buttonValue = currentLanguage == 'ar' ? buttonData.titleAr : buttonData.titleEn;

    let navigate = useNavigate();
    const Translation = UseTranslations();

    const handleOnClick = (event) => {
        if (isAuthenticated && !isContentManagementMode) {

            if (isANewButton(buttonData.buttonId)) {
                toast.error(Translation.messages.unSavedButton);
                return;
            }

            return toast((toastEvent) => (
                <span style={{ whiteSpace: "nowrap" }}>
                    <button type="button" class={"btn dissection-button"} onClick={() => {
                        toast.dismiss(toastEvent.id)
                        return navigate("journey/" + buttonData.journeyId);
                    }}>
                        {currentLanguage == 'ar' ? 'نعم' : 'Yes'}
                    </button>
                    {Translation.messages.goToServiceDetails + " " + buttonValue}
                </span>
            ));
        }

        return navigate("journey/" + buttonData.journeyId);
    }

    return (
        <>

            {
                buttonData.hasContent && !isContentManagementMode ?
                    <button onClick={handleOnClick} type="button" class={"btn level-option " + (isActive ? "selected" : "")}>
                        {buttonValue}
                    </button>
                    : <a key={buttonData.Id} type="button" class={"btn level-option " + (isActive ? "selected" : "")} onClick={handleOpenBranch}>
                        {buttonValue}
                    </a>
            }

        </>
    );
};

export default LevelButton;
