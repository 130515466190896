import React from "react";


const YoutubeVideoPlayer = React.memo(({ videoSource }) => {

    return (
        <iframe src={videoSource}
                frameBorder='0'
                allow='autoplay;encrypted-media;fullscreen'
                title='video'
                style={{ width: "65%", height: "20rem" }}
        />
    );

});

export default YoutubeVideoPlayer;
