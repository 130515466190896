import Levels from "./Level/Levels";
import React from "react";
import UseTranslations from "../../Translation/UseTranslations";


const HomeContent = () => {

    const Translation = UseTranslations();

    return (
        <div id="main-container" class="container-fluid">
            <div class="home-page-background"></div>
            <div id="title-container" >
                <div class="row justify-content-center mb-5 mt-5">
                    <div>
                        <h4 id="home-title"> {Translation.homepage.header} </h4>
                        {
                            Translation.languageLocal === 'ar' ?
                                <div id="home-title-icon-starter" style={{ right: '-18px' }}></div>
                                :
                                <div id="home-title-icon-starter" style={{ left: '-18px' }}></div>
                        }
                    </div>
                </div>
                <div id="title-description-container" class="row justify-content-center mb-5">
                    <h4 class="one-line-text">
                        {Translation.homepage.secondHeader}
                    </h4>
                    <p class="one-line-text">
                        {Translation.homepage.headerDiscerption}
                    </p>
                </div>
                <div class="row justify-content-center">
                    {
                        <Levels />
                    }
                </div>
            </div>
        </div>
    );
};

export default HomeContent;
