export default [
    {
        id: 'welcome',
        text: ['<p class="shepherd-text">جولة في طريقة استخدام الجدول لتعديل محتوى النظام </p>'],
        attachTo: {
            element: '.admin-table',
            on: 'bottom'
        },
        canClickTarget: false,
        classes: 'shepherd shepherd-welcome',
        buttons: [
            {
                classes: "tour-skip-button",
                text: "تخطى",
                type: "cancel",
            },
            {
                classes: "tour-button",
                text: "ابدا الجولة",
                type: "next",
            }
        ]
    },
    {
        id: "step1",
        attachTo: {
            element: ".admin-table-add-button",
            on: "top-start"
        },
        canClickTarget: false,
        buttons: [
            {
                classes: "tour-button",
                text: "التالي",
                type: "next",
            }
        ],
        text: "يمكنك اضافة خيار في الواجهة من خلال الضغط على الخيار اضف",
    },
    {
        id: "step2",
        attachTo: {
            element: ".admin-table-add-level",
            on: "top-start"
        },
        canClickTarget: false,
        buttons: [
            {
                classes: "tour-button",
                text: "التالي",
                type: "next",
            }
        ],
        text: "يمكنك اضافة مستوى جديد في الواجهة من خلال الضغط على الخيار اضف",
    },
    {
        id: "step3",
        attachTo: {
            element: ".admin-table-delete-button",
            on: "top-start"
        },
        canClickTarget: false,
        buttons: [
            {
                classes: "tour-button",
                text: "التالي",
                type: "next",
            }
        ],
        text: "لحذف احدى الخيارات اضغط على خيار الحذف "
    },
    {
        id: "step4",
        attachTo: {
            element: ".admin-table-preview-button",
            on: "top-start"
        },
        canClickTarget: false,
        buttons: [
            {
                classes: "tour-button",
                text: "التالي",
                type: "next",
            }
        ],
        title: "الخطوة السادسة",
        text: "لمعاينة التغييرات المحدثة من قبلك قبل حفظ التغييرات يمكنك الضغط على الخيار معاينة"
    },
    {
        id: "step5",
        attachTo: {
            element: ".admin-table-cancel-button",
            on: "top-start"
        },
        canClickTarget: false,
        buttons: [
            {
                classes: "tour-button",
                text: "التالي",
                type: "next",
            }
        ],
        text: "للالغاء التغييرات المحدثة من قبلك في الجلسة التعديل الحالية يمكن الغضط على خيار الالغاء",
    },
    {
        id: "step6",
        attachTo: {
            element: ".admin-table-save-button",
            on: "top-start"
        },
        canClickTarget: false,
        buttons: [
            {
                classes: "tour-button",
                text: "التالي",
                type: "next",
            }
        ],
        text: "لحفظ التغييرات المحدثة يمكنك الضغط على زر الحفظ وسوف تظهر التغييرات للمستثمرين بشكل مباشر",
    },
]
