import UseTranslations from "../../Translation/UseTranslations";

const BottomNavigationBar = () => {

    const Translation = UseTranslations();
    const today = new Date();
    const year = today.getFullYear();

    return (
        <div id="bottom-navbar-container">
            <div className="row align-items-center w-100 uncopyable">
                <div id="rights-container" className="col-sm-12 col-xs-12 col-md-4 order-xs-1 mb-2">
                    <span>
                        {Translation.footer.copyRights + year}
                    </span>
                </div>
                <div className="col-sm-12 col-xs-12 col-md-4 order-xs-2 mb-2">
                    <div className="container">
                        <div className="row justify-content-center">
                            <strong>
                                {Translation.footer.unifiedNumber} : 19999
                            </strong>
                        </div>
                        <div className="row mt-3">
                            <div className="container" style={{ color: "white" }}>
                                <a className="no-styling-link" target="_blank" href="https://www.facebook.com/SaudiFDA/"><i class="fab fa-facebook-square ml-1" ></i></a>
                                <a className="no-styling-link" target="_blank" href="https://twitter.com/saudi_fda"><i class="fab fa-twitter-square ml-1" ></i></a>
                                <a className="no-styling-link" target="_blank" href="https://ae.linkedin.com/company/sfda"><i class="fab fa-linkedin ml-1" ></i></a>
                                <a className="no-styling-link" target="_blank" href="http://instagram.com/saudi_fda"><i class="fab fa-instagram-square ml-1 text-white" ></i></a>
                                <a className="no-styling-link" target="_blank" href="https://www.youtube.com/channel/UC2-qPKHVpCDhJJIky-SkkJg"><i class="fab fa-youtube-square ml-1" ></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="terms-container" className="col-sm-12 col-xs-12 col-md-2 mb-2">
                    <a target="_blank" id="terms-of-use-link" href="https://sfda.gov.sa/ar/terms-of-use" style={{ color: "white" }}>
                        <span>
                            {Translation.footer.termsOfUse}
                        </span>
                    </a>
                </div>
                <div id="privacy-container" className="col-sm-12 col-xs-12 col-md-2 mb-2">
                    <a target="_blank" id="privacy-link" href="https://sfda.gov.sa/ar/privacy-policy" style={{ color: "white" }}>
                        <span>
                            {Translation.footer.privacy}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default BottomNavigationBar;
