export default [
    {
        id: 'welcome',
        text: ['<p class="shepherd-text">جولة في طريقة تعديل محتوى تفاصيل الخدمة</p>'],
        attachTo: {
            element: '#content-container',
            on: 'bottom'
        },
        canClickTarget: false,
        classes: 'shepherd shepherd-welcome',
        buttons: [
            {
                classes: "tour-skip-button",
                text: "تخطى",
                type: "cancel",
            },
            {
                classes: "tour-button",
                text: "ابدا الجولة",
                type: "next",
            }
        ]
    },
    {
        id: "step1",
        attachTo: {
            element: ".content-paragraph",
            on: "top-start"
        },
        canClickTarget: false,
        buttons: [
            {
                classes: "tour-button",
                text: "التالي",
                type: "next",
            }
        ],
        text: "يمكنك الضغط على احدى خانات التعديل لتعديل على المحتوى بشكل مباشر",
    },
    {
        id: "step2",
        attachTo: {
            element: ".language-button",
            on: "top-start"
        },
        canClickTarget: false,
        buttons: [
            {
                classes: "tour-button",
                text: "التالي",
                type: "next",
            }
        ],
        text: "للتعديل اللغة الاخرى يمكنك الضغط على خيار تغيير اللغة ورا تظهر محتوى باللغة الاخرى وراح تقدر تكمل تعديل من دون تخسر تغييراتك باللغة السابقة",
    },
    {
        id: "step3",
        attachTo: {
            element: ".video-upload-tool-tip",
            on: "top-start"
        },
        canClickTarget: false,
        buttons: [
            {
                classes: "tour-button",
                text: "التالي",
                type: "next",
            }
        ],
        text: "يمكنك رفع فيديو من جهازك الشخصي او رابط فيديو من خدمة اخرى او من اليوتيوب",
    },
    {
        id: "step5",
        attachTo: {
            element: ".admin-cancel-changes",
            on: "top-start"
        },
        canClickTarget: false,
        buttons: [
            {
                classes: "tour-button",
                text: "التالي",
                type: "next",
            }
        ],
        text: "يمكنك الغاء التغييرات المحدثة بالضغط على الغاء"
    },
    {
        id: "step4",
        attachTo: {
            element: ".admin-save-changes",
            on: "top-start"
        },
        canClickTarget: false,
        buttons: [
            {
                classes: "tour-button",
                text: "التالي",
                type: "next",
            }
        ],
        text: "يمكنك حفظ التغييرات المحدثة بالضغط على حفظ"
    },
]
