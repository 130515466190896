import { useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import UseTranslations from '../../Translation/UseTranslations';

const LanguageButton = () => {

    const Translation = UseTranslations();

    const { setCurrentLanguage } = useContext(UserContext);

    const handleLanguageChange = (event) => {
        event.preventDefault();

        // Gets data attr from below button "data-to-language"
        const toLanguage = event.target.dataset.toLanguage;

        setCurrentLanguage(toLanguage);
    }

    return (
        <div className="p-1">
            <button type="button"
                onClick={handleLanguageChange}
                style={{ marginLeft: "2rem", backgroundColor: "#072b46", outline: "none", border: "none" }}
                className="btn btn-primary language-button"
                data-to-language={Translation.toLanguageLocal}>
                {Translation.toLanguage}
            </button>
        </div>
    );
};

/*<button type="button"
onClick={handleLanguageChange}
style={{ marginLeft: "5rem", backgroundColor: "#072b46", outline: "none", border: "none" }}
className="btn btn-primary language-button"
data-to-language={Translation.toLanguageLocal}>
{Translation.toLanguage}
</button>*/

export default LanguageButton;
