import React from "react";

const EditableInformationStepText = ({ isAuthenticated, value, onChange, styling, contentKey, stepId }) => {

    const [localMode, setLocalMode] = React.useState("read");

    const handleEditClick = (event) => setLocalMode("edit");
    const handleOnChange = (event) => onChange?.(event.target.value, contentKey, stepId);
    const handleOnBlur = (event) => setLocalMode("read");

    return (
        (localMode == "edit" && isAuthenticated) ?
            <textarea
                className="editable-textarea"
                

                style={{ ...styling, margin: "0", paddingTop: "3rem" }}

                onBlur={handleOnBlur}

                onInput={handleOnChange}

                value={ value }
                autoFocus
            ></textarea> :
            <p onClick={handleEditClick} style={{ ...styling, margin: "0", cursor: "pointer" }}> {value}</p>
    );

}

export default EditableInformationStepText;