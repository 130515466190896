import LoginForm from "./LoginForm";


const LoginContainer = ({ onEmailChanged, onPasswordChanged, onFormSubmit }) => {

  return (
      <div id="login-container-wrapper" class="container-fluid">
        <div class="home-page-background"></div>
        <div id="title-container">
          <div class="row justify-content-center mb-5">
            <div>
              <div id="login-title-icon-starter"></div>
              <h4 id="home-title">تسجيل دخول مدير النظام رحلة العميل</h4>
            </div>
          </div>
          <div id="title-description-container" class="row justify-content-center mb-5">
            <p class="one-line-text">سوف تمكنك هذي الصفحة للتعديل على محتوى المختار في نظام رحلة العميل</p>
          </div>
          <LoginForm onEmailChanged={onEmailChanged} onPasswordChanged={onPasswordChanged} onFormSubmit={onFormSubmit} />
        </div>
      </div>
  );
};

export default LoginContainer;