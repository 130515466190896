import UseTranslations from "../../Translation/UseTranslations";
import BottomNavigationBar from "../Layout/BottomNavigationBar";
import NavigationBar from "../Layout/NavigationBar";
import { Toaster, toast } from 'react-hot-toast';
import { useState } from "react";
import { Link } from "react-router-dom";
import Table from "./Admin/Table";
import { getRequest, postRequest } from "../../APIs/GenericFetch";
import { BACKEND_BASE_URL, BACKEND_CALCULATOR_SERVICES, BACKEND_CALCULATOR_ACTIVITES, BACKEND_CALCULATOR_SERVICES_DELETE_HANDLER_URI } from "../../APIs/BackendUrls";
import { useEffect, useContext } from "react";
import { UserContext } from "../../Context/UserContext";

const Calculator = () => {
    const { currentLanguage } = useContext(UserContext);
    const Translation = UseTranslations();
    const { isAuthenticated } = useContext(UserContext);

    useEffect(() => {
        fetchCalculatorData();
        fetchActivites();
    }, []);

    const [servicesDeleteEvent, setServicesDeleteEvent] = useState([]);

    const [services, setServices] = useState([]);
    const [activites, setActivites] = useState([]);
    const [calculationResult, setCalculationResult] = useState("");

    const [currentService, setCurrentService] = useState(null);
    const [currentType, setCurrentType] = useState(null);
    const [currentActivity, setCurrentActivity] = useState(1);

    const [currentLandSpace, setCurrentLandSpace] = useState();
    const [servicesPreChanges, setServicesPreChanges] = useState([]);

    const [searchableTerm, setSearchableTerm] = useState("");
    const [isDropdownActive, setIsDropdownActive] = useState(false);

    const [calculateBtnClicked, setCalculateBtnClicked] = useState(false)

    const addDeleteEvent = (id, type) => {
        setServicesDeleteEvent(previousState => [...previousState, { id: id, type: type }]);
    }

    const fetchActivites = () => {
        return getRequest(BACKEND_BASE_URL + BACKEND_CALCULATOR_ACTIVITES).then(responseObject => {
            var fetcheData = responseObject["data"];
            if (responseObject.success) {
                if (fetcheData.length === 0) {
                    toast.error(Translation.messages.noDataWasFound); return false;
                }
                setActivites(fetcheData); return true;
            } else
                toast.error(Translation.messages.somethingWentWrong);
            return false;
        });
    }

    const fetchCalculatorData = () => {
        return getRequest(BACKEND_BASE_URL + BACKEND_CALCULATOR_SERVICES).then(responseObject => {
            var fetcheData = responseObject["data"];

            if (responseObject.success) {
                if (responseObject.status === 200) {
                    if (fetcheData.length === 0) {
                        toast.error(Translation.messages.noDataWasFound); return false;
                    }
                    setServices(fetcheData);
                    setServicesPreChanges(deepCloneOjbect(fetcheData));
                    return true;
                } else
                    toast.error(Translation.messages.somethingWentWrong);
            }
            return false;
        });
    }

    const deleteCalculatorData = () => {
        return postRequest(BACKEND_BASE_URL + BACKEND_CALCULATOR_SERVICES_DELETE_HANDLER_URI, servicesDeleteEvent).then(responseObject => {
            if (!responseObject.success || responseObject.status != 200)
                toast.error(Translation.messages.somethingWentWrong);
            else {
                setServicesDeleteEvent([]);
            }
        });
    }

    const updateCaculatorData = () => {
        return postRequest(BACKEND_BASE_URL + BACKEND_CALCULATOR_SERVICES, services).then(responseObject => {
            const toastId = toast.loading(Translation.messages.changesAreBeingUploaded);
            var fetcheData = responseObject["data"];
            if (!responseObject.success || responseObject.status != 200)
                toast.error(Translation.messages.somethingWentWrong);
            else {
                setServices(fetcheData);
                setServicesPreChanges(deepCloneOjbect(fetcheData));
                toast.success(Translation.messages.changesUploadedSuccessfully);
            }
            toast.dismiss(toastId);
        });
    }

    const calculateFormula = (value) => {
        var formula = "x";
        if (services[currentService]["hasSubServices"]) {
            formula = services[currentService]["subServices"][currentType]["formula"];
        } else {
            formula = services[currentService]["formula"];
        }
        setCalculationResult(evaluateFormula(formula, value));
    }

    const evaluateFormula = (formula, value) => {
        return eval(formula.replace("x", parseInt(value)));
    }

    const isFixedCost = (formula) => {
        return /^\d+$/gm.test(formula)
    }

    const handleCalculationResult = (value) => {
        if (value >= 1) {
            setCurrentLandSpace(value);
            calculateFormula(value);
        } else {
            setCurrentLandSpace("")
            setCalculationResult("")
        }
    }

    const handleSave = () => {
        if (isServicesValid()) {
            updateCaculatorData();
            if (servicesDeleteEvent.length > 0)
                deleteCalculatorData();
        }
    }

    const handleCancel = () => {
        setServices(deepCloneOjbect(servicesPreChanges));
        setServicesDeleteEvent([]);
    }

    const deepCloneOjbect = (targetObject) => {
        return JSON.parse(JSON.stringify(targetObject));
    }

    const isServicesValid = () => {
        var flag = true;
        var errorType = 0;
        for (const service of services) {
            console.log(service);
            if (service["name"] === "" 
            || service["nameEn"] === "" 
            || (service["hasSubServices"] 
            && service["SubServicesName"] === "" 
            || service["SubServicesNameEn"] === ""
            )) {
                errorType = 1;
                flag = false;
            } else if (service['hasSubServices'] === false){
                if(service["formula"].includes("x") && (!service["variableAr"] || !service["variableEn"])) {
                    errorType = 2;
                } 
            } else if (service['hasSubServices'] === true){
                service['subServices'].forEach((serv)=>{
                    if(serv.formula.includes("x") && (!serv.variableAr || !serv.variableEn)){
                        errorType = 3;
                        flag = false;
                    }
                })
            }
            
            if (flag === false){
                switch(errorType) {
                    case 1:
                      toast.error(Translation.calculator.serviceNameShouldNotBeEmpty);
                      break;
                    case 2:
                      toast.error(Translation.calculator.variableShouldNotBeEmpty);
                      break;
                    default:
                        toast.error(Translation.calculator.variableShouldNotBeEmpty);
                  }
                break;
            }
        };
        return flag;
    }
    const handleClickCalculateBtn = () => {
        setCalculateBtnClicked(!calculateBtnClicked)
    }

    const renderCostCalculator = (service) => {
        return (
            service && <>
                <p className="ml-1 d-inline"><strong>{Translation.calculator.serviceCost}</strong></p>
                <i className="fa fa-credit-card ml-2 mb-4"></i>
                {
                    service["isFree"] ?
                        <p>
                            {Translation.calculator.free}
                        </p>
                        :
                        (isFixedCost(service["formula"])) ?
                            <div className="row justify-content-end" style={{ dir: currentLanguage === "ar" ? "rtl" : "ltr" }} >
                                <p style={{ direction: currentLanguage === "ar" ? "rtl" : "ltr" }}>
                                    {service["formula"]} {" "} {" "} {Translation.calculator.currency}  {currentLanguage === "ar" ? service["unitAr"] : service["unitEn"]}
                                </p>
                            </div>
                            :
                            <div>
                                <div className="mb-2">
                                    <p className="ml-1 d-inline">{currentLanguage === "ar" ? service["variableAr"] : service["variableEn"]}</p>
                                    <i className="fa fa-pen ml-2 mb-3"></i>
                                    <input style={{ dir: currentLanguage === "ar" ? "rtl" : "ltr", textAlign: currentLanguage === "ar" ? "right" : "left" }}
                                        type="number" className="calculator-input" onChange={(event) => { handleCalculationResult(event.target.value) }} value={currentLandSpace} />
                                    <button className="primary-button p-2" onClick={() => {
                                        handleClickCalculateBtn()
                                    }}
                                        disabled={!currentLandSpace}
                                    >{Translation.calculator.calculate}</button>
                                </div>
                                {
                                    calculateBtnClicked &&
                                    <div>
                                        <p className="ml-1 d-inline">{Translation.calculator.estimatedCost}</p>
                                        <i className="fa fa-coins ml-2 mb-3"></i>
                                        <div className="row justify-content-end" style={{ dir: currentLanguage === "ar" ? "rtl" : "ltr" }} >
                                            <p style={{ direction: currentLanguage === "ar" ? "rtl" : "ltr" }}>
                                                {calculationResult} {" "} {" "} {Translation.calculator.currency}  {currentLanguage === "ar" ? service["unitAr"] : service["unitEn"]}
                                            </p>
                                        </div>
                                    </div>
                                }
                            </div>
                }
            </>
        );
    }


    return (
        services && <div id="contactus-page">

            <Toaster
                position="top-center"
                reverseOrder={true}
                toastOptions={{
                    className: '',
                    duration: 5000,
                }}
            />

            <NavigationBar secondNavbarClassName="second-navbar-content-page" />
            <div className="home-page-secondary-background"></div>
            <div id="calculator-container-wrapper" style={{ direction: Translation.toCSSDirection }} >
                <div id="content-container-calculator" className="container">
                    <div className="row">
                        <div className="col mb-4">
                            <div className="container d-flex justify-content-center">
                                <div className="calculator-header-container calculator-header">
                                    {Translation.calculator.header}
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            {
                                services.length > 0 &&
                                <div className={`container ${currentLanguage === "ar" ? "text-right" : "text-left"} `}>

                                    <div className="mb-4">
                                        <h4 className="mb d-inline"><strong>{Translation.calculator.pickService}</strong></h4>
                                        <i className="fab fa-get-pocket fa-2x ml-2 mb-3"></i>

                                        <div className="input-group mt-2 position-relative">
                                            <input
                                                onClick={event => setSearchableTerm("")}
                                                value={searchableTerm}
                                                onFocus={(event) => setIsDropdownActive(true)}
                                                type="text"
                                                className={`form-control ${currentLanguage === "ar" ? "text-right" : "text-left"} `}
                                                placeholder={Translation.calculator.serviceName}
                                                onChange={(event) => setSearchableTerm(event.target.value)}
                                            />
                                            {
                                                isDropdownActive
                                                &&
                                                <div className="list-group calculator-services-dropdown">
                                                    {
                                                        services.length > 0 && (
                                                            services
                                                                .filter(service => service["name"]
                                                                    .includes(searchableTerm))
                                                                .map((service, index) => (
                                                                    <span
                                                                        className="custom-dropdown-item"
                                                                        key={`service_${index}_${service["id"]}`}
                                                                        onClick={(event) => {
                                                                            const currentServiceIndex = services.findIndex(targetService => targetService["name"] === service["name"]);
                                                                            setCurrentService(currentServiceIndex);
                                                                            setCurrentType(0);
                                                                            setSearchableTerm(services[currentServiceIndex]["name"]);
                                                                            setIsDropdownActive(false);
                                                                            setCalculationResult("");
                                                                        }}
                                                                    >
                                                                        {service["name"]}
                                                                    </span>
                                                                ))
                                                        )
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        currentService != null &&
                                        services[currentService]["hasActivity"] &&
                                        <div>
                                            <h5 className="mb d-inline"><strong>{Translation.calculator.pickActivity}</strong></h5>
                                            <i className="fa fa-window-restore ml-2 mb-3"></i>

                                            <div className="input-group mt-3 position-relative">
                                                <select className="calculator-input" onChange={(event) => setCurrentActivity(parseInt(event.target.value))}>
                                                    {
                                                        activites.map(activity => <option key={`${activity["id"]}`} value={activity["id"]}>{activity["name"]}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }

                                    {
                                        currentService != null && services[currentService]["hasSubServices"] &&
                                        <div className="mt-4">
                                            <p className="mb d-inline mt-4"><strong>{Translation.calculator.pick + " " + services[currentService]["subServicesName"]}</strong></p>
                                            <i className="fa fa-tags ml-2 mb-2"></i>
                                            <div className="input-group mt-3 position-relative">
                                                <select
                                                    onChange={(event) => {
                                                        setCurrentType(parseInt(event.target.value));
                                                        setCalculationResult("");
                                                        // display service description and service cost calc
                                                    }}
                                                    className="calculator-input calculator-dropdown"
                                                >
                                                    {
                                                        services[currentService]["subServices"].length > 0
                                                        &&
                                                        services[currentService]["subServices"]
                                                            .filter(type => !services[currentService]["hasActivity"]
                                                                ||
                                                                type["activityId"] === currentActivity)
                                                            .map((type, index) =>
                                                                <option key={`${type["name"]}`} value={index}>{type["name"]}</option>
                                                            )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    {
                                        currentService != null
                                        &&
                                        services.length > 0
                                        &&
                                        <div className="mb-2">
                                            <div className="mt-4 mb-4">
                                                <div >
                                                    <p className="ml-1 d-inline"><strong>{Translation.calculator.serviceDescription}</strong></p>
                                                    <i className="fa fa-info ml-2 mb-2"></i>
                                                </div>
                                                <div>
                                                    <p style={{ wordWrap: 'break-word' }}>
                                                        {
                                                            // Translation.calculator.serviceDescriptionPrefix
                                                            // +
                                                            (services.length > 0 ? (currentLanguage === "ar" ? services[currentService]?.descriptionAr : services[currentService]?.descriptionEn) : "")
                                                            +
                                                            (
                                                                services.length > 0 && services[currentService]["hasSubServices"] && currentType != null ?
                                                                    Translation.calculator.forType + " " + services[currentService]["name"] + " "
                                                                    + services[currentService].subServices?.currentType?.name
                                                                    :
                                                                    ""
                                                            )
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            {
                                                services[currentService]["hasSubServices"] ?
                                                    currentType != null && renderCostCalculator(services[currentService]["subServices"][currentType]) :
                                                    currentService != null && renderCostCalculator(services[currentService])
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            <div className="container mt-5">
                                <Link to="/" className="primary-button p-2"><i className="fa fa-arrow-left mr-3"></i>{Translation.calculator.endJourney}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                {isAuthenticated() &&
                    <Table
                        addDeleteEvent={addDeleteEvent}
                        handleCancel={handleCancel}
                        handleSave={handleSave}
                        services={services}
                        setServices={setServices}
                        activites={activites}
                        setDefaultSeviceType={setCurrentType}
                    />
                }
            </div>
            <BottomNavigationBar />
        </div>
    );
};

export default Calculator;
