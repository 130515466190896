import React, { useState } from 'react';
import Questions from './Questions';
import dataList from './FAQData';
import NavigationBar from '../Layout/NavigationBar';
import BottomNavigationBar from '../Layout/BottomNavigationBar';
import { useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import UseTranslations from '../../Translation/UseTranslations';

const FAQ = () => {

    const { currentLanguage } = useContext(UserContext);
    const Translation = UseTranslations();

    const [currentTab, setCurrentTab] = useState(dataList.data.length);
    const [currentTabData, setCurrentTabData] = useState(() => dataList.data.find(o => o.typeId === 1));


    let handleClick = e => {
        setCurrentTab(Number(e.target.id));
        setCurrentTabData(dataList.data.find(o => o.typeId === Number(e.target.id)));
    };

    return (
        <>
            <NavigationBar secondNavbarClassName="second-navbar-content-page" />
            <div className="home-page-secondary-background"></div>
            <div id="content-container-wrapper" style={{ direction: currentLanguage === 'ar' ? 'ltr' : 'rtl' }}>
                <div id="content-container" className="container">
                    <div className="container">
                        <div className="row justify-content-end mb-5">
                            <h3 id="main-title-header">
                                {Translation.FAQ.title}
                            </h3>
                            <div id="home-title-icon-starter"></div>
                        </div>
                        <ul className="nav-tabs">

                            {
                                dataList.data.map(faqTab =>
                                    <li
                                        className={currentTab === faqTab['typeId'] ? 'active tab' : 'tab'}
                                        id={faqTab['typeId']}
                                        onClick={handleClick}
                                    > {currentLanguage === 'ar' ? faqTab['type'] : faqTab['typeEn']} </li>
                                )
                            }
                        </ul>
                        { currentLanguage !== 'en' && <Questions key={currentTab} tabData={currentTabData} />}
                    </div>
                </div>
            </div>
            <BottomNavigationBar />
        </>
    );
};

export default FAQ;
