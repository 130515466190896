import { useState, createContext, useEffect } from "react";
import { DEFAULT_LANGUAGE } from "../Translation/Translations";

const UserContext = createContext();

// Note below functions only accept string as value otherwise utilize JOSN.* functions
const setLocalStorage = (key, value) => localStorage.setItem(key, value);
const getLocalStorage = (key, defaultValue) => localStorage.getItem(key) || defaultValue;

const setLocalStorageObject = (key, value) => setLocalStorage(key, JSON.stringify(value));
const getLocalStorageObject = (key, defaultValue) => JSON.parse(localStorage.getItem(key)) || defaultValue;

const removeLocalStorage = (key) => localStorage.removeItem(key);

const defaultUserSessionObject = { token: null, expiration: null };


const UserContextProvider = ({ children }) => {

    const [userSession, setUserSession] = useState(() => getLocalStorageObject('userSession', defaultUserSessionObject));
    const [currentLanguage, setCurrentLanguage] = useState(() => getLocalStorage('currentLanguage', DEFAULT_LANGUAGE));

    const isAuthenticated = () => userSession?.token != null && !isExpiredToken(userSession?.expiration);
    const setSession = (token, expiration) => setUserSession({ token: token, expiration: expiration });

    const isExpiredToken = (expirationDate) => new Date(expirationDate) < new Date();

    const getToken = () => userSession?.token;

    useEffect(() => { setLocalStorage('userSession', JSON.stringify(userSession)); }, [userSession]);
    useEffect(() => { setLocalStorage('currentLanguage', currentLanguage); }, [currentLanguage]);

    const clearSession = () => {

        removeLocalStorage('userSession');
        setUserSession(defaultUserSessionObject);
    }

    return (
        <UserContext.Provider value={{ getToken, userSession, clearSession, isAuthenticated, setSession, currentLanguage, setCurrentLanguage }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContextProvider, UserContext };