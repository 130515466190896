import React, { useEffect, useState } from "react";
import Level from "./Level";
import Table from "../../Admin/Editable/Table";
import Spinner from "../../Generic/Spinner";
import { getRequest, postRequest, deleteRequest } from "../../../APIs/GenericFetch";
import { BACKEND_BASE_URL, BACKEND_LEVELS_DATA_URI, BACKEND_BUTTON_DELETE_EVENT_URI, BACKEND_BUTTON_ADD_EVENT_URI, BACKEND_BUTTON_MODIFY_EVENT_URI } from "../../../APIs/BackendUrls";
import { UserContext } from "../../../Context/UserContext";
import { useContext } from "react";
import TourButton from "../../Admin/TourGuide/TourButton";
import toast from 'react-hot-toast';
import TourSteps from "../../Admin/TourGuide/TourSteps";
import UseTranslations from "../../../Translation/UseTranslations";


const UPDATE_CHANGE_EVENT = "UPDATE";
const ADD_CHANGE_EVENT = "ADD";
const DELETE_CHANGE_EVENT = "DELETE";

const MAX_LEVEL = 10;

const Levels = () => {

    const Translation = UseTranslations();

    const { isAuthenticated, currentLanguage, getToken } = useContext(UserContext);
    // content management mode hides or show admin tools to modify the data
    const [isContentManagementMode, setIsContentManagementMode] = useState(() => false);
    // saves individual changes made by admin
    const [adminChanges, setAdminChanges] = useState(() => []);
    // saves a copy of pre-saved level's content data 
    const [preChangesData, setPreChangesData] = useState(() => null);
    // level's content data
    const [levelsData, setLevelsData] = React.useState(() => []);
    // is data loaded from the server
    const [isDataLoaded, setIsDataLoaded] = React.useState(() => false);

    const deepCloneOjbect = (targetObject) => {
        return JSON.parse(JSON.stringify(targetObject));
    }

    const columns = [
        { path: "titleAr", name: "عنوان عربي", enName: "Arabic Title", type: "string" },
        { path: "titleEn", name: "عنوان بالانجليزي", enName: "English Title", type: "string" },
        { path: "parent", name: "أصل", enName: "Parent", type: "dropdown" },
        //{ path: "atLevel", name: "مستوى", type: "number" }, // for debugging you can uncomment all this paths
        //{ path: "atBranch", name: "فرع", type: "number" }, // for debugging you can uncomment all this paths
        //{ path: "targetLevel", name: "فتح مستوى", type: "number" }, // for debugging you can uncomment all this paths
        //{ path: "targetBranch", name: "فتح فرع", type: "number" }, // for debugging you can uncomment all this paths
    ];

    // below useEffect acts as componentDidMount witch will start fetching before rendering any component
    useEffect(() => {

        if (isAuthenticated())
            toast(Translation.messages.unSavedChangesLoss, { duration: 6000, position: 'left-bottom' });

        fetchLevelsData().then(() => { setIsDataLoaded(true); });
    }, []);

    const deleteButton = async (id) => {

        return await deleteRequest(BACKEND_BASE_URL + BACKEND_BUTTON_DELETE_EVENT_URI, id, getToken());
    }

    const addButton = async (button) => {
        return await postRequest(BACKEND_BASE_URL + BACKEND_BUTTON_ADD_EVENT_URI, button, getToken());
    }

    const modifyButton = async (button) => {
        return await postRequest(BACKEND_BASE_URL + BACKEND_BUTTON_MODIFY_EVENT_URI, button, getToken());
    }

    // because children buttons depends on parent buttons, deleting a parent needs to delete all children and if children is parent of another recursively delete it too
    const removeChildren = (parent) => {
        const childrenLevel = levelsData.findIndex(level => level.atLevel === parent['targetLevel']);
        if (childrenLevel === -1)
            return levelsData;
        levelsData[childrenLevel]['branches'] = levelsData[childrenLevel]['branches'].map(branch => {
            if (branch.atBranch === parent['targetBranch']) {
                branch['buttons'] = branch['buttons'].filter(button => {
                    if (button['parent']['buttonId'] === parent['buttonId']) {
                        setAdminChanges(previewChanges => [...previewChanges, { 'event': DELETE_CHANGE_EVENT, 'button': button }]);
                        removeChildren(button);
                        return false;
                    }
                    return true;
                });
            }
            return branch;
        });
        return levelsData;
    }

    // finds button by id then deletes it
    const removeButtonById = (buttonId) => {
        setLevelsData(levelsData => {
            levelsData = levelsData.map((levelData) => {
                levelData['branches'] = levelData['branches'].map(branchesData => {
                    branchesData['buttons'] = branchesData['buttons'].filter(buttonData => buttonData['buttonId'] !== buttonId
                    )
                    return branchesData;
                });
                return levelData;
            })
            return levelsData;
        });
    }

    // finds button by id then deletes it
    const removeButtonByIdNoState = (buttonId) => {
        levelsData.map((levelData) => {
            levelData['branches'] = levelData['branches'].map(branchesData => {
                branchesData['buttons'] = branchesData['buttons'].filter(buttonData => buttonData['buttonId'] !== buttonId
                )
                return branchesData;
            });
            return levelData;
        })
    }

    // looks for a branch at level and adds a default branch if not exists
    const addNewBranchIfNotExists = (atLevel, atBranch) => {
        setLevelsData(levelsData => {
            let level = levelsData.find(level => level.atLevel === atLevel);
            let branch = level['branches'].find(branch => branch.atBranch === atBranch);

            if (!branch) level['branches'].push({ "atBranch": atBranch, "buttons": [], isHidden: true, currentActiveButtonId: 0 });
            return levelsData;
        });
    }

    // if parent has no children make it open a content
    const setContentTrueIfChildless = (parent) => {

        setLevelsData(currentState => {

            // first find parent from currentState instead of using parent parameter
            // to make sure the parent is getting updated upon this function complition
            const currentLevel = currentState.find(level => level.atLevel === parent['atLevel']);
            const currentBranch = currentLevel['branches'].find(branch => branch['buttons'].find(button => button['buttonId'] === parent['buttonId']));
            const currentStateParent = currentBranch['buttons'].find(button => button['buttonId'] === parent['buttonId']);

            const possibleChildrenLevels = currentState.filter(level => level.atLevel === currentStateParent['atLevel'] + 1);

            // parent is childless until below loop finds a child
            currentStateParent.hasContent = true;

            possibleChildrenLevels.forEach(level => {
                level['branches'].forEach(branch => {
                    const child = branch['buttons'].findIndex(button => button.parent.buttonId === parent.buttonId);

                    if (child !== -1)
                        currentStateParent.hasContent = false;
                });
            });

            // update parent event
            setAdminChanges(previewChanges => [...previewChanges, { 'event': UPDATE_CHANGE_EVENT, 'button': currentStateParent }]);

            return currentState;
        });
    }

    const fetchLevelsData = () => {
        return getRequest(BACKEND_BASE_URL + BACKEND_LEVELS_DATA_URI, getToken()).then(responseObject => {
            if (responseObject.success) {
                if (responseObject.status === 200) {
                    const fetchedData = defaultLevelsData(responseObject['data'].sort((previus, current) => previus.atLevel - current.atLevel));

                    if (fetchedData.length === 0) {
                        toast.error(Translation.messages.noDataWasFound); return false;
                    }

                    setLevelsData(fetchedData);
                    setPreChangesData(deepCloneOjbect(fetchedData));

                    return true;
                } else
                    toast.error(Translation.messages.somethingWentWrong);
            }
            return false;
        });
    }

    // cancels data modifed made pre-save by admin 
    const handleCancelChanges = (event) => {
        setIsContentManagementMode(false);
        setLevelsData(deepCloneOjbect(preChangesData || []));
        toast.success(Translation.messages.changesWereCanceled);
    }

    // just to keep previusLevelData field of every level up to date
    const refreshPreviusLevelsData = () => {
        setLevelsData(currentState => {
            currentState.forEach(level => {
                if (level.atLevel > 1)
                    level['previusLevelData'] = currentState.find(targetLevel => targetLevel.atLevel === level.atLevel - 1)
                    ['branches'].map(button => button);
            });

            return currentState;
        });
    }

    // adds a level if not exists with default values
    const addLevelIfNotExists = (atLevel) => {
        if (!levelsData.find(level => level.atLevel === atLevel)) {
            setLevelsData(prevlevelsData => {
                return [...prevlevelsData,
                {
                    "previusLevelData": atLevel != 1 ? levelsData.find(level => level.atLevel === atLevel - 1)['branches'].map(button => button) : [],
                    "atLevel": atLevel,
                    'branches': [{ "atBranch": 1, "buttons": [], isHidden: atLevel === 1 ? false : true, currentActiveButtonId: 0 }]
                }];
            });
        }
        sortLevelsData();
    }

    // need the levels data to be in order because how admin table component was built 
    const sortLevelsData = () => {
        setLevelsData(currentState => currentState.sort((previusLevel, currentLevel) => previusLevel.atLevel - currentLevel.atLevel))
    }

    // its not allowed to added a button if previous level has no buttons ( no parent to be attached to ), only should be used for non-first-level levels
    const checkIfPreviousLevelHasButtons = (atLevel) => {
        const previousLevel = levelsData.find(level => level.atLevel === atLevel - 1);
        if (!previousLevel)
            return false;

        const anyNonEmptyBranches = previousLevel['branches'].find(branch => branch['buttons'].length > 0);

        if (anyNonEmptyBranches)
            return true;

        return false;
    }

    const getFirstNonReservedBranchAtLevel = (atLevel) => {

        const currentLevel = levelsData.find(level => level.atLevel === atLevel);

        let heighestTargetBranch = 0;

        currentLevel['branches'].forEach(branch => {
            branch['buttons'].forEach(button => {
                if (button.targetBranch > heighestTargetBranch)
                    heighestTargetBranch = button.targetBranch;
            });
        });

        return heighestTargetBranch + 1;
    }

    // used when a new button is created weather it opens a level or a content so when a child chose this button in the future it can reallocate it self under this settings
    const addTargetLevelAndBranch = (buttonData) => {

        // targets (opens) the next level
        buttonData.targetLevel = buttonData.atLevel + 1;

        // targets (opens) the next avaible branch
        buttonData.targetBranch = getFirstNonReservedBranchAtLevel(buttonData.atLevel);

        return buttonData;
    }

    const addButtonAtLevelAndBranch = (buttonData, atLevel, atBranch, isToUpdateButton = true) => {

        addNewBranchIfNotExists(atLevel, atBranch);

        setLevelsData(currentState => {
            const currentLevel = currentState.find(level => level.atLevel === atLevel);

            const currentBranch = currentLevel['branches'].find(branch => branch.atBranch === atBranch);

            if (isToUpdateButton) {
                buttonData = addTargetLevelAndBranch(buttonData);
                buttonData["orderIndex"] = currentBranch["buttons"].length;
            }
            currentBranch['buttons'].push(buttonData);

            return currentState;
        });
    }

    const findFirstParent = (atLevel) => {
        const currentLevel = levelsData.find(level => level.atLevel === atLevel - 1);

        const currentBranch = currentLevel['branches'].find(branch => branch['buttons'].length > 0);

        return currentBranch['buttons'][0];
    }

    // if parent has no target branch or target level take child's level and branch ( so we can open the child when we click the parent) and make doesnt open a content since it has a child
    const updateParent = (parent, child) => {
        if (parent.targetLevel === null)
            parent.targetLevel = child.atLevel;

        if (parent.targetBranch === null)
            parent.targetBranch = child.atBranch;

        parent.hasContent = false;
    }

    // need to update children about parent's new changes for the dropdown in the table to reflict changes
    const updateChildrenAboutParentChanges = (parent) => {
        setLevelsData(currentState => {
            let childrenLevel = levelsData.find(level => level.atLevel === parent.atLevel + 1);

            if (childrenLevel) {
                childrenLevel['branches'].map(branch => {
                    branch['buttons'].map(button => {
                        if (button['parent'] && button.parent.buttonId === parent.buttonId) {
                            button['parent'] = parent;
                            setAdminChanges(previewChanges => [...previewChanges, { 'event': UPDATE_CHANGE_EVENT, 'button': button }]);
                        }
                        return button
                    });
                });
            }

            return currentState;
        });
    }

    const findButtonById = (buttonId, atLevel) => {
        const currentLevel = levelsData.find(level => level.atLevel === atLevel);

        const currentBranch = currentLevel['branches'].find(branch => branch['buttons'].find(button => button['buttonId'] === buttonId));

        const button = currentBranch['buttons'].find(button => button['buttonId'] === buttonId);

        return button;
    }

    const handleAdd = (atLevel) => {
        const defaultRow = {
            buttonId: generateUniqueId(),
            targetLevel: null,
            targetBranch: null,
            atLevel: atLevel,
            atBranch: 1,
            titleAr: "العنوان الجديد",
            titleEn: "New Title",
            hasContent: true,
            orderIndex: 0,
            parent: null
        };

        addLevelIfNotExists(atLevel);

        // if the button should be inserted at the first level insert the button at first level/branch
        if (atLevel === 1) {
            addButtonAtLevelAndBranch(defaultRow, 1, 1);
        } else {
            if (checkIfPreviousLevelHasButtons(atLevel)) {

                const firstParentFound = findFirstParent(atLevel);

                if (!firstParentFound) {
                    toast.error(Translation.messages.somethingWentWrong); return;
                }

                updateParent(firstParentFound, defaultRow);

                defaultRow.parent = firstParentFound;

                defaultRow.atLevel = firstParentFound.targetLevel;
                defaultRow.atBranch = firstParentFound.targetBranch;

                setAdminChanges(previewChanges => [...previewChanges, { 'event': UPDATE_CHANGE_EVENT, 'button': firstParentFound }]);

                // add the new inserted button at the level required and first branch
                addButtonAtLevelAndBranch(defaultRow, defaultRow.atLevel, defaultRow.atBranch);

            } else
                toast.error(`لا يمكن اضافة خيار في المستوى لعدم وجود خيارات بالمستوى ${atLevel - 1}`);
        }

        // save the new button as ADD event to be pushed later to the backend
        setAdminChanges(previewChanges => [...previewChanges, { 'event': ADD_CHANGE_EVENT, 'button': defaultRow }]);

        sortLevelsData();
        refreshPreviusLevelsData();
    };

    const handleChange = (buttonId, changedData, columnHeader) => {

        // if the new title is empty show validation message and do nothing
        if ((columnHeader === 'titleAr' && changedData[columnHeader] === '') || (columnHeader === 'titleEn' && changedData[columnHeader] === '')) {
            toast.error(Translation.messages.cellMustBeNotEmpty); return;
        }
        // if admin changed parent of button and not the content of the button 
        else if (columnHeader === 'parent') {
            const parentId = Number(changedData.parent.buttonId);

            // find the new parent selected object
            const theNewParent = findButtonById(parentId, changedData.atLevel - 1);

            // make it open a child instead of content if not already opens a child
            updateParent(theNewParent, changedData);

            const theOldParent = findButtonById(changedData.parent.oldButton.buttonId, changedData.atLevel - 1);

            changedData['parent'] = theNewParent;

            // get parent target branch so when parent is clicked open the child
            changedData.atBranch = changedData['parent'].targetBranch;

            // removing the button and readding it to force the dropdown in admin table to show latest data ( unluckly still need to learn react indepth )
            removeButtonById(buttonId);
            addButtonAtLevelAndBranch(changedData, changedData.atLevel, changedData.atBranch, false);

            setContentTrueIfChildless(theOldParent);
            updateChildrenAboutParentChanges(theNewParent);

            // three events happened in above logic the changed button, old parent and the new parent
            setAdminChanges(previewChanges => [...previewChanges, ...[
                { 'event': UPDATE_CHANGE_EVENT, 'button': theOldParent },
                { 'event': UPDATE_CHANGE_EVENT, 'button': theNewParent },
            ]]);
        }
        else {
            updateChildrenAboutParentChanges(changedData);

            // removing the button and readding it to force the dropdown in admin table to show latest data ( unluckly still need to learn react indepth )
            removeButtonById(buttonId);
            addButtonAtLevelAndBranch(changedData, changedData.atLevel, changedData.atBranch, false);
        }

        setAdminChanges(previewChanges => [...previewChanges, { 'event': UPDATE_CHANGE_EVENT, 'button': changedData }]);

        sortLevelsData();
    }

    const swapUp = (buttonData) => {

        const currentLevel = levelsData.find(level => level.atLevel === buttonData['atLevel']);
        const currentBranch = currentLevel['branches'].find(branch => branch['buttons'].find(button => button['buttonId'] === buttonData['buttonId']));
        const currentButtonIndex = currentBranch['buttons'].findIndex(button => button['buttonId'] === buttonData['buttonId']);

        if (currentBranch['buttons'][currentButtonIndex]["orderIndex"] > 1) {
            const currentButtonClone = currentBranch['buttons'][currentButtonIndex];
            currentButtonClone["orderIndex"] = currentButtonClone['orderIndex'] - 1;

            currentBranch['buttons'][currentButtonIndex] = currentBranch['buttons'][currentButtonIndex - 1];
            currentBranch['buttons'][currentButtonIndex - 1] = currentButtonClone;

            currentBranch['buttons'][currentButtonIndex]["orderIndex"] = currentButtonClone["orderIndex"] + 1;

            setAdminChanges(previewChanges => [...previewChanges, { 'event': UPDATE_CHANGE_EVENT, 'button': currentButtonClone }]);
            setAdminChanges(previewChanges => [...previewChanges, { 'event': UPDATE_CHANGE_EVENT, 'button': currentBranch['buttons'][currentButtonIndex] }]);
        }

    }

    const swapDown = (buttonData) => {

        const currentLevel = levelsData.find(level => level.atLevel === buttonData['atLevel']);
        const currentBranch = currentLevel['branches'].find(branch => branch['buttons'].find(button => button['buttonId'] === buttonData['buttonId']));
        const currentButtonIndex = currentBranch['buttons'].findIndex(button => button['buttonId'] === buttonData['buttonId']);

        if (currentButtonIndex < currentBranch['buttons'].length - 1) {
            const currentButtonClone = currentBranch['buttons'][currentButtonIndex];
            currentButtonClone["orderIndex"] = currentButtonClone['orderIndex'] + 1;

            currentBranch['buttons'][currentButtonIndex] = currentBranch['buttons'][currentButtonIndex + 1];
            currentBranch['buttons'][currentButtonIndex + 1] = currentButtonClone;

            currentBranch['buttons'][currentButtonIndex]["orderIndex"] = currentButtonClone["orderIndex"] - 1;

            setAdminChanges(previewChanges => [...previewChanges, { 'event': UPDATE_CHANGE_EVENT, 'button': currentButtonClone }]);
            setAdminChanges(previewChanges => [...previewChanges, { 'event': UPDATE_CHANGE_EVENT, 'button': currentBranch['buttons'][currentButtonIndex] }]);
        }

    }

    // finds a button by id then removes it from the list
    const handleDelete = (buttonId, buttonData) => {

        const parentOfDeletedButton = buttonData['parent'];

        // if button deleted have children which they depends on delete them too
        removeChildren(buttonData);
        // after the children were removed, remove the button it self
        // it uses no state version of remove button because I wasnt to make sure it delete the buttons before reaching setContentTrueIfChildless below
        removeButtonByIdNoState(buttonId);

        setAdminChanges(previewChanges => [...previewChanges, { 'event': DELETE_CHANGE_EVENT, 'button': buttonData }]);

        if (parentOfDeletedButton) {
            const orignalParent = findButtonById(parentOfDeletedButton['buttonId'], buttonData['atLevel'] - 1);

            // parent when comes from the backend it has no atLevel or atBranch values
            parentOfDeletedButton['atLevel'] = orignalParent['atLevel'];
            parentOfDeletedButton['atBranch'] = orignalParent['atBranch'];

            // make parent open a content if the recent deleted was the sole child
            setContentTrueIfChildless(parentOfDeletedButton);
        }

        sortLevelsData();
    };


    // walks through admin changes and submit them one by one
    const handleSaveChanges = async () => {

        const toastId = toast.loading(Translation.messages.changesAreBeingUploaded);

        for (const change of adminChanges) {

            if (change.event === UPDATE_CHANGE_EVENT)
                await modifyButton(change.button);
            if (change.event === ADD_CHANGE_EVENT) {

                /* 
                 * now some buttons events have the old random generated id ( the backend remove the old id and insert a new auto generated id by the database ) 
                 * so when the button comes from the backend update where the old button were refrenced and update them with the new id
                 */

                const postDataButtonResponse = await addButton(change.button);

                if (postDataButtonResponse.success) {
                    const responseButton = postDataButtonResponse['data'];

                    if (responseButton) {

                        // if some children have refrence for the new button came from the backend update them too
                        let childrenOfSavedButton = adminChanges.filter(previewChange => {
                            if (previewChange.button.parent)
                                return previewChange.button.parent.buttonId === change.button.buttonId;
                            return false;
                        });

                        if (childrenOfSavedButton)
                            childrenOfSavedButton.forEach(change => change.button.parent.buttonId = responseButton.buttonId);

                        // update events of the button with the new id
                        let changedButton = adminChanges.filter(previewChange => previewChange.button.buttonId === change.button.buttonId);

                        if (changedButton)
                            changedButton.forEach(event => event['button']['buttonId'] = responseButton.buttonId);

                        var x = 2;
                    }
                }
            }
            if (change.event === DELETE_CHANGE_EVENT)
                await deleteButton(change.button.buttonId);
        }

        fetchLevelsData().then((wasSuccess) => {
            if (wasSuccess)
                toast.success(Translation.messages.changesUploadedSuccessfully);
            else
                toast.error(Translation.messages.somethingWentWrong)

            toast.dismiss(toastId);

            setIsContentManagementMode(false);
            setAdminChanges(() => []);
        });


    };

    // when creation a new button I need a temporary id so when I search for the button when it's value is changed by admin
    const generateUniqueId = () => {
        return (Math.random() * (99999 - 9999 + 1)) << 0;
    }

    // When the data is fetched from the server I need to add to them some attr like isHidden ( for branches )
    const defaultLevelsData = (initialLevelsData) => {

        // previus is needed to be showen in the dropdown of admin table
        let previusLevelData = [];

        return initialLevelsData.map((levelData) => {
            // add temp data needed in the UI
            levelData['branches'] = levelData['branches'].map(brancheData => ({ ...brancheData, isHidden: levelData.atLevel === 1 && brancheData.atBranch === 1 ? false : true, currentActiveButtonId: 0 }));

            levelData['previusLevelData'] = previusLevelData;

            // save current branches for the next iteration
            previusLevelData = levelData['branches'].map(brancheData => brancheData);

            levelData['branches'].forEach(branch => {
                branch['buttons'].forEach(button => {
                    // set atLevel because when data comes from the backend it does not have it
                    button['atLevel'] = levelData.atLevel;
                    button['atBranch'] = branch.atBranch;
                })
            });

            if (isAuthenticated()) {
                levelData['branches'].forEach(branch => {
                    branch['buttons'].forEach((button, index) => {
                        if (!button['orderIndex']) {
                            button['orderIndex'] = index + 1;
                        }
                    })
                });
            }

            return levelData;
        });
    }

    const onOpenBranch = (buttonId, currentBranch, currentLevel, targetBranch, targetLevel) => {
        setLevelsData(levels => {

            levels.forEach(levelData => {
                levelData['branches'].forEach(branchData => {
                    if (levelData.atLevel >= currentLevel + 1) {
                        branchData['isHidden'] = true;
                        branchData['currentActiveButtonId'] = 0;
                    }
                });
            });

            levels = levels.map((levelData) => {
                // Sets current level active button
                if (levelData.atLevel === currentLevel)
                    levelData['branches'].map(branchesData => {
                        if (branchesData.atBranch === currentBranch)
                            branchesData['currentActiveButtonId'] = buttonId;
                        return branchesData;
                    });
                // Shows and hide levels
                if (levelData.atLevel === targetLevel)
                    levelData['branches'].map(branchesData => {
                        if (branchesData.atBranch === targetBranch && levelData.atLevel === targetLevel) {
                            branchesData['isHidden'] = false;
                        }
                        return branchesData;
                    });
                return levelData;
            });
            return levels;
        });
    }

    const isANewButton = (buttonId) => {
        return adminChanges.filter(previewChange => previewChange.event === ADD_CHANGE_EVENT && previewChange.button.buttonId === buttonId).length > 0;
    }

    const addNextLevel = () => {
        if (levelsData.length === 0) {
            setLevelsData(defaultLevelsData([]));
            addLevelIfNotExists(1);
        } else if (levelsData.length >= 10) {
            toast.error(Translation.messages.cantAddMoreThanLevels);
        }
        else {
            const lastLevel = levelsData.sort((previous, current) => current.atLevel - previous.atLevel)[0].atLevel;
            addLevelIfNotExists(lastLevel + 1);
        }
    }

    console.log(levelsData);
    console.log(adminChanges);

    return (
        <>

            {
                isDataLoaded ? (
                    <div >
                        {
                            levelsData.length > 0 ?
                                levelsData.map(levelData =>
                                    <Level isANewButton={isANewButton} isAuthenticated={isAuthenticated()} currentLanguage={currentLanguage} onOpenBranch={onOpenBranch} isContentManagementMode={isContentManagementMode} levelNumber={levelData.atLevel} branchsData={levelData['branches']} />
                                ) : !isAuthenticated() && < Spinner />
                        }
                    </div>
                ) : <Spinner />
            }
            {
                isContentManagementMode && (
                    <div className="admin-table-container" style={{ width: "100%" }} >
                        <Table swapUp={swapUp} swapDown={swapDown} handleAddNextLevel={addNextLevel} levelData={levelsData} handleSaveChanges={handleSaveChanges} handleCancelChanges={handleCancelChanges} setIsContentManagementMode={setIsContentManagementMode} onAdd={handleAdd} onDelete={handleDelete} columns={columns} onChange={handleChange} />
                    </div>
                )
            }
            {
                isAuthenticated() && !isContentManagementMode && isDataLoaded &&
                <div style={{ width: "100%", textAlign: "center" }}>
                    <button type="button" style={{ backgroundColor: "#072b46", outline: "none", border: "none" }} class="btn btn-primary admin-table-cancel-button mr-2" onClick={() => { setIsContentManagementMode(true) }}>{Translation.homepage.modify}</button>
                </div>
            }
            {
                isAuthenticated() && isContentManagementMode && <TourButton tourSteps={TourSteps} />
            }
        </>
    );
};

export default Levels;