import React, { useEffect, useRef, useState } from "react";
import SunEditor from "suneditor-react";
import { useContext } from "react";
import { UserContext } from "../../../../Context/UserContext";
import "suneditor/dist/css/suneditor.min.css";



export const TextEditor1 = React.memo(({ isAuthenticated,
    value, mode, onChange, onBlur }) => {
    const editorRef = useRef();
    const contentRef = useRef();
    // [editorContent, setEditorContent] = useState('')
    useEffect(() => {
        if (!contentRef.current) return;
        contentRef.current.innerHTML = value;
    }, [value]);

    const { currentLanguage } = useContext(UserContext);

const editorOptions = {
    rtl: (currentLanguage === 'ar' ? true : false),
    height: 200,
    resizingBar: false,
    buttonList: [
        ["undo", "redo"],
        ["bold", "underline", "italic"],
        ["fontColor", "hiliteColor"],
        ["align", "horizontalRule"],
        ["table", "link"],
    ],
    imageRotation: false,
    fontSize: [16],
    colorList: [
        [
            "#828282",
            "#FF5400",
            "#676464",
            "#F1F2F4",
            "#FF9B00",
            "#F00",
            "#fa6e30",
            "#000",
            "rgba(255, 153, 0, 0.1)",
            "#FF6600",
            "#0099FF",
            "#74CC6D",
            "#FF9900",
            "#CCCCCC"
        ]
    ],
    defaultStyle: "font-size:16px"
};

    return isAuthenticated ? (
            <SunEditor
                readOnly={!isAuthenticated}
                ref={editorRef}
                setOptions={editorOptions}
                onBlur={(event) => {
                    onBlur(event.target.innerHTML);
                }}
                //value={"value"}
                onChange= {(content)=>{
                    // console.log(sunEditorValue)
                    // setSunEditorValue(content)
                    onChange(content)

                }}
                lang="en"
                setContents={value}             
            />           
    ) :
        (
            <SunEditor
                readOnly={true}
                setContents={value}
                setOptions={editorOptions}
                hideToolbar={true}
                // disableToolbar={true}
                // disable={true}
            />
        );
});
