import React from "react";
import NativeVideoPlayer from "./NativeVideoPlayer";
import YoutubeVideoPlayer from "./YoutubeVideoPlayer";


const VideoPlayer = /*React.memo(*/({ videoUrl }) => {

    // url could comes from youtube as https://youtu.be/xxxxx or https://www.youtube.com/watch?v=xxxxx
    const convertYoutubeUrlToEmbededUrl = (url) => "https://www.youtube.com/embed/" + (url.split("=")[1] || url.split("/").at(-1));

    // stolen regex from https://stackoverflow.com/questions/19377262/regex-for-youtube-url
    const isYouTubeUrl = (url) => /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$/.test(url);

    if (isYouTubeUrl(videoUrl)) {
        videoUrl = convertYoutubeUrlToEmbededUrl(videoUrl);

        return <YoutubeVideoPlayer videoSource={videoUrl} />
    } else 
        return (
            <NativeVideoPlayer videoSource={videoUrl} />
        );

}/*)*/;

export default VideoPlayer;
