import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Journey from './Components/Journey/Journey';
import Login from './Components/Admin/Login/Login';
import { UserContextProvider } from './Context/UserContext';
import ContactUs from './Components/ContactUS/ContactUs';
import FAQ from './Components/FAQ/FAQ';
import Calculator from './Components/Calculator/Calculator';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <UserContextProvider>
            <Routes>
                <Route exact path="/" element={<App />} />
                <Route path="journey/:id" element={<Journey />} />
                <Route path="contactus" element={<ContactUs />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="login" element={<Login />} />
                <Route path="calculator" element={<Calculator />} />
            </Routes>
        </UserContextProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
