import React from "react";

import BottomNavigationBar from "../Layout/BottomNavigationBar";
import NavigationBar from "../Layout/NavigationBar";
import HomeContent from "./HomeContent";
import toast, { Toaster } from 'react-hot-toast';

const Home = () => {

    return (
        <div id="home-page">
            <Toaster
                position="top-center"
                reverseOrder={true}
                toastOptions={{
                    className: '',
                    duration: 5000,
                    style: {
                        maxWidth: 750,
                    }
                }}
            />
            <div class="home-page-background"></div>
            <div class="home-page-secondary-background"></div>
            <NavigationBar secondNavbarClassName="second-navbar-home-page" />
            <HomeContent />
            <BottomNavigationBar  />
      </div>
    );
};

export default Home;
