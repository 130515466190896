
const InformationCard = ({ title, description, faicon, copyToClipboardOnClick }) => {

    return (
        <div className="row content-card-container">
            <div onClick={(event) => copyToClipboardOnClick(description)} className="col-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 left-content-card-container" style={{ cursor: "pointer" }}>
                <div className="content-paragraph" >
                    <p>{description}</p>
                </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 right-content-card-container">
                <span>{title}</span>
                <i className={faicon}></i>
            </div>
        </div>
     );
}

export default InformationCard;
